import React from "react";
import { ChatFeed, Message } from "react-chat-ui";
import { Button, Divider } from "@mui/material";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Stack from "@mui/material/Stack";
import { Container } from "@mui/material";
import myUIConstants from "../../../shared/constants/constants";

const URL_CHAT = process.env.REACT_APP_BLIP;
const URL_CHAT_READ = `${process.env.REACT_APP_JSON_BACKEND_HOST}/json/read/image/blip/chat`;
const URL_CHAT_WRITE = `${process.env.REACT_APP_JSON_BACKEND_HOST}/json/write/image/blip/chat`;
const URL_PROMPT = `${process.env.REACT_APP_JSON_BACKEND_HOST}/json/read/image/blip/prompt`;
const users = {
  0: "You",
  1: "Ella",
};
let server_busy = false;

const customBubble = (props) => (
  <div>
    <p>{`${props.message.senderName} ${props.message.id ? "says" : "said"}: ${
      props.message.message
    }`}</p>
  </div>
);

async function postFormData(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST",
    body: data,
  });
  return response; // parses JSON response into native JavaScript objects
}

async function postData(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response; // parses JSON response into native JavaScript objects
}

async function getData(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
  return response; // parses JSON response into native JavaScript objects
}

function parseMarkdownTable(message) {
  const count = (message.match(/\|\s*\|/g) || []).length;
  if (count < 1) return message;
  message = message.replace(/\|\s*\|/g, "|\n|");
  message = message.replace(/\|/, "\n|");
  const lastPipeIndex = message.lastIndexOf("|");
  message =
    message.slice(0, lastPipeIndex + 1) +
    "\n\n" +
    message.slice(lastPipeIndex + 1);
  return message;
}

async function GetAIAnswer(
  clearPrevious,
  url_prompt,
  image,
  text,
  sampling = "Nucleus sampling"
) {
  return await getData(url_prompt)
    .then((rsp) => rsp.json())
    .then(async (data) => {
      var formData = new FormData();
      formData.append("image", image);
      formData.append("text", text);
      formData.append("prompt", data.prompt);
      formData.append("min_len", data.min_len);
      formData.append("max_len", data.max_len);
      formData.append("beam_size", data.beam_size);
      formData.append("len_penalty", data.len_penalty);
      formData.append("ainame", data.ainame);
      formData.append("username", data.username);
      formData.append("repetition_penalty", data.repetition_penalty);
      formData.append("top_p", data.top_p);
      formData.append("sampling", sampling);
      formData.append("clear_previous", clearPrevious);
      formData.append("temperature", data.temperature);
      return await postFormData(URL_CHAT, formData)
        .then((data) => {
          return data.json();
        })
        .then(async (data) => {
          return [data.output, data.prompt];
        })
        .catch((e) => {
          return "Server is not running";
        });
    });
}

const readJson = async (url_chat) => {
  return await getData(url_chat)
    .then((rsp) => rsp.json())
    .then((data) => {
      const history = data.history;
      const prompt = data.prompt;
      const imageUrl = data.imageUrl;
      const rsp = [];
      history.forEach((obj) => {
        const message = obj.message;
        const id = obj.id;
        rsp.push(new Message({ message, id, senderName: users[id] }));
      });
      return [rsp, history, prompt, imageUrl];
    });
};

const saveJson = (url_chat, json, prompt, imageUrl) => {
  const history = [];
  json.forEach((obj) => {
    const msg = obj.message;
    const id = obj.id;
    history.push({ message: msg, id });
  });
  postData(url_chat, { history, prompt, imageUrl });
};

class BlipComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      image: null,
      messages: [],
      useCustomBubble: false,
      history: [],
      prompt: "",
      url_prompt: URL_PROMPT,
      url_chat_read: URL_CHAT_READ,
      url_chat_write: URL_CHAT_WRITE,
      imageUrl: null,
      show_warning: false,
    };
  }

  handleFileUpload(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = async () => {
      this.setState({ imageUrl: reader.result });
      this.setState({ image: event.target.files[0] });
      this.setState({ clearPrevious: true });
      this.setState({ messages: [] });
      this.setState({ history: [] });
      saveJson(
        this.state.url_chat_write,
        this.state.history,
        this.state.prompt
      );
      server_busy = true;
      const [reply, prompt] = await GetAIAnswer(
        true,
        this.state.url_prompt,
        event.target.files[0],
        ""
      );
      server_busy = false;
      this.pushEllaMessage(reply, prompt);
      this.setState({ show_warning: false });
    };
    reader.readAsDataURL(file);
  }

  async componentDidMount() {
    const [messages, history, prompt, imageUrl] = await readJson(
      this.state.url_chat_read
    );
    const messageElements = [];
    messages.forEach((message) => {
      const messageElement = {
        id: message.id,
        message:
          message.senderName === users[1] ? (
            <div style={{ color: "black", margin: 0 }}>
              <ReactMarkdown
                className="nomargin"
                style={{ margin: 0 }}
                remarkPlugins={[remarkGfm]}
              >
                {parseMarkdownTable(message.message)}
              </ReactMarkdown>
            </div>
          ) : (
            message.message
          ),
        senderName: message.senderName,
      };
      messageElements.push(messageElement);
    });
    const url = imageUrl;
    const image = await fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "image.png", { type: "image/png" });
        return file;
      });
    this.setState({ messages: messageElements });
    this.setState({ history });
    this.setState({ prompt });
    this.setState({ imageUrl });
    this.setState({ image });
  }

  onPress(user) {
    this.setState({ curr_user: user });
  }

  async onMessageSubmit(e) {
    const input = this.message;
    e.preventDefault();
    if (!input.value) {
      return false;
    }
    if (!this.state.image) {
      this.setState({ show_warning: true });
      return false;
    }
    if (server_busy) {
      return false;
    }
    const text = input.value;
    this.pushUserMessage(text);
    input.value = "";
    const clearPrevious = this.state.history.length == 1;
    server_busy = true;
    const [reply, prompt] = await GetAIAnswer(
      clearPrevious,
      this.state.url_prompt,
      this.state.image,
      text
    );
    server_busy = false;
    this.pushEllaMessage(reply, prompt);
    this.setState({ clear_memory: false, show_warning: false });
  }

  pushUserMessage(message) {
    const prevState = this.state;
    const recipient = 0;
    const newMessage = new Message({
      id: recipient,
      message: <p>{message}</p>,
      senderName: users[recipient],
    });
    prevState.history.push({ message, id: recipient });
    prevState.messages.push(newMessage);
    this.setState(this.state);
  }

  pushEllaMessage(message, prompt) {
    const prevState = this.state;
    const messageElement = (
      <div style={{ color: "black", margin: 0 }}>
        <ReactMarkdown
          className="nomargin"
          style={{ margin: 0 }}
          remarkPlugins={[remarkGfm]}
        >
          {parseMarkdownTable(message)}
        </ReactMarkdown>
      </div>
    );
    const newMessage = new Message({
      id: 1,
      message: messageElement,
      senderName: "Ella",
    });
    prevState.messages.push(newMessage);
    prevState.history.push({ message, id: 1 });
    this.setState({ ...this.state, prompt });
    saveJson(
      this.state.url_chat_write,
      this.state.history,
      prompt,
      this.state.imageUrl
    );
  }

  onClearHistory(e) {
    e.preventDefault();
    this.setState({ clearPrevious: true });
    this.setState({ messages: [] });
    this.setState({ history: [] });
    saveJson(this.state.url_chat_write, this.state.history, this.state.prompt);
  }

  render() {
    return (
      <div
        style={{
          width: "100%",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div className="container" style={{ marginTop: 0 }}>
          <h2
            style={{
              textAlign: "center",
              fontFamily: myUIConstants.fontFamily,
              marginBottom: 60,
            }}
          >
            Image Interpretation
          </h2>
          <Container>
            <Stack alignItems="center" spacing={2}>
              {this.state.imageUrl && (
                <img
                  src={this.state.imageUrl}
                  alt="Uploaded Image"
                  // height="300"
                />
              )}
              <label htmlFor="upload-image">
                <Button variant="contained" component="span">
                  Upload
                </Button>
                <input
                  id="upload-image"
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={(e) => this.handleFileUpload(e)}
                />
              </label>
            </Stack>
          </Container>
          <br></br>
          <div className="chatfeed-wrapper">
            <ChatFeed
              chatBubble={this.state.useCustomBubble && customBubble}
              maxHeight={500}
              messages={this.state.messages} // Boolean: list of message objects
              showSenderName
            />

            <form onSubmit={(e) => this.onMessageSubmit(e)}>
              <input
                style={{ height: 50 }}
                ref={(m) => {
                  this.message = m;
                }}
                placeholder="Type a message..."
              />
            </form>
          </div>
          {this.state.show_warning ? (
            <div
              style={{
                border: "1px solid",
                borderColor: "red",
                borderRadius: 10,
                width: "100%",
                padding: 10,
                marginTop: 20,
                fontSize: 15,
                color: "red",
                direction: "rtl",
              }}
            >
              Please upload the image first
            </div>
          ) : null}
          <br></br>
          <form
            style={{
              direction: "rtl",
              width: "100%",
              padding: 10,
              marginTop: 20,
              fontSize: 15,
            }}
            onSubmit={() => {}}
          >
            <Button
              onClick={(e) => this.onMessageSubmit(e)}
              style={{ marginLeft: 20 }}
              type="submit"
              variant="contained"
              color="success"
            >
              {" "}
              Submit{" "}
            </Button>
            <Button
              onClick={(e) => this.onClearHistory(e)}
              style={{ marginLeft: 20 }}
              type="submit"
              variant="outlined"
            >
              Clear History
            </Button>
          </form>
          <div>
            <h3>Prompt will look like this:</h3>
            <textarea
              fullWidth
              width={1000}
              multiline={true}
              readOnly={true}
              rows={50}
              style={{ marginTop: 0, marginBottom: 50, width: "100%" }}
              size="small"
              color="primary"
              type="text"
              value={this.state.prompt}
            ></textarea>
          </div>
        </div>
      </div>
    );
  }
}

export default BlipComponent;
