import React from "react";
import styles from "./SpeechAnalysisComp.module.scss";
import CustomShapePieChart from "components/mantine-charts/CustomShapePieChart";
import HorizontalLineChart from "components/mantine-charts/horizontalLineChart";
interface IPronunciationAssessmentScoresProps {
  scores: TPronunciationAssessment;
}
// 분석 결과 수평차트
const PronunciationAssessmentScores = ({
  scores,
}: IPronunciationAssessmentScoresProps) => {
  return (
    <div className={styles.scores}>
      <div className={styles.pronScore}>
        <span>pronunciation assessment score</span>
        <CustomShapePieChart score={scores.PronScore} />
      </div>

      <div className={styles.otherScores}>
        <div className={styles.scoresItem}>
          <div className={styles.scoresLabel}>
            <span>accuracy score</span>
            <span>{`${scores.AccuracyScore}/100`}</span>
          </div>
          <HorizontalLineChart score={scores.AccuracyScore} />
        </div>
        <div className={styles.scoresItem}>
          <div className={styles.scoresLabel}>
            <span>fluency score</span>
            <span>{`${scores.FluencyScore}/100`}</span>
          </div>
          <HorizontalLineChart score={scores.FluencyScore} />
        </div>
        <div className={styles.scoresItem}>
          <div className={styles.scoresLabel}>
            <span>prosody score</span>
            <span>{`${scores.ProsodyScore}/100`}</span>
          </div>
          <HorizontalLineChart score={scores.ProsodyScore} />
        </div>
        <div className={styles.scoresItem}>
          <div className={styles.scoresLabel}>
            <span>completeness score</span>
            <span>{`${scores.CompletenessScore}/100`}</span>
          </div>
          <HorizontalLineChart score={scores.CompletenessScore} />
        </div>
      </div>
    </div>
  );
};
export default PronunciationAssessmentScores;
