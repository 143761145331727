import React from "react";
import RoleplayChatComponent from "./RoleplayChatComponent";

export default function RoleplayChat() {
  return (
    <div className="App">
      <RoleplayChatComponent />
    </div>
  );
}
