export const sampleDatas: {
  name: string;
  data: TSpeechAnalysisData;
  scriptText: string;
}[] = [
  {
    name: "sample-1",
    data: [
      {
        Id: "beb55fe9f31a4cd7a65de803a2a6803c",
        RecognitionStatus: 0,
        Offset: 800000,
        Duration: 155700000,
        Channel: 0,
        DisplayText:
          "Today was a beautiful day. We had a great time taking a long, long walk. In the morning. The countryside was in full bloom, yet the air was crisp and cold. Towards end of the day, clouds came in, forecasting much needed rain.",
        SNR: 40.36583,
        NBest: [
          {
            Confidence: 0.97722334,
            Lexical:
              "today was a beautiful day we had a great time taking a long long walk in the morning the countryside was in full bloom yet the air was crisp and cold towards end of the day clouds came in forecasting much needed rain",
            ITN: "today was a beautiful day we had a great time taking a long long walk in the morning the countryside was in full bloom yet the air was crisp and cold towards end of the day clouds came in forecasting much needed rain",
            MaskedITN:
              "today was a beautiful day we had a great time taking a long long walk in the morning the countryside was in full bloom yet the air was crisp and cold towards end of the day clouds came in forecasting much needed rain",
            Display:
              "Today was a beautiful day. We had a great time taking a long, long walk. In the morning. The countryside was in full bloom, yet the air was crisp and cold. Towards end of the day, clouds came in, forecasting much needed rain.",
            PronunciationAssessment: {
              AccuracyScore: 92,
              FluencyScore: 84,
              ProsodyScore: 86.8,
              CompletenessScore: 93,
              PronScore: 88,
            },
            Words: [
              {
                Word: "today",
                Offset: 800000,
                Duration: 4500000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "tə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 800000,
                    Duration: 2500000,
                  },
                  {
                    Syllable: "deɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 3400000,
                    Duration: 1900000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "t",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 100,
                        },
                        {
                          Phoneme: "tʃ",
                          Score: 9,
                        },
                        {
                          Phoneme: "k",
                          Score: 4,
                        },
                        {
                          Phoneme: "ə",
                          Score: 3,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 3,
                        },
                      ],
                    },
                    Offset: 800000,
                    Duration: 1700000,
                  },
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ə",
                          Score: 100,
                        },
                        {
                          Phoneme: "d",
                          Score: 50,
                        },
                        {
                          Phoneme: "ʊ",
                          Score: 30,
                        },
                        {
                          Phoneme: "t",
                          Score: 17,
                        },
                        {
                          Phoneme: "u",
                          Score: 10,
                        },
                      ],
                    },
                    Offset: 2600000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "d",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "d",
                          Score: 100,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 45,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 1,
                        },
                        {
                          Phoneme: "ð",
                          Score: 0,
                        },
                        {
                          Phoneme: "t",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 3400000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "eɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "eɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "w",
                          Score: 13,
                        },
                        {
                          Phoneme: "d",
                          Score: 6,
                        },
                        {
                          Phoneme: "t",
                          Score: 6,
                        },
                        {
                          Phoneme: "l",
                          Score: 3,
                        },
                      ],
                    },
                    Offset: 4200000,
                    Duration: 1100000,
                  },
                ],
              },
              {
                Word: "was",
                Offset: 5400000,
                Duration: 2100000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "wəz",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 5400000,
                    Duration: 2100000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "w",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "w",
                          Score: 100,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 20,
                        },
                        {
                          Phoneme: "l",
                          Score: 5,
                        },
                        {
                          Phoneme: "r",
                          Score: 3,
                        },
                        {
                          Phoneme: "ə",
                          Score: 2,
                        },
                      ],
                    },
                    Offset: 5400000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ə",
                          Score: 100,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 5,
                        },
                        {
                          Phoneme: "w",
                          Score: 1,
                        },
                        {
                          Phoneme: "z",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 6200000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "z",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "z",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 8,
                        },
                        {
                          Phoneme: "ð",
                          Score: 4,
                        },
                        {
                          Phoneme: "s",
                          Score: 1,
                        },
                        {
                          Phoneme: "d",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 7000000,
                    Duration: 500000,
                  },
                ],
              },
              {
                Word: "a",
                Offset: 7600000,
                Duration: 700000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 7600000,
                    Duration: 700000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ə",
                          Score: 100,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 2,
                        },
                        {
                          Phoneme: "ð",
                          Score: 2,
                        },
                        {
                          Phoneme: "z",
                          Score: 1,
                        },
                        {
                          Phoneme: "t",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 7600000,
                    Duration: 700000,
                  },
                ],
              },
              {
                Word: "beautiful",
                Offset: 8400000,
                Duration: 4700000,
                PronunciationAssessment: {
                  AccuracyScore: 98,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "bju",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 8400000,
                    Duration: 1900000,
                  },
                  {
                    Syllable: "tɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 89,
                    },
                    Offset: 10400000,
                    Duration: 1000000,
                  },
                  {
                    Syllable: "fəl",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 11500000,
                    Duration: 1600000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "b",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "b",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 49,
                        },
                        {
                          Phoneme: "j",
                          Score: 3,
                        },
                        {
                          Phoneme: "r",
                          Score: 1,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 8400000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "j",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "j",
                          Score: 100,
                        },
                        {
                          Phoneme: "b",
                          Score: 1,
                        },
                        {
                          Phoneme: "u",
                          Score: 1,
                        },
                        {
                          Phoneme: "ʊ",
                          Score: 0,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 9200000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "u",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "u",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 2,
                        },
                        {
                          Phoneme: "j",
                          Score: 2,
                        },
                        {
                          Phoneme: "ʊ",
                          Score: 1,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 10000000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "t",
                    PronunciationAssessment: {
                      AccuracyScore: 84,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 100,
                        },
                        {
                          Phoneme: "d",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                        {
                          Phoneme: "n",
                          Score: 0,
                        },
                        {
                          Phoneme: "u",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 10400000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "ɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 92,
                      NBestPhonemes: [
                        {
                          Phoneme: "ə",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 90,
                        },
                        {
                          Phoneme: "f",
                          Score: 1,
                        },
                        {
                          Phoneme: "t",
                          Score: 0,
                        },
                        {
                          Phoneme: "r",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 10800000,
                    Duration: 600000,
                  },
                  {
                    Phoneme: "f",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "f",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 90,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 2,
                        },
                        {
                          Phoneme: "k",
                          Score: 0,
                        },
                        {
                          Phoneme: "p",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 11500000,
                    Duration: 400000,
                  },
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ə",
                          Score: 100,
                        },
                        {
                          Phoneme: "l",
                          Score: 5,
                        },
                        {
                          Phoneme: "ʊ",
                          Score: 0,
                        },
                        {
                          Phoneme: "f",
                          Score: 0,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 12000000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "l",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "l",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 1,
                        },
                        {
                          Phoneme: "d",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                        {
                          Phoneme: "r",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 12800000,
                    Duration: 300000,
                  },
                ],
              },
              {
                Word: "day",
                Offset: 13200000,
                Duration: 4100000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "deɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 13200000,
                    Duration: 4100000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "d",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "d",
                          Score: 100,
                        },
                        {
                          Phoneme: "l",
                          Score: 20,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɡ",
                          Score: 0,
                        },
                        {
                          Phoneme: "t",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 13200000,
                    Duration: 1100000,
                  },
                  {
                    Phoneme: "eɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "eɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 14,
                        },
                        {
                          Phoneme: "k",
                          Score: 6,
                        },
                        {
                          Phoneme: "i",
                          Score: 5,
                        },
                        {
                          Phoneme: "d",
                          Score: 5,
                        },
                      ],
                    },
                    Offset: 14400000,
                    Duration: 2900000,
                  },
                ],
                Punctuation: ".",
              },
              {
                Word: "we",
                Offset: 21200000,
                Duration: 2500000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 1.4848046,
                        },
                        MissingBreak: {
                          Confidence: 0,
                        },
                        BreakLength: 3800000,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "wi",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 21200000,
                    Duration: 2500000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "w",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "w",
                          Score: 100,
                        },
                        {
                          Phoneme: "i",
                          Score: 2,
                        },
                        {
                          Phoneme: "r",
                          Score: 1,
                        },
                        {
                          Phoneme: "k",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɡ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 21200000,
                    Duration: 1900000,
                  },
                  {
                    Phoneme: "i",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "i",
                          Score: 100,
                        },
                        {
                          Phoneme: "w",
                          Score: 6,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                        {
                          Phoneme: "h",
                          Score: 0,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 23200000,
                    Duration: 500000,
                  },
                ],
              },
              {
                Word: "had",
                Offset: 23800000,
                Duration: 3100000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "hæd",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 23800000,
                    Duration: 3100000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "h",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "h",
                          Score: 100,
                        },
                        {
                          Phoneme: "i",
                          Score: 25,
                        },
                        {
                          Phoneme: "d",
                          Score: 2,
                        },
                        {
                          Phoneme: "æ",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 23800000,
                    Duration: 1100000,
                  },
                  {
                    Phoneme: "æ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "æ",
                          Score: 100,
                        },
                        {
                          Phoneme: "d",
                          Score: 16,
                        },
                        {
                          Phoneme: "h",
                          Score: 8,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 5,
                        },
                        {
                          Phoneme: "ɑ",
                          Score: 2,
                        },
                      ],
                    },
                    Offset: 25000000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "d",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "d",
                          Score: 100,
                        },
                        {
                          Phoneme: "v",
                          Score: 9,
                        },
                        {
                          Phoneme: "t",
                          Score: 5,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 5,
                        },
                        {
                          Phoneme: "ə",
                          Score: 4,
                        },
                      ],
                    },
                    Offset: 26000000,
                    Duration: 900000,
                  },
                ],
              },
              {
                Word: "a",
                Offset: 27000000,
                Duration: 700000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "ʌ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 27000000,
                    Duration: 700000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "ʌ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ʌ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 91,
                        },
                        {
                          Phoneme: "ɡ",
                          Score: 33,
                        },
                        {
                          Phoneme: "d",
                          Score: 2,
                        },
                        {
                          Phoneme: "r",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 27000000,
                    Duration: 700000,
                  },
                ],
              },
              {
                Word: "great",
                Offset: 27800000,
                Duration: 2900000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "ɡreɪt",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 27800000,
                    Duration: 2900000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "ɡ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɡ",
                          Score: 100,
                        },
                        {
                          Phoneme: "k",
                          Score: 8,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 2,
                        },
                        {
                          Phoneme: "w",
                          Score: 2,
                        },
                        {
                          Phoneme: "ə",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 27800000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "r",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "r",
                          Score: 100,
                        },
                        {
                          Phoneme: "l",
                          Score: 24,
                        },
                        {
                          Phoneme: "w",
                          Score: 13,
                        },
                        {
                          Phoneme: "ɡ",
                          Score: 6,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 28600000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "eɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "eɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "r",
                          Score: 55,
                        },
                        {
                          Phoneme: "t",
                          Score: 26,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 9,
                        },
                        {
                          Phoneme: "i",
                          Score: 3,
                        },
                      ],
                    },
                    Offset: 29200000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "t",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 100,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 11,
                        },
                        {
                          Phoneme: "d",
                          Score: 2,
                        },
                        {
                          Phoneme: "ɡ",
                          Score: 1,
                        },
                        {
                          Phoneme: "p",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 30000000,
                    Duration: 700000,
                  },
                ],
              },
              {
                Word: "time",
                Offset: 30800000,
                Duration: 2900000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "taɪm",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 30800000,
                    Duration: 2900000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "t",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 100,
                        },
                        {
                          Phoneme: "aɪ",
                          Score: 50,
                        },
                        {
                          Phoneme: "h",
                          Score: 1,
                        },
                        {
                          Phoneme: "æ",
                          Score: 0,
                        },
                        {
                          Phoneme: "aʊ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 30800000,
                    Duration: 1100000,
                  },
                  {
                    Phoneme: "aɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "aɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "æ",
                          Score: 2,
                        },
                        {
                          Phoneme: "t",
                          Score: 2,
                        },
                        {
                          Phoneme: "m",
                          Score: 1,
                        },
                        {
                          Phoneme: "aʊ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 32000000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "m",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "m",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 47,
                        },
                        {
                          Phoneme: "aɪ",
                          Score: 29,
                        },
                        {
                          Phoneme: "n",
                          Score: 2,
                        },
                        {
                          Phoneme: "d",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 32800000,
                    Duration: 900000,
                  },
                ],
              },
              {
                Word: "taking",
                Offset: 33800000,
                Duration: 4300000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "teɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 33800000,
                    Duration: 1900000,
                  },
                  {
                    Syllable: "kɪŋ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 35800000,
                    Duration: 2300000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "t",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 100,
                        },
                        {
                          Phoneme: "d",
                          Score: 1,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 1,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                        {
                          Phoneme: "m",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 33800000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "eɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "eɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 3,
                        },
                        {
                          Phoneme: "k",
                          Score: 2,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                        {
                          Phoneme: "æ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 34800000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "k",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "k",
                          Score: 100,
                        },
                        {
                          Phoneme: "h",
                          Score: 74,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɡ",
                          Score: 1,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 35800000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "ɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "h",
                          Score: 83,
                        },
                        {
                          Phoneme: "i",
                          Score: 5,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 4,
                        },
                        {
                          Phoneme: "k",
                          Score: 2,
                        },
                      ],
                    },
                    Offset: 36600000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "ŋ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ŋ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 66,
                        },
                        {
                          Phoneme: "ð",
                          Score: 42,
                        },
                        {
                          Phoneme: "m",
                          Score: 34,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 16,
                        },
                      ],
                    },
                    Offset: 37200000,
                    Duration: 900000,
                  },
                ],
              },
              {
                Word: "a",
                Offset: 38200000,
                Duration: 700000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 38200000,
                    Duration: 700000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ə",
                          Score: 100,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 81,
                        },
                        {
                          Phoneme: "l",
                          Score: 13,
                        },
                        {
                          Phoneme: "aɪ",
                          Score: 11,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 8,
                        },
                      ],
                    },
                    Offset: 38200000,
                    Duration: 700000,
                  },
                ],
              },
              {
                Word: "long",
                Offset: 39000000,
                Duration: 4100000,
                PronunciationAssessment: {
                  AccuracyScore: 99,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "lɔŋ",
                    PronunciationAssessment: {
                      AccuracyScore: 99,
                    },
                    Offset: 39000000,
                    Duration: 4100000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "l",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "l",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 53,
                        },
                        {
                          Phoneme: "ɔ",
                          Score: 20,
                        },
                        {
                          Phoneme: "ɑ",
                          Score: 11,
                        },
                        {
                          Phoneme: "r",
                          Score: 10,
                        },
                      ],
                    },
                    Offset: 39000000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "ɔ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɔ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɑ",
                          Score: 90,
                        },
                        {
                          Phoneme: "ə",
                          Score: 9,
                        },
                        {
                          Phoneme: "l",
                          Score: 8,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 6,
                        },
                      ],
                    },
                    Offset: 40000000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "ŋ",
                    PronunciationAssessment: {
                      AccuracyScore: 98,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɔ",
                          Score: 97,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 93,
                        },
                        {
                          Phoneme: "l",
                          Score: 91,
                        },
                        {
                          Phoneme: "ɑ",
                          Score: 45,
                        },
                        {
                          Phoneme: "ə",
                          Score: 25,
                        },
                      ],
                    },
                    Offset: 41000000,
                    Duration: 2100000,
                  },
                ],
              },
              {
                Word: "long",
                Offset: 44700000,
                Duration: 3800000,
                PronunciationAssessment: {
                  AccuracyScore: 96,
                  ErrorType: "Insertion",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["UnexpectedBreak"],
                        UnexpectedBreak: {
                          Confidence: 0.58610713,
                        },
                        MissingBreak: {
                          Confidence: 0,
                        },
                        BreakLength: 1500000,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "lɔŋ",
                    PronunciationAssessment: {
                      AccuracyScore: 96,
                    },
                    Offset: 44700000,
                    Duration: 3800000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "l",
                    PronunciationAssessment: {
                      AccuracyScore: 91,
                      NBestPhonemes: [
                        {
                          Phoneme: "l",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɑ",
                          Score: 19,
                        },
                        {
                          Phoneme: "ɔ",
                          Score: 4,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 3,
                        },
                        {
                          Phoneme: "r",
                          Score: 3,
                        },
                      ],
                    },
                    Offset: 44700000,
                    Duration: 1800000,
                  },
                  {
                    Phoneme: "ɔ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɑ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɔ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 34,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 3,
                        },
                        {
                          Phoneme: "l",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 46600000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "ŋ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ŋ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɑ",
                          Score: 45,
                        },
                        {
                          Phoneme: "w",
                          Score: 30,
                        },
                        {
                          Phoneme: "ɡ",
                          Score: 2,
                        },
                        {
                          Phoneme: "ɔ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 47400000,
                    Duration: 1100000,
                  },
                ],
              },
              {
                Word: "walk",
                Offset: 48600000,
                Duration: 2900000,
                PronunciationAssessment: {
                  AccuracyScore: 96,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "wɔk",
                    PronunciationAssessment: {
                      AccuracyScore: 96,
                    },
                    Offset: 48600000,
                    Duration: 2900000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "w",
                    PronunciationAssessment: {
                      AccuracyScore: 91,
                      NBestPhonemes: [
                        {
                          Phoneme: "w",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɑ",
                          Score: 8,
                        },
                        {
                          Phoneme: "r",
                          Score: 5,
                        },
                        {
                          Phoneme: "l",
                          Score: 3,
                        },
                        {
                          Phoneme: "æ",
                          Score: 2,
                        },
                      ],
                    },
                    Offset: 48600000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "ɔ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɔ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɑ",
                          Score: 97,
                        },
                        {
                          Phoneme: "k",
                          Score: 8,
                        },
                        {
                          Phoneme: "l",
                          Score: 4,
                        },
                        {
                          Phoneme: "w",
                          Score: 4,
                        },
                      ],
                    },
                    Offset: 49200000,
                    Duration: 1100000,
                  },
                  {
                    Phoneme: "k",
                    PronunciationAssessment: {
                      AccuracyScore: 94,
                      NBestPhonemes: [
                        {
                          Phoneme: "k",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 40,
                        },
                        {
                          Phoneme: "ɔ",
                          Score: 5,
                        },
                        {
                          Phoneme: "ɡ",
                          Score: 1,
                        },
                        {
                          Phoneme: "t",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 50400000,
                    Duration: 1100000,
                  },
                ],
              },
              {
                Word: "outside",
                PronunciationAssessment: {
                  ErrorType: "Omission",
                },
                Phonemes: [],
              },
              {
                Word: "in",
                Offset: 51600000,
                Duration: 1100000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "ɪn",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 51600000,
                    Duration: 1100000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "ɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 4,
                        },
                        {
                          Phoneme: "n",
                          Score: 0,
                        },
                        {
                          Phoneme: "i",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 51600000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "n",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "n",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 50,
                        },
                        {
                          Phoneme: "m",
                          Score: 7,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 1,
                        },
                        {
                          Phoneme: "ð",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 52000000,
                    Duration: 700000,
                  },
                ],
              },
              {
                Word: "the",
                Offset: 52800000,
                Duration: 900000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "ðʌ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 52800000,
                    Duration: 900000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "ð",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ð",
                          Score: 100,
                        },
                        {
                          Phoneme: "m",
                          Score: 13,
                        },
                        {
                          Phoneme: "l",
                          Score: 6,
                        },
                        {
                          Phoneme: "ə",
                          Score: 3,
                        },
                        {
                          Phoneme: "n",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 52800000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "ʌ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ʌ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 75,
                        },
                        {
                          Phoneme: "m",
                          Score: 15,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 1,
                        },
                        {
                          Phoneme: "r",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 53200000,
                    Duration: 500000,
                  },
                ],
              },
              {
                Word: "morning",
                Offset: 53800000,
                Duration: 5700000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "mɔr",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 53800000,
                    Duration: 1900000,
                  },
                  {
                    Syllable: "nɪŋ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 55800000,
                    Duration: 3700000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "m",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "m",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɔ",
                          Score: 0,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 0,
                        },
                        {
                          Phoneme: "n",
                          Score: 0,
                        },
                        {
                          Phoneme: "w",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 53800000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "ɔ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɔ",
                          Score: 100,
                        },
                        {
                          Phoneme: "m",
                          Score: 8,
                        },
                        {
                          Phoneme: "r",
                          Score: 3,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 2,
                        },
                        {
                          Phoneme: "ɔɪ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 54600000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "r",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "r",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɔ",
                          Score: 57,
                        },
                        {
                          Phoneme: "n",
                          Score: 4,
                        },
                        {
                          Phoneme: "ɑ",
                          Score: 1,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 55200000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "n",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "n",
                          Score: 100,
                        },
                        {
                          Phoneme: "r",
                          Score: 26,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 15,
                        },
                        {
                          Phoneme: "ɔɪ",
                          Score: 0,
                        },
                        {
                          Phoneme: "m",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 55800000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "ɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 59,
                        },
                        {
                          Phoneme: "ə",
                          Score: 1,
                        },
                        {
                          Phoneme: "n",
                          Score: 0,
                        },
                        {
                          Phoneme: "i",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 56800000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "ŋ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ŋ",
                          Score: 100,
                        },
                        {
                          Phoneme: "n",
                          Score: 9,
                        },
                        {
                          Phoneme: "m",
                          Score: 3,
                        },
                        {
                          Phoneme: "i",
                          Score: 2,
                        },
                        {
                          Phoneme: "d",
                          Score: 2,
                        },
                      ],
                    },
                    Offset: 57600000,
                    Duration: 1900000,
                  },
                ],
                Punctuation: ".",
              },
              {
                Word: "the",
                Offset: 63800000,
                Duration: 2300000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 1.6410998,
                        },
                        MissingBreak: {
                          Confidence: 0,
                        },
                        BreakLength: 4200000,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "ðʌ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 63800000,
                    Duration: 2300000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "ð",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ð",
                          Score: 100,
                        },
                        {
                          Phoneme: "m",
                          Score: 94,
                        },
                        {
                          Phoneme: "v",
                          Score: 73,
                        },
                        {
                          Phoneme: "b",
                          Score: 68,
                        },
                        {
                          Phoneme: "ə",
                          Score: 19,
                        },
                      ],
                    },
                    Offset: 63800000,
                    Duration: 1500000,
                  },
                  {
                    Phoneme: "ʌ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ə",
                          Score: 100,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 100,
                        },
                        {
                          Phoneme: "k",
                          Score: 75,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 60,
                        },
                        {
                          Phoneme: "t",
                          Score: 11,
                        },
                      ],
                    },
                    Offset: 65400000,
                    Duration: 700000,
                  },
                ],
              },
              {
                Word: "countryside",
                Offset: 66200000,
                Duration: 7900000,
                PronunciationAssessment: {
                  AccuracyScore: 30,
                  ErrorType: "Mispronunciation",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "kʌn",
                    PronunciationAssessment: {
                      AccuracyScore: 9,
                    },
                    Offset: 66200000,
                    Duration: 2700000,
                  },
                  {
                    Syllable: "tri",
                    PronunciationAssessment: {
                      AccuracyScore: 33,
                    },
                    Offset: 69000000,
                    Duration: 1100000,
                  },
                  {
                    Syllable: "saɪd",
                    PronunciationAssessment: {
                      AccuracyScore: 45,
                    },
                    Offset: 70200000,
                    Duration: 3900000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "k",
                    PronunciationAssessment: {
                      AccuracyScore: 11,
                      NBestPhonemes: [
                        {
                          Phoneme: "k",
                          Score: 100,
                        },
                        {
                          Phoneme: "aʊ",
                          Score: 73,
                        },
                        {
                          Phoneme: "t",
                          Score: 16,
                        },
                        {
                          Phoneme: "æ",
                          Score: 2,
                        },
                        {
                          Phoneme: "tʃ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 66200000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "ʌ",
                    PronunciationAssessment: {
                      AccuracyScore: 0,
                      NBestPhonemes: [
                        {
                          Phoneme: "aʊ",
                          Score: 100,
                        },
                        {
                          Phoneme: "n",
                          Score: 74,
                        },
                        {
                          Phoneme: "k",
                          Score: 3,
                        },
                        {
                          Phoneme: "æ",
                          Score: 2,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 67200000,
                    Duration: 1000000,
                  },
                  {
                    Phoneme: "n",
                    PronunciationAssessment: {
                      AccuracyScore: 19,
                      NBestPhonemes: [
                        {
                          Phoneme: "n",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 13,
                        },
                        {
                          Phoneme: "l",
                          Score: 0,
                        },
                        {
                          Phoneme: "aʊ",
                          Score: 0,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 68300000,
                    Duration: 600000,
                  },
                  {
                    Phoneme: "t",
                    PronunciationAssessment: {
                      AccuracyScore: 29,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 100,
                        },
                        {
                          Phoneme: "h",
                          Score: 11,
                        },
                        {
                          Phoneme: "n",
                          Score: 3,
                        },
                        {
                          Phoneme: "d",
                          Score: 2,
                        },
                        {
                          Phoneme: "s",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 69000000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "r",
                    PronunciationAssessment: {
                      AccuracyScore: 40,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 100,
                        },
                        {
                          Phoneme: "i",
                          Score: 97,
                        },
                        {
                          Phoneme: "h",
                          Score: 57,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 42,
                        },
                        {
                          Phoneme: "l",
                          Score: 2,
                        },
                      ],
                    },
                    Offset: 69400000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "i",
                    PronunciationAssessment: {
                      AccuracyScore: 30,
                      NBestPhonemes: [
                        {
                          Phoneme: "i",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 57,
                        },
                        {
                          Phoneme: "z",
                          Score: 4,
                        },
                        {
                          Phoneme: "ə",
                          Score: 2,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 69800000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "s",
                    PronunciationAssessment: {
                      AccuracyScore: 18,
                      NBestPhonemes: [
                        {
                          Phoneme: "s",
                          Score: 100,
                        },
                        {
                          Phoneme: "l",
                          Score: 100,
                        },
                        {
                          Phoneme: "i",
                          Score: 42,
                        },
                        {
                          Phoneme: "z",
                          Score: 12,
                        },
                        {
                          Phoneme: "t",
                          Score: 3,
                        },
                      ],
                    },
                    Offset: 70200000,
                    Duration: 1700000,
                  },
                  {
                    Phoneme: "aɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 48,
                      NBestPhonemes: [
                        {
                          Phoneme: "aɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "l",
                          Score: 79,
                        },
                        {
                          Phoneme: "d",
                          Score: 27,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 6,
                        },
                        {
                          Phoneme: "æ",
                          Score: 6,
                        },
                      ],
                    },
                    Offset: 72000000,
                    Duration: 1300000,
                  },
                  {
                    Phoneme: "d",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "d",
                          Score: 100,
                        },
                        {
                          Phoneme: "w",
                          Score: 7,
                        },
                        {
                          Phoneme: "b",
                          Score: 2,
                        },
                        {
                          Phoneme: "t",
                          Score: 1,
                        },
                        {
                          Phoneme: "v",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 73400000,
                    Duration: 700000,
                  },
                ],
              },
              {
                Word: "was",
                Offset: 74200000,
                Duration: 1900000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "wʌz",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 74200000,
                    Duration: 1900000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "w",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "w",
                          Score: 100,
                        },
                        {
                          Phoneme: "d",
                          Score: 1,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 1,
                        },
                        {
                          Phoneme: "b",
                          Score: 0,
                        },
                        {
                          Phoneme: "l",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 74200000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "ʌ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ʌ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 85,
                        },
                        {
                          Phoneme: "w",
                          Score: 9,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                        {
                          Phoneme: "ʊ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 74600000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "z",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "z",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 57,
                        },
                        {
                          Phoneme: "ə",
                          Score: 5,
                        },
                        {
                          Phoneme: "ð",
                          Score: 1,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 75400000,
                    Duration: 700000,
                  },
                ],
              },
              {
                Word: "in",
                Offset: 76200000,
                Duration: 1500000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "ɪn",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 76200000,
                    Duration: 1500000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "ɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "n",
                          Score: 1,
                        },
                        {
                          Phoneme: "ə",
                          Score: 1,
                        },
                        {
                          Phoneme: "i",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 76200000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "n",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "n",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 6,
                        },
                        {
                          Phoneme: "ə",
                          Score: 2,
                        },
                        {
                          Phoneme: "f",
                          Score: 1,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 76800000,
                    Duration: 900000,
                  },
                ],
              },
              {
                Word: "full",
                Offset: 77800000,
                Duration: 2300000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "fʊl",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 77800000,
                    Duration: 2300000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "f",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "f",
                          Score: 100,
                        },
                        {
                          Phoneme: "ʊ",
                          Score: 5,
                        },
                        {
                          Phoneme: "n",
                          Score: 4,
                        },
                        {
                          Phoneme: "tʃ",
                          Score: 4,
                        },
                        {
                          Phoneme: "ə",
                          Score: 2,
                        },
                      ],
                    },
                    Offset: 77800000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "ʊ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ʊ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 5,
                        },
                        {
                          Phoneme: "u",
                          Score: 3,
                        },
                        {
                          Phoneme: "ɔ",
                          Score: 2,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 78800000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "l",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "l",
                          Score: 100,
                        },
                        {
                          Phoneme: "ʊ",
                          Score: 37,
                        },
                        {
                          Phoneme: "r",
                          Score: 13,
                        },
                        {
                          Phoneme: "b",
                          Score: 7,
                        },
                        {
                          Phoneme: "d",
                          Score: 3,
                        },
                      ],
                    },
                    Offset: 79400000,
                    Duration: 700000,
                  },
                ],
              },
              {
                Word: "bloom",
                Offset: 80200000,
                Duration: 4900000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "blum",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 80200000,
                    Duration: 4900000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "b",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "b",
                          Score: 100,
                        },
                        {
                          Phoneme: "l",
                          Score: 15,
                        },
                        {
                          Phoneme: "d",
                          Score: 6,
                        },
                        {
                          Phoneme: "r",
                          Score: 2,
                        },
                        {
                          Phoneme: "p",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 80200000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "l",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "l",
                          Score: 100,
                        },
                        {
                          Phoneme: "r",
                          Score: 2,
                        },
                        {
                          Phoneme: "b",
                          Score: 2,
                        },
                        {
                          Phoneme: "u",
                          Score: 0,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 81000000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "u",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "u",
                          Score: 100,
                        },
                        {
                          Phoneme: "l",
                          Score: 44,
                        },
                        {
                          Phoneme: "m",
                          Score: 15,
                        },
                        {
                          Phoneme: "r",
                          Score: 0,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 81600000,
                    Duration: 1100000,
                  },
                  {
                    Phoneme: "m",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "m",
                          Score: 100,
                        },
                        {
                          Phoneme: "u",
                          Score: 25,
                        },
                        {
                          Phoneme: "n",
                          Score: 9,
                        },
                        {
                          Phoneme: "p",
                          Score: 2,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 2,
                        },
                      ],
                    },
                    Offset: 82800000,
                    Duration: 2300000,
                  },
                ],
                Punctuation: ",",
              },
              {
                Word: "yet",
                Offset: 86500000,
                Duration: 2600000,
                PronunciationAssessment: {
                  AccuracyScore: 95,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 0.50795954,
                        },
                        MissingBreak: {
                          Confidence: 0,
                        },
                        BreakLength: 1300000,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "jɛt",
                    PronunciationAssessment: {
                      AccuracyScore: 95,
                    },
                    Offset: 86500000,
                    Duration: 2600000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "j",
                    PronunciationAssessment: {
                      AccuracyScore: 97,
                      NBestPhonemes: [
                        {
                          Phoneme: "j",
                          Score: 100,
                        },
                        {
                          Phoneme: "h",
                          Score: 1,
                        },
                        {
                          Phoneme: "n",
                          Score: 1,
                        },
                        {
                          Phoneme: "m",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɡ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 86500000,
                    Duration: 1200000,
                  },
                  {
                    Phoneme: "ɛ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɛ",
                          Score: 100,
                        },
                        {
                          Phoneme: "j",
                          Score: 59,
                        },
                        {
                          Phoneme: "t",
                          Score: 26,
                        },
                        {
                          Phoneme: "æ",
                          Score: 1,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 87800000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "t",
                    PronunciationAssessment: {
                      AccuracyScore: 82,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 100,
                        },
                        {
                          Phoneme: "b",
                          Score: 1,
                        },
                        {
                          Phoneme: "θ",
                          Score: 1,
                        },
                        {
                          Phoneme: "p",
                          Score: 1,
                        },
                        {
                          Phoneme: "ð",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 88600000,
                    Duration: 500000,
                  },
                ],
              },
              {
                Word: "the",
                Offset: 89200000,
                Duration: 1100000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "ðə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 89200000,
                    Duration: 1100000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "ð",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ð",
                          Score: 100,
                        },
                        {
                          Phoneme: "b",
                          Score: 9,
                        },
                        {
                          Phoneme: "d",
                          Score: 2,
                        },
                        {
                          Phoneme: "θ",
                          Score: 1,
                        },
                        {
                          Phoneme: "v",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 89200000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ə",
                          Score: 100,
                        },
                        {
                          Phoneme: "i",
                          Score: 77,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 35,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 16,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 13,
                        },
                      ],
                    },
                    Offset: 89600000,
                    Duration: 700000,
                  },
                ],
              },
              {
                Word: "air",
                Offset: 90400000,
                Duration: 2100000,
                PronunciationAssessment: {
                  AccuracyScore: 83,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "ɛr",
                    PronunciationAssessment: {
                      AccuracyScore: 83,
                    },
                    Offset: 90400000,
                    Duration: 2100000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "ɛ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɛ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 91,
                        },
                        {
                          Phoneme: "i",
                          Score: 27,
                        },
                        {
                          Phoneme: "ə",
                          Score: 14,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 13,
                        },
                      ],
                    },
                    Offset: 90400000,
                    Duration: 1100000,
                  },
                  {
                    Phoneme: "r",
                    PronunciationAssessment: {
                      AccuracyScore: 62,
                      NBestPhonemes: [
                        {
                          Phoneme: "l",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 95,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 47,
                        },
                        {
                          Phoneme: "r",
                          Score: 29,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 7,
                        },
                      ],
                    },
                    Offset: 91600000,
                    Duration: 900000,
                  },
                ],
              },
              {
                Word: "was",
                Offset: 92600000,
                Duration: 1500000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "wʌz",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 92600000,
                    Duration: 1500000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "w",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "w",
                          Score: 100,
                        },
                        {
                          Phoneme: "l",
                          Score: 63,
                        },
                        {
                          Phoneme: "d",
                          Score: 16,
                        },
                        {
                          Phoneme: "r",
                          Score: 8,
                        },
                        {
                          Phoneme: "t",
                          Score: 2,
                        },
                      ],
                    },
                    Offset: 92600000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "ʌ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ʌ",
                          Score: 100,
                        },
                        {
                          Phoneme: "z",
                          Score: 50,
                        },
                        {
                          Phoneme: "ə",
                          Score: 24,
                        },
                        {
                          Phoneme: "w",
                          Score: 6,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 4,
                        },
                      ],
                    },
                    Offset: 93000000,
                    Duration: 600000,
                  },
                  {
                    Phoneme: "z",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "z",
                          Score: 100,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 57,
                        },
                        {
                          Phoneme: "ə",
                          Score: 5,
                        },
                        {
                          Phoneme: "s",
                          Score: 3,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 2,
                        },
                      ],
                    },
                    Offset: 93700000,
                    Duration: 400000,
                  },
                ],
              },
              {
                Word: "crisp",
                Offset: 94200000,
                Duration: 4100000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "krɪsp",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 94200000,
                    Duration: 4100000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "k",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "k",
                          Score: 100,
                        },
                        {
                          Phoneme: "z",
                          Score: 41,
                        },
                        {
                          Phoneme: "w",
                          Score: 3,
                        },
                        {
                          Phoneme: "r",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɡ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 94200000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "r",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "r",
                          Score: 100,
                        },
                        {
                          Phoneme: "w",
                          Score: 76,
                        },
                        {
                          Phoneme: "l",
                          Score: 58,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 4,
                        },
                        {
                          Phoneme: "k",
                          Score: 2,
                        },
                      ],
                    },
                    Offset: 95200000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "ɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "s",
                          Score: 68,
                        },
                        {
                          Phoneme: "r",
                          Score: 4,
                        },
                        {
                          Phoneme: "ə",
                          Score: 2,
                        },
                        {
                          Phoneme: "i",
                          Score: 2,
                        },
                      ],
                    },
                    Offset: 96000000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "s",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "s",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 28,
                        },
                        {
                          Phoneme: "z",
                          Score: 14,
                        },
                        {
                          Phoneme: "p",
                          Score: 13,
                        },
                        {
                          Phoneme: "ə",
                          Score: 3,
                        },
                      ],
                    },
                    Offset: 96800000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "p",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 100,
                        },
                        {
                          Phoneme: "p",
                          Score: 100,
                        },
                        {
                          Phoneme: "d",
                          Score: 25,
                        },
                        {
                          Phoneme: "b",
                          Score: 10,
                        },
                        {
                          Phoneme: "θ",
                          Score: 5,
                        },
                      ],
                    },
                    Offset: 97600000,
                    Duration: 700000,
                  },
                ],
              },
              {
                Word: "and",
                Offset: 98400000,
                Duration: 1900000,
                PronunciationAssessment: {
                  AccuracyScore: 93,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "ænd",
                    PronunciationAssessment: {
                      AccuracyScore: 93,
                    },
                    Offset: 98400000,
                    Duration: 1900000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "æ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "æ",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 91,
                        },
                        {
                          Phoneme: "n",
                          Score: 75,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 10,
                        },
                        {
                          Phoneme: "p",
                          Score: 8,
                        },
                      ],
                    },
                    Offset: 98400000,
                    Duration: 600000,
                  },
                  {
                    Phoneme: "n",
                    PronunciationAssessment: {
                      AccuracyScore: 96,
                      NBestPhonemes: [
                        {
                          Phoneme: "n",
                          Score: 100,
                        },
                        {
                          Phoneme: "æ",
                          Score: 3,
                        },
                        {
                          Phoneme: "ə",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 1,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 99100000,
                    Duration: 400000,
                  },
                  {
                    Phoneme: "d",
                    PronunciationAssessment: {
                      AccuracyScore: 86,
                      NBestPhonemes: [
                        {
                          Phoneme: "n",
                          Score: 100,
                        },
                        {
                          Phoneme: "k",
                          Score: 100,
                        },
                        {
                          Phoneme: "d",
                          Score: 21,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 5,
                        },
                        {
                          Phoneme: "t",
                          Score: 2,
                        },
                      ],
                    },
                    Offset: 99600000,
                    Duration: 700000,
                  },
                ],
              },
              {
                Word: "cold",
                Offset: 100400000,
                Duration: 5300000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "koʊld",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 100400000,
                    Duration: 5300000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "k",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "k",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 3,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 2,
                        },
                        {
                          Phoneme: "ɡ",
                          Score: 1,
                        },
                        {
                          Phoneme: "t",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 100400000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "oʊ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "oʊ",
                          Score: 100,
                        },
                        {
                          Phoneme: "l",
                          Score: 84,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 82,
                        },
                        {
                          Phoneme: "k",
                          Score: 37,
                        },
                        {
                          Phoneme: "ɔ",
                          Score: 15,
                        },
                      ],
                    },
                    Offset: 100800000,
                    Duration: 1900000,
                  },
                  {
                    Phoneme: "l",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "l",
                          Score: 100,
                        },
                        {
                          Phoneme: "d",
                          Score: 30,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 3,
                        },
                        {
                          Phoneme: "r",
                          Score: 2,
                        },
                        {
                          Phoneme: "ə",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 102800000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "d",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "d",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 52,
                        },
                        {
                          Phoneme: "l",
                          Score: 9,
                        },
                        {
                          Phoneme: "z",
                          Score: 7,
                        },
                        {
                          Phoneme: "θ",
                          Score: 4,
                        },
                      ],
                    },
                    Offset: 103400000,
                    Duration: 2300000,
                  },
                ],
                Punctuation: ".",
              },
              {
                Word: "towards",
                Offset: 110000000,
                Duration: 5500000,
                PronunciationAssessment: {
                  AccuracyScore: 72,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 1.6410998,
                        },
                        MissingBreak: {
                          Confidence: 0,
                        },
                        BreakLength: 4200000,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "tə",
                    PronunciationAssessment: {
                      AccuracyScore: 67,
                    },
                    Offset: 110000000,
                    Duration: 2100000,
                  },
                  {
                    Syllable: "wɔrdz",
                    PronunciationAssessment: {
                      AccuracyScore: 76,
                    },
                    Offset: 112200000,
                    Duration: 3300000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "t",
                    PronunciationAssessment: {
                      AccuracyScore: 77,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 100,
                        },
                        {
                          Phoneme: "tʃ",
                          Score: 14,
                        },
                        {
                          Phoneme: "k",
                          Score: 4,
                        },
                        {
                          Phoneme: "dʒ",
                          Score: 3,
                        },
                        {
                          Phoneme: "p",
                          Score: 3,
                        },
                      ],
                    },
                    Offset: 110000000,
                    Duration: 1700000,
                  },
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 20,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɔ",
                          Score: 8,
                        },
                        {
                          Phoneme: "ɔɪ",
                          Score: 8,
                        },
                        {
                          Phoneme: "ʊ",
                          Score: 1,
                        },
                        {
                          Phoneme: "r",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 111800000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "w",
                    PronunciationAssessment: {
                      AccuracyScore: 18,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɔ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɔɪ",
                          Score: 72,
                        },
                        {
                          Phoneme: "t",
                          Score: 11,
                        },
                        {
                          Phoneme: "ʊ",
                          Score: 5,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 3,
                        },
                      ],
                    },
                    Offset: 112200000,
                    Duration: 200000,
                  },
                  {
                    Phoneme: "ɔ",
                    PronunciationAssessment: {
                      AccuracyScore: 65,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɔ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɔɪ",
                          Score: 44,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 4,
                        },
                        {
                          Phoneme: "r",
                          Score: 3,
                        },
                        {
                          Phoneme: "ʊ",
                          Score: 2,
                        },
                      ],
                    },
                    Offset: 112500000,
                    Duration: 200000,
                  },
                  {
                    Phoneme: "r",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "r",
                          Score: 100,
                        },
                        {
                          Phoneme: "d",
                          Score: 54,
                        },
                        {
                          Phoneme: "ɔ",
                          Score: 49,
                        },
                        {
                          Phoneme: "ɔɪ",
                          Score: 29,
                        },
                        {
                          Phoneme: "l",
                          Score: 4,
                        },
                      ],
                    },
                    Offset: 112800000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "d",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "d",
                          Score: 100,
                        },
                        {
                          Phoneme: "z",
                          Score: 12,
                        },
                        {
                          Phoneme: "r",
                          Score: 5,
                        },
                        {
                          Phoneme: "t",
                          Score: 1,
                        },
                        {
                          Phoneme: "n",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 113800000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "z",
                    PronunciationAssessment: {
                      AccuracyScore: 60,
                      NBestPhonemes: [
                        {
                          Phoneme: "z",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 93,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 69,
                        },
                        {
                          Phoneme: "æ",
                          Score: 23,
                        },
                        {
                          Phoneme: "s",
                          Score: 14,
                        },
                      ],
                    },
                    Offset: 114400000,
                    Duration: 1100000,
                  },
                ],
              },
              {
                Word: "the",
                PronunciationAssessment: {
                  ErrorType: "Omission",
                },
                Phonemes: [],
              },
              {
                Word: "end",
                Offset: 115600000,
                Duration: 1500000,
                PronunciationAssessment: {
                  AccuracyScore: 75,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "ɛnd",
                    PronunciationAssessment: {
                      AccuracyScore: 75,
                    },
                    Offset: 115600000,
                    Duration: 1500000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "ɛ",
                    PronunciationAssessment: {
                      AccuracyScore: 60,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɛ",
                          Score: 100,
                        },
                        {
                          Phoneme: "n",
                          Score: 64,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 30,
                        },
                        {
                          Phoneme: "æ",
                          Score: 9,
                        },
                        {
                          Phoneme: "z",
                          Score: 4,
                        },
                      ],
                    },
                    Offset: 115600000,
                    Duration: 400000,
                  },
                  {
                    Phoneme: "n",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "n",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 59,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 1,
                        },
                        {
                          Phoneme: "d",
                          Score: 0,
                        },
                        {
                          Phoneme: "m",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 116100000,
                    Duration: 400000,
                  },
                  {
                    Phoneme: "d",
                    PronunciationAssessment: {
                      AccuracyScore: 66,
                      NBestPhonemes: [
                        {
                          Phoneme: "d",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 65,
                        },
                        {
                          Phoneme: "n",
                          Score: 52,
                        },
                        {
                          Phoneme: "k",
                          Score: 2,
                        },
                        {
                          Phoneme: "z",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 116600000,
                    Duration: 500000,
                  },
                ],
              },
              {
                Word: "of",
                Offset: 117200000,
                Duration: 1000000,
                PronunciationAssessment: {
                  AccuracyScore: 95,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "əv",
                    PronunciationAssessment: {
                      AccuracyScore: 95,
                    },
                    Offset: 117200000,
                    Duration: 1000000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ə",
                          Score: 100,
                        },
                        {
                          Phoneme: "r",
                          Score: 2,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 2,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 1,
                        },
                        {
                          Phoneme: "d",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 117200000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "v",
                    PronunciationAssessment: {
                      AccuracyScore: 89,
                      NBestPhonemes: [
                        {
                          Phoneme: "v",
                          Score: 100,
                        },
                        {
                          Phoneme: "ð",
                          Score: 51,
                        },
                        {
                          Phoneme: "ə",
                          Score: 41,
                        },
                        {
                          Phoneme: "r",
                          Score: 31,
                        },
                        {
                          Phoneme: "d",
                          Score: 2,
                        },
                      ],
                    },
                    Offset: 117800000,
                    Duration: 400000,
                  },
                ],
              },
              {
                Word: "the",
                Offset: 118300000,
                Duration: 1200000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "ðə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 118300000,
                    Duration: 1200000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "ð",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ð",
                          Score: 100,
                        },
                        {
                          Phoneme: "v",
                          Score: 71,
                        },
                        {
                          Phoneme: "d",
                          Score: 0,
                        },
                        {
                          Phoneme: "t",
                          Score: 0,
                        },
                        {
                          Phoneme: "r",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 118300000,
                    Duration: 400000,
                  },
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ə",
                          Score: 100,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 14,
                        },
                        {
                          Phoneme: "d",
                          Score: 2,
                        },
                        {
                          Phoneme: "r",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 118800000,
                    Duration: 700000,
                  },
                ],
              },
              {
                Word: "day",
                Offset: 119600000,
                Duration: 3700000,
                PronunciationAssessment: {
                  AccuracyScore: 96,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "deɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 96,
                    },
                    Offset: 119600000,
                    Duration: 3700000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "d",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "d",
                          Score: 100,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 43,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 3,
                        },
                        {
                          Phoneme: "t",
                          Score: 1,
                        },
                        {
                          Phoneme: "æ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 119600000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "eɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 95,
                      NBestPhonemes: [
                        {
                          Phoneme: "eɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 89,
                        },
                        {
                          Phoneme: "k",
                          Score: 24,
                        },
                        {
                          Phoneme: "i",
                          Score: 22,
                        },
                        {
                          Phoneme: "n",
                          Score: 9,
                        },
                      ],
                    },
                    Offset: 120600000,
                    Duration: 2700000,
                  },
                ],
                Punctuation: ",",
              },
              {
                Word: "clouds",
                Offset: 124700000,
                Duration: 4600000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 0.50795954,
                        },
                        MissingBreak: {
                          Confidence: 0,
                        },
                        BreakLength: 1300000,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "klaʊdz",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 124700000,
                    Duration: 4600000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "k",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "k",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɡ",
                          Score: 8,
                        },
                        {
                          Phoneme: "t",
                          Score: 6,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 4,
                        },
                        {
                          Phoneme: "l",
                          Score: 3,
                        },
                      ],
                    },
                    Offset: 124700000,
                    Duration: 1200000,
                  },
                  {
                    Phoneme: "l",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "l",
                          Score: 100,
                        },
                        {
                          Phoneme: "k",
                          Score: 3,
                        },
                        {
                          Phoneme: "r",
                          Score: 1,
                        },
                        {
                          Phoneme: "ə",
                          Score: 1,
                        },
                        {
                          Phoneme: "w",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 126000000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "aʊ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "aʊ",
                          Score: 100,
                        },
                        {
                          Phoneme: "l",
                          Score: 33,
                        },
                        {
                          Phoneme: "ɔ",
                          Score: 11,
                        },
                        {
                          Phoneme: "ɑ",
                          Score: 5,
                        },
                        {
                          Phoneme: "d",
                          Score: 3,
                        },
                      ],
                    },
                    Offset: 126800000,
                    Duration: 1100000,
                  },
                  {
                    Phoneme: "d",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "d",
                          Score: 100,
                        },
                        {
                          Phoneme: "aʊ",
                          Score: 4,
                        },
                        {
                          Phoneme: "z",
                          Score: 1,
                        },
                        {
                          Phoneme: "n",
                          Score: 0,
                        },
                        {
                          Phoneme: "l",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 128000000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "z",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "z",
                          Score: 100,
                        },
                        {
                          Phoneme: "d",
                          Score: 27,
                        },
                        {
                          Phoneme: "k",
                          Score: 27,
                        },
                        {
                          Phoneme: "s",
                          Score: 14,
                        },
                        {
                          Phoneme: "t",
                          Score: 2,
                        },
                      ],
                    },
                    Offset: 128800000,
                    Duration: 500000,
                  },
                ],
              },
              {
                Word: "came",
                Offset: 129400000,
                Duration: 3300000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "keɪm",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 129400000,
                    Duration: 3300000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "k",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "k",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 8,
                        },
                        {
                          Phoneme: "d",
                          Score: 3,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 1,
                        },
                        {
                          Phoneme: "z",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 129400000,
                    Duration: 1100000,
                  },
                  {
                    Phoneme: "eɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "eɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "k",
                          Score: 14,
                        },
                        {
                          Phoneme: "m",
                          Score: 8,
                        },
                        {
                          Phoneme: "i",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 130600000,
                    Duration: 1100000,
                  },
                  {
                    Phoneme: "m",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "m",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 31,
                        },
                        {
                          Phoneme: "i",
                          Score: 1,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 1,
                        },
                        {
                          Phoneme: "n",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 131800000,
                    Duration: 900000,
                  },
                ],
              },
              {
                Word: "in",
                Offset: 132800000,
                Duration: 3000000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "ɪn",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 132800000,
                    Duration: 3000000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "ɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "n",
                          Score: 9,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 1,
                        },
                        {
                          Phoneme: "æ",
                          Score: 1,
                        },
                        {
                          Phoneme: "i",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 132800000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "n",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "n",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 5,
                        },
                        {
                          Phoneme: "r",
                          Score: 4,
                        },
                        {
                          Phoneme: "m",
                          Score: 4,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 3,
                        },
                      ],
                    },
                    Offset: 133800000,
                    Duration: 2000000,
                  },
                ],
                Punctuation: ",",
              },
              {
                Word: "forecasting",
                Offset: 137200000,
                Duration: 8000000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 0.50795954,
                        },
                        MissingBreak: {
                          Confidence: 0,
                        },
                        BreakLength: 1300000,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "fɔr",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 137200000,
                    Duration: 3100000,
                  },
                  {
                    Syllable: "kæ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 140400000,
                    Duration: 2100000,
                  },
                  {
                    Syllable: "stɪŋ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 142600000,
                    Duration: 2600000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "f",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "f",
                          Score: 100,
                        },
                        {
                          Phoneme: "v",
                          Score: 9,
                        },
                        {
                          Phoneme: "h",
                          Score: 4,
                        },
                        {
                          Phoneme: "p",
                          Score: 3,
                        },
                        {
                          Phoneme: "ə",
                          Score: 3,
                        },
                      ],
                    },
                    Offset: 137200000,
                    Duration: 1700000,
                  },
                  {
                    Phoneme: "ɔ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɔ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ʊ",
                          Score: 95,
                        },
                        {
                          Phoneme: "f",
                          Score: 59,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 15,
                        },
                        {
                          Phoneme: "ə",
                          Score: 5,
                        },
                      ],
                    },
                    Offset: 139000000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "r",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "r",
                          Score: 100,
                        },
                        {
                          Phoneme: "ʊ",
                          Score: 40,
                        },
                        {
                          Phoneme: "k",
                          Score: 38,
                        },
                        {
                          Phoneme: "l",
                          Score: 32,
                        },
                        {
                          Phoneme: "t",
                          Score: 3,
                        },
                      ],
                    },
                    Offset: 139600000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "k",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "k",
                          Score: 100,
                        },
                        {
                          Phoneme: "d",
                          Score: 11,
                        },
                        {
                          Phoneme: "t",
                          Score: 6,
                        },
                        {
                          Phoneme: "ɡ",
                          Score: 2,
                        },
                        {
                          Phoneme: "r",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 140400000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "æ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "æ",
                          Score: 100,
                        },
                        {
                          Phoneme: "s",
                          Score: 38,
                        },
                        {
                          Phoneme: "k",
                          Score: 13,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 7,
                        },
                        {
                          Phoneme: "h",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 141000000,
                    Duration: 1500000,
                  },
                  {
                    Phoneme: "s",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "s",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 51,
                        },
                        {
                          Phoneme: "ə",
                          Score: 1,
                        },
                        {
                          Phoneme: "z",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 142600000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "t",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 100,
                        },
                        {
                          Phoneme: "tʃ",
                          Score: 1,
                        },
                        {
                          Phoneme: "s",
                          Score: 0,
                        },
                        {
                          Phoneme: "d",
                          Score: 0,
                        },
                        {
                          Phoneme: "k",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 143400000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "ɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 74,
                        },
                        {
                          Phoneme: "h",
                          Score: 0,
                        },
                        {
                          Phoneme: "k",
                          Score: 0,
                        },
                        {
                          Phoneme: "d",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 144000000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "ŋ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ŋ",
                          Score: 100,
                        },
                        {
                          Phoneme: "m",
                          Score: 60,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 10,
                        },
                        {
                          Phoneme: "n",
                          Score: 0,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 144600000,
                    Duration: 600000,
                  },
                ],
              },
              {
                Word: "much",
                Offset: 145300000,
                Duration: 2600000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "mʌtʃ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 145300000,
                    Duration: 2600000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "m",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "m",
                          Score: 100,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 36,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 15,
                        },
                        {
                          Phoneme: "n",
                          Score: 0,
                        },
                        {
                          Phoneme: "w",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 145300000,
                    Duration: 600000,
                  },
                  {
                    Phoneme: "ʌ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ʌ",
                          Score: 100,
                        },
                        {
                          Phoneme: "tʃ",
                          Score: 9,
                        },
                        {
                          Phoneme: "ɑ",
                          Score: 1,
                        },
                        {
                          Phoneme: "m",
                          Score: 0,
                        },
                        {
                          Phoneme: "æ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 146000000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "tʃ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "tʃ",
                          Score: 100,
                        },
                        {
                          Phoneme: "n",
                          Score: 41,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 1,
                        },
                        {
                          Phoneme: "m",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 147000000,
                    Duration: 900000,
                  },
                ],
              },
              {
                Word: "needed",
                Offset: 148000000,
                Duration: 3700000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "ni",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 148000000,
                    Duration: 1700000,
                  },
                  {
                    Syllable: "dɪd",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 149800000,
                    Duration: 1900000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "n",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "n",
                          Score: 100,
                        },
                        {
                          Phoneme: "i",
                          Score: 29,
                        },
                        {
                          Phoneme: "tʃ",
                          Score: 6,
                        },
                        {
                          Phoneme: "m",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 148000000,
                    Duration: 1100000,
                  },
                  {
                    Phoneme: "i",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "i",
                          Score: 100,
                        },
                        {
                          Phoneme: "n",
                          Score: 13,
                        },
                        {
                          Phoneme: "d",
                          Score: 4,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 2,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 149200000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "d",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "d",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 40,
                        },
                        {
                          Phoneme: "t",
                          Score: 14,
                        },
                        {
                          Phoneme: "i",
                          Score: 6,
                        },
                        {
                          Phoneme: "ə",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 149800000,
                    Duration: 600000,
                  },
                  {
                    Phoneme: "ɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "d",
                          Score: 72,
                        },
                        {
                          Phoneme: "ə",
                          Score: 8,
                        },
                        {
                          Phoneme: "r",
                          Score: 3,
                        },
                        {
                          Phoneme: "t",
                          Score: 2,
                        },
                      ],
                    },
                    Offset: 150500000,
                    Duration: 400000,
                  },
                  {
                    Phoneme: "d",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "d",
                          Score: 100,
                        },
                        {
                          Phoneme: "r",
                          Score: 9,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 1,
                        },
                        {
                          Phoneme: "ə",
                          Score: 1,
                        },
                        {
                          Phoneme: "t",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 151000000,
                    Duration: 700000,
                  },
                ],
              },
              {
                Word: "rain",
                Offset: 151800000,
                Duration: 4700000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.9073807e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.5555011,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "reɪn",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 151800000,
                    Duration: 4700000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "r",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "r",
                          Score: 100,
                        },
                        {
                          Phoneme: "d",
                          Score: 32,
                        },
                        {
                          Phoneme: "w",
                          Score: 2,
                        },
                        {
                          Phoneme: "l",
                          Score: 1,
                        },
                        {
                          Phoneme: "v",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 151800000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "eɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "eɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "r",
                          Score: 50,
                        },
                        {
                          Phoneme: "æ",
                          Score: 45,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 15,
                        },
                        {
                          Phoneme: "n",
                          Score: 12,
                        },
                      ],
                    },
                    Offset: 152600000,
                    Duration: 1300000,
                  },
                  {
                    Phoneme: "n",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "n",
                          Score: 100,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 55,
                        },
                        {
                          Phoneme: "m",
                          Score: 49,
                        },
                        {
                          Phoneme: "d",
                          Score: 20,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 14,
                        },
                      ],
                    },
                    Offset: 154000000,
                    Duration: 2500000,
                  },
                ],
                Punctuation: ".",
              },
            ],
          },
        ],
      },
    ],
    scriptText:
      "Today was a beautiful day. We had a great time taking a long walk outside in the morning. The countryside was in full bloom, yet the air was crisp and cold. Towards the end of the day, clouds came in, forecasting much needed rain.",
  },
  {
    name: "sample-2",
    data: [
      {
        Id: "9024344eb7c2481eaf53e8257a857790",
        RecognitionStatus: 0,
        Offset: 900000,
        Duration: 25900000,
        Channel: 0,
        DisplayText: "It took me a long time to learn where he came from.",
        SNR: 39.079533,
        NBest: [
          {
            Confidence: 0.98311317,
            Lexical: "it took me a long time to learn where he came from",
            ITN: "it took me a long time to learn where he came from",
            MaskedITN: "it took me a long time to learn where he came from",
            Display: "It took me a long time to learn where he came from.",
            PronunciationAssessment: {
              AccuracyScore: 98,
              FluencyScore: 100,
              ProsodyScore: 91.5,
              CompletenessScore: 100,
              PronScore: 96.2,
            },
            Words: [
              {
                Word: "it",
                Offset: 900000,
                Duration: 2100000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.37465814,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "ɪt",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 900000,
                    Duration: 2100000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "ɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 1,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 1,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 1,
                        },
                        {
                          Phoneme: "i",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 900000,
                    Duration: 1500000,
                  },
                  {
                    Phoneme: "t",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 66,
                        },
                        {
                          Phoneme: "i",
                          Score: 0,
                        },
                        {
                          Phoneme: "ʊ",
                          Score: 0,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 2500000,
                    Duration: 500000,
                  },
                ],
              },
              {
                Word: "took",
                Offset: 3100000,
                Duration: 2100000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.6153847e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.37465814,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "tʊk",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 3100000,
                    Duration: 2100000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "t",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 100,
                        },
                        {
                          Phoneme: "ʊ",
                          Score: 86,
                        },
                        {
                          Phoneme: "k",
                          Score: 2,
                        },
                        {
                          Phoneme: "d",
                          Score: 0,
                        },
                        {
                          Phoneme: "h",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 3100000,
                    Duration: 400000,
                  },
                  {
                    Phoneme: "ʊ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ʊ",
                          Score: 100,
                        },
                        {
                          Phoneme: "k",
                          Score: 26,
                        },
                        {
                          Phoneme: "t",
                          Score: 8,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 0,
                        },
                        {
                          Phoneme: "h",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 3600000,
                    Duration: 800000,
                  },
                  {
                    Phoneme: "k",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "k",
                          Score: 100,
                        },
                        {
                          Phoneme: "m",
                          Score: 11,
                        },
                        {
                          Phoneme: "t",
                          Score: 0,
                        },
                        {
                          Phoneme: "w",
                          Score: 0,
                        },
                        {
                          Phoneme: "p",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 4500000,
                    Duration: 700000,
                  },
                ],
              },
              {
                Word: "me",
                Offset: 5300000,
                Duration: 1000000,
                PronunciationAssessment: {
                  AccuracyScore: 73,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.6153847e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.37465814,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "mi",
                    PronunciationAssessment: {
                      AccuracyScore: 73,
                    },
                    Offset: 5300000,
                    Duration: 1000000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "m",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "m",
                          Score: 100,
                        },
                        {
                          Phoneme: "i",
                          Score: 1,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 0,
                        },
                        {
                          Phoneme: "k",
                          Score: 0,
                        },
                        {
                          Phoneme: "n",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 5300000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "i",
                    PronunciationAssessment: {
                      AccuracyScore: 40,
                      NBestPhonemes: [
                        {
                          Phoneme: "i",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 66,
                        },
                        {
                          Phoneme: "j",
                          Score: 2,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 5900000,
                    Duration: 400000,
                  },
                ],
              },
              {
                Word: "a",
                Offset: 6400000,
                Duration: 1000000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.6153847e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.37465814,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 6400000,
                    Duration: 1000000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ə",
                          Score: 100,
                        },
                        {
                          Phoneme: "i",
                          Score: 33,
                        },
                        {
                          Phoneme: "l",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 6400000,
                    Duration: 1000000,
                  },
                ],
              },
              {
                Word: "long",
                Offset: 7500000,
                Duration: 2900000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.6153847e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.37465814,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "lɔŋ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 7500000,
                    Duration: 2900000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "l",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "l",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɑ",
                          Score: 48,
                        },
                        {
                          Phoneme: "ə",
                          Score: 25,
                        },
                        {
                          Phoneme: "ɔ",
                          Score: 11,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 7500000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "ɔ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɔ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɑ",
                          Score: 97,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 0,
                        },
                        {
                          Phoneme: "l",
                          Score: 0,
                        },
                        {
                          Phoneme: "æ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 8500000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "ŋ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ŋ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɑ",
                          Score: 63,
                        },
                        {
                          Phoneme: "ɔ",
                          Score: 34,
                        },
                        {
                          Phoneme: "t",
                          Score: 10,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 9300000,
                    Duration: 1100000,
                  },
                ],
              },
              {
                Word: "time",
                Offset: 10500000,
                Duration: 2300000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.6153847e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.37465814,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "taɪm",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 10500000,
                    Duration: 2300000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "t",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 100,
                        },
                        {
                          Phoneme: "aɪ",
                          Score: 20,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 1,
                        },
                        {
                          Phoneme: "d",
                          Score: 0,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 10500000,
                    Duration: 1100000,
                  },
                  {
                    Phoneme: "aɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "aɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "m",
                          Score: 36,
                        },
                        {
                          Phoneme: "t",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                        {
                          Phoneme: "æ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 11700000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "m",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "m",
                          Score: 100,
                        },
                        {
                          Phoneme: "n",
                          Score: 0,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                        {
                          Phoneme: "t",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 12300000,
                    Duration: 500000,
                  },
                ],
              },
              {
                Word: "to",
                Offset: 12900000,
                Duration: 1300000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.6153847e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.37465814,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "tə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 12900000,
                    Duration: 1300000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "t",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 100,
                        },
                        {
                          Phoneme: "m",
                          Score: 41,
                        },
                        {
                          Phoneme: "d",
                          Score: 3,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 0,
                        },
                        {
                          Phoneme: "n",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 12900000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ə",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 20,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 1,
                        },
                        {
                          Phoneme: "l",
                          Score: 1,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 13500000,
                    Duration: 700000,
                  },
                ],
              },
              {
                Word: "learn",
                Offset: 14300000,
                Duration: 2700000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.6153847e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.37465814,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "lɝrn",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 14300000,
                    Duration: 2700000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "l",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "l",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɝ",
                          Score: 5,
                        },
                        {
                          Phoneme: "ə",
                          Score: 1,
                        },
                        {
                          Phoneme: "t",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 14300000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "ɝ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɝ",
                          Score: 100,
                        },
                        {
                          Phoneme: "r",
                          Score: 6,
                        },
                        {
                          Phoneme: "l",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɑ",
                          Score: 0,
                        },
                        {
                          Phoneme: "n",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 14900000,
                    Duration: 1000000,
                  },
                  {
                    Phoneme: "r",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "r",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɝ",
                          Score: 86,
                        },
                        {
                          Phoneme: "n",
                          Score: 47,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 16000000,
                    Duration: 400000,
                  },
                  {
                    Phoneme: "n",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "n",
                          Score: 100,
                        },
                        {
                          Phoneme: "r",
                          Score: 5,
                        },
                        {
                          Phoneme: "d",
                          Score: 3,
                        },
                        {
                          Phoneme: "t",
                          Score: 2,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 16500000,
                    Duration: 500000,
                  },
                ],
              },
              {
                Word: "where",
                Offset: 17100000,
                Duration: 1300000,
                PronunciationAssessment: {
                  AccuracyScore: 99,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.6153847e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.37465814,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "wɛr",
                    PronunciationAssessment: {
                      AccuracyScore: 99,
                    },
                    Offset: 17100000,
                    Duration: 1300000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "w",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "w",
                          Score: 100,
                        },
                        {
                          Phoneme: "n",
                          Score: 60,
                        },
                        {
                          Phoneme: "d",
                          Score: 3,
                        },
                        {
                          Phoneme: "t",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 17100000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "ɛ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɛ",
                          Score: 100,
                        },
                        {
                          Phoneme: "w",
                          Score: 2,
                        },
                        {
                          Phoneme: "ə",
                          Score: 1,
                        },
                        {
                          Phoneme: "r",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɝ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 17700000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "r",
                    PronunciationAssessment: {
                      AccuracyScore: 98,
                      NBestPhonemes: [
                        {
                          Phoneme: "r",
                          Score: 100,
                        },
                        {
                          Phoneme: "h",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 0,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 18100000,
                    Duration: 300000,
                  },
                ],
              },
              {
                Word: "he",
                Offset: 18500000,
                Duration: 1300000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.6153847e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.37465814,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "hi",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 18500000,
                    Duration: 1300000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "h",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "h",
                          Score: 100,
                        },
                        {
                          Phoneme: "i",
                          Score: 47,
                        },
                        {
                          Phoneme: "r",
                          Score: 2,
                        },
                        {
                          Phoneme: "t",
                          Score: 0,
                        },
                        {
                          Phoneme: "ʃ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 18500000,
                    Duration: 600000,
                  },
                  {
                    Phoneme: "i",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "i",
                          Score: 100,
                        },
                        {
                          Phoneme: "h",
                          Score: 51,
                        },
                        {
                          Phoneme: "k",
                          Score: 14,
                        },
                        {
                          Phoneme: "ʊ",
                          Score: 0,
                        },
                        {
                          Phoneme: "t",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 19200000,
                    Duration: 600000,
                  },
                ],
              },
              {
                Word: "came",
                Offset: 19900000,
                Duration: 2700000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.6153847e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.37465814,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "keɪm",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 19900000,
                    Duration: 2700000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "k",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "k",
                          Score: 100,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 1,
                        },
                        {
                          Phoneme: "t",
                          Score: 0,
                        },
                        {
                          Phoneme: "i",
                          Score: 0,
                        },
                        {
                          Phoneme: "d",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 19900000,
                    Duration: 1100000,
                  },
                  {
                    Phoneme: "eɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "eɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "m",
                          Score: 0,
                        },
                        {
                          Phoneme: "k",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 0,
                        },
                        {
                          Phoneme: "i",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 21100000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "m",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "m",
                          Score: 100,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 6,
                        },
                        {
                          Phoneme: "n",
                          Score: 0,
                        },
                        {
                          Phoneme: "f",
                          Score: 0,
                        },
                        {
                          Phoneme: "p",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 21700000,
                    Duration: 900000,
                  },
                ],
              },
              {
                Word: "from",
                Offset: 22700000,
                Duration: 4100000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.6153847e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.37465814,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "frʌm",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 22700000,
                    Duration: 4100000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "f",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "f",
                          Score: 100,
                        },
                        {
                          Phoneme: "m",
                          Score: 26,
                        },
                        {
                          Phoneme: "r",
                          Score: 9,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 1,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 22700000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "r",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "r",
                          Score: 100,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 9,
                        },
                        {
                          Phoneme: "ɑ",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɝ",
                          Score: 0,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 23500000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "ʌ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ʌ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɑ",
                          Score: 44,
                        },
                        {
                          Phoneme: "m",
                          Score: 22,
                        },
                        {
                          Phoneme: "ə",
                          Score: 1,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 24300000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "m",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "m",
                          Score: 100,
                        },
                        {
                          Phoneme: "n",
                          Score: 2,
                        },
                        {
                          Phoneme: "p",
                          Score: 1,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 1,
                        },
                        {
                          Phoneme: "b",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 25300000,
                    Duration: 1500000,
                  },
                ],
                Punctuation: ".",
              },
            ],
          },
        ],
      },
      {
        Id: "0a6faffce0244bec9269dfd8047e343f",
        RecognitionStatus: 0,
        Offset: 34800000,
        Duration: 49500000,
        Channel: 0,
        DisplayText:
          "The little Prince who asked me so many questions never seemed to hear the ones I asked him.",
        SNR: 41.08713,
        NBest: [
          {
            Confidence: 0.98410404,
            Lexical:
              "the little prince who asked me so many questions never seemed to hear the ones i asked him",
            ITN: "the little prince who asked me so many questions never seemed to hear the ones i asked him",
            MaskedITN:
              "the little prince who asked me so many questions never seemed to hear the ones i asked him",
            Display:
              "The little Prince who asked me so many questions never seemed to hear the ones I asked him.",
            PronunciationAssessment: {
              AccuracyScore: 98,
              FluencyScore: 98,
              ProsodyScore: 90.9,
              CompletenessScore: 100,
              PronScore: 95.6,
            },
            Words: [
              {
                Word: "the",
                Offset: 34800000,
                Duration: 1700000,
                PronunciationAssessment: {
                  AccuracyScore: 88,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.5761316,
                        },
                        MissingBreak: {
                          Confidence: 0,
                        },
                        BreakLength: 7900000,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.4589193,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "ðə",
                    PronunciationAssessment: {
                      AccuracyScore: 88,
                    },
                    Offset: 34800000,
                    Duration: 1700000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "ð",
                    PronunciationAssessment: {
                      AccuracyScore: 80,
                      NBestPhonemes: [
                        {
                          Phoneme: "ð",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 14,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 4,
                        },
                        {
                          Phoneme: "d",
                          Score: 3,
                        },
                        {
                          Phoneme: "b",
                          Score: 3,
                        },
                      ],
                    },
                    Offset: 34800000,
                    Duration: 1000000,
                  },
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ə",
                          Score: 100,
                        },
                        {
                          Phoneme: "ð",
                          Score: 70,
                        },
                        {
                          Phoneme: "l",
                          Score: 22,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 2,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 35900000,
                    Duration: 600000,
                  },
                ],
              },
              {
                Word: "little",
                Offset: 36600000,
                Duration: 2200000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.5267488e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.4589193,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "lɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 36600000,
                    Duration: 900000,
                  },
                  {
                    Syllable: "təl",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 37600000,
                    Duration: 1200000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "l",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "l",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 47,
                        },
                        {
                          Phoneme: "ə",
                          Score: 1,
                        },
                        {
                          Phoneme: "t",
                          Score: 1,
                        },
                        {
                          Phoneme: "d",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 36600000,
                    Duration: 400000,
                  },
                  {
                    Phoneme: "ɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "l",
                          Score: 74,
                        },
                        {
                          Phoneme: "t",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 0,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 37100000,
                    Duration: 400000,
                  },
                  {
                    Phoneme: "t",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 8,
                        },
                        {
                          Phoneme: "d",
                          Score: 2,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                        {
                          Phoneme: "n",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 37600000,
                    Duration: 200000,
                  },
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ə",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 93,
                        },
                        {
                          Phoneme: "l",
                          Score: 90,
                        },
                        {
                          Phoneme: "d",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 37900000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "l",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "l",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 25,
                        },
                        {
                          Phoneme: "p",
                          Score: 7,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 38300000,
                    Duration: 500000,
                  },
                ],
              },
              {
                Word: "prince",
                Offset: 38900000,
                Duration: 5000000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.5267488e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.4589193,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "prɪns",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 38900000,
                    Duration: 5000000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "p",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "p",
                          Score: 100,
                        },
                        {
                          Phoneme: "l",
                          Score: 74,
                        },
                        {
                          Phoneme: "r",
                          Score: 1,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                        {
                          Phoneme: "b",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 38900000,
                    Duration: 600000,
                  },
                  {
                    Phoneme: "r",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "r",
                          Score: 100,
                        },
                        {
                          Phoneme: "p",
                          Score: 4,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 0,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 39600000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "ɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "r",
                          Score: 39,
                        },
                        {
                          Phoneme: "n",
                          Score: 18,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 16,
                        },
                        {
                          Phoneme: "æ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 40600000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "n",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "n",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 67,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 26,
                        },
                        {
                          Phoneme: "s",
                          Score: 3,
                        },
                        {
                          Phoneme: "d",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 41400000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "s",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "s",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 59,
                        },
                        {
                          Phoneme: "z",
                          Score: 20,
                        },
                        {
                          Phoneme: "n",
                          Score: 14,
                        },
                        {
                          Phoneme: "tʃ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 42200000,
                    Duration: 1700000,
                  },
                ],
                Punctuation: ",",
              },
              {
                Word: "who",
                Offset: 44200000,
                Duration: 1900000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["MissingBreak"],
                        UnexpectedBreak: {
                          Confidence: 0.09053502,
                        },
                        MissingBreak: {
                          Confidence: 0.9547325,
                        },
                        BreakLength: 200000,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.4589193,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "hu",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 44200000,
                    Duration: 1900000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "h",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "h",
                          Score: 100,
                        },
                        {
                          Phoneme: "u",
                          Score: 1,
                        },
                        {
                          Phoneme: "f",
                          Score: 0,
                        },
                        {
                          Phoneme: "k",
                          Score: 0,
                        },
                        {
                          Phoneme: "w",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 44200000,
                    Duration: 1100000,
                  },
                  {
                    Phoneme: "u",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "u",
                          Score: 100,
                        },
                        {
                          Phoneme: "æ",
                          Score: 43,
                        },
                        {
                          Phoneme: "h",
                          Score: 33,
                        },
                        {
                          Phoneme: "r",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 45400000,
                    Duration: 700000,
                  },
                ],
              },
              {
                Word: "asked",
                Offset: 46200000,
                Duration: 2900000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.5267488e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.4589193,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "æskt",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 46200000,
                    Duration: 2900000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "æ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "æ",
                          Score: 100,
                        },
                        {
                          Phoneme: "u",
                          Score: 1,
                        },
                        {
                          Phoneme: "s",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 0,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 46200000,
                    Duration: 1300000,
                  },
                  {
                    Phoneme: "s",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "s",
                          Score: 100,
                        },
                        {
                          Phoneme: "k",
                          Score: 53,
                        },
                        {
                          Phoneme: "t",
                          Score: 1,
                        },
                        {
                          Phoneme: "æ",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 47600000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "k",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "k",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 87,
                        },
                        {
                          Phoneme: "s",
                          Score: 0,
                        },
                        {
                          Phoneme: "p",
                          Score: 0,
                        },
                        {
                          Phoneme: "d",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 48400000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "t",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 100,
                        },
                        {
                          Phoneme: "k",
                          Score: 0,
                        },
                        {
                          Phoneme: "m",
                          Score: 0,
                        },
                        {
                          Phoneme: "d",
                          Score: 0,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 48800000,
                    Duration: 300000,
                  },
                ],
              },
              {
                Word: "me",
                Offset: 49200000,
                Duration: 1100000,
                PronunciationAssessment: {
                  AccuracyScore: 86,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.5267488e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.4589193,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "mi",
                    PronunciationAssessment: {
                      AccuracyScore: 86,
                    },
                    Offset: 49200000,
                    Duration: 1100000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "m",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "m",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 4,
                        },
                        {
                          Phoneme: "i",
                          Score: 1,
                        },
                        {
                          Phoneme: "n",
                          Score: 0,
                        },
                        {
                          Phoneme: "l",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 49200000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "i",
                    PronunciationAssessment: {
                      AccuracyScore: 72,
                      NBestPhonemes: [
                        {
                          Phoneme: "i",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 0,
                        },
                        {
                          Phoneme: "s",
                          Score: 0,
                        },
                        {
                          Phoneme: "ʊ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 49800000,
                    Duration: 500000,
                  },
                ],
              },
              {
                Word: "so",
                Offset: 50400000,
                Duration: 2500000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.5267488e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.4589193,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "soʊ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 50400000,
                    Duration: 2500000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "s",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "s",
                          Score: 100,
                        },
                        {
                          Phoneme: "i",
                          Score: 13,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 4,
                        },
                        {
                          Phoneme: "z",
                          Score: 1,
                        },
                        {
                          Phoneme: "ʊ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 50400000,
                    Duration: 1700000,
                  },
                  {
                    Phoneme: "oʊ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "oʊ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 0,
                        },
                        {
                          Phoneme: "s",
                          Score: 0,
                        },
                        {
                          Phoneme: "m",
                          Score: 0,
                        },
                        {
                          Phoneme: "aʊ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 52200000,
                    Duration: 700000,
                  },
                ],
              },
              {
                Word: "many",
                Offset: 53000000,
                Duration: 2100000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.5267488e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.4589193,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "mɛ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 53000000,
                    Duration: 900000,
                  },
                  {
                    Syllable: "ni",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 54000000,
                    Duration: 1100000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "m",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "m",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 1,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 1,
                        },
                        {
                          Phoneme: "b",
                          Score: 0,
                        },
                        {
                          Phoneme: "n",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 53000000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "ɛ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɛ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                        {
                          Phoneme: "æ",
                          Score: 0,
                        },
                        {
                          Phoneme: "n",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 53600000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "n",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "n",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 5,
                        },
                        {
                          Phoneme: "i",
                          Score: 0,
                        },
                        {
                          Phoneme: "h",
                          Score: 0,
                        },
                        {
                          Phoneme: "m",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 54000000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "i",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "i",
                          Score: 100,
                        },
                        {
                          Phoneme: "k",
                          Score: 16,
                        },
                        {
                          Phoneme: "n",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɡ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 54600000,
                    Duration: 500000,
                  },
                ],
              },
              {
                Word: "questions",
                Offset: 55200000,
                Duration: 8200000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.5267488e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.4589193,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "kwɛʃ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 55200000,
                    Duration: 3100000,
                  },
                  {
                    Syllable: "tʃənz",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 58400000,
                    Duration: 5000000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "k",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "k",
                          Score: 100,
                        },
                        {
                          Phoneme: "w",
                          Score: 0,
                        },
                        {
                          Phoneme: "i",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɡ",
                          Score: 0,
                        },
                        {
                          Phoneme: "t",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 55200000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "w",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "w",
                          Score: 100,
                        },
                        {
                          Phoneme: "k",
                          Score: 12,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 3,
                        },
                        {
                          Phoneme: "æ",
                          Score: 0,
                        },
                        {
                          Phoneme: "r",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 56200000,
                    Duration: 600000,
                  },
                  {
                    Phoneme: "ɛ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɛ",
                          Score: 100,
                        },
                        {
                          Phoneme: "w",
                          Score: 93,
                        },
                        {
                          Phoneme: "ʃ",
                          Score: 83,
                        },
                        {
                          Phoneme: "æ",
                          Score: 0,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 56900000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "ʃ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ʃ",
                          Score: 100,
                        },
                        {
                          Phoneme: "tʃ",
                          Score: 45,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 23,
                        },
                        {
                          Phoneme: "s",
                          Score: 1,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 57300000,
                    Duration: 1000000,
                  },
                  {
                    Phoneme: "tʃ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "tʃ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 43,
                        },
                        {
                          Phoneme: "ʃ",
                          Score: 19,
                        },
                        {
                          Phoneme: "t",
                          Score: 0,
                        },
                        {
                          Phoneme: "s",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 58400000,
                    Duration: 800000,
                  },
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ə",
                          Score: 100,
                        },
                        {
                          Phoneme: "tʃ",
                          Score: 49,
                        },
                        {
                          Phoneme: "n",
                          Score: 11,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 59300000,
                    Duration: 600000,
                  },
                  {
                    Phoneme: "n",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "n",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 44,
                        },
                        {
                          Phoneme: "z",
                          Score: 8,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 2,
                        },
                        {
                          Phoneme: "d",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 60000000,
                    Duration: 1300000,
                  },
                  {
                    Phoneme: "z",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "z",
                          Score: 100,
                        },
                        {
                          Phoneme: "s",
                          Score: 4,
                        },
                        {
                          Phoneme: "d",
                          Score: 2,
                        },
                        {
                          Phoneme: "n",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 61400000,
                    Duration: 2000000,
                  },
                ],
                Punctuation: ",",
              },
              {
                Word: "never",
                Offset: 64300000,
                Duration: 3800000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 0.36213994,
                        },
                        MissingBreak: {
                          Confidence: 0,
                        },
                        BreakLength: 800000,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.4589193,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "nɛ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 64300000,
                    Duration: 2100000,
                  },
                  {
                    Syllable: "vər",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 66500000,
                    Duration: 1600000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "n",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "n",
                          Score: 100,
                        },
                        {
                          Phoneme: "m",
                          Score: 1,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 0,
                        },
                        {
                          Phoneme: "z",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 64300000,
                    Duration: 1600000,
                  },
                  {
                    Phoneme: "ɛ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɛ",
                          Score: 100,
                        },
                        {
                          Phoneme: "n",
                          Score: 50,
                        },
                        {
                          Phoneme: "v",
                          Score: 32,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                        {
                          Phoneme: "æ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 66000000,
                    Duration: 400000,
                  },
                  {
                    Phoneme: "v",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "v",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 80,
                        },
                        {
                          Phoneme: "f",
                          Score: 0,
                        },
                        {
                          Phoneme: "b",
                          Score: 0,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 66500000,
                    Duration: 400000,
                  },
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ə",
                          Score: 100,
                        },
                        {
                          Phoneme: "v",
                          Score: 4,
                        },
                        {
                          Phoneme: "w",
                          Score: 0,
                        },
                        {
                          Phoneme: "h",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɝ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 67000000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "r",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "r",
                          Score: 100,
                        },
                        {
                          Phoneme: "s",
                          Score: 66,
                        },
                        {
                          Phoneme: "ə",
                          Score: 50,
                        },
                        {
                          Phoneme: "ɝ",
                          Score: 0,
                        },
                        {
                          Phoneme: "z",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 67400000,
                    Duration: 700000,
                  },
                ],
              },
              {
                Word: "seemed",
                Offset: 68200000,
                Duration: 2100000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.5267488e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.4589193,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "simd",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 68200000,
                    Duration: 2100000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "s",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "s",
                          Score: 100,
                        },
                        {
                          Phoneme: "i",
                          Score: 0,
                        },
                        {
                          Phoneme: "t",
                          Score: 0,
                        },
                        {
                          Phoneme: "z",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 68200000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "i",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "i",
                          Score: 100,
                        },
                        {
                          Phoneme: "s",
                          Score: 48,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 5,
                        },
                        {
                          Phoneme: "m",
                          Score: 0,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 68800000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "m",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "m",
                          Score: 100,
                        },
                        {
                          Phoneme: "i",
                          Score: 6,
                        },
                        {
                          Phoneme: "n",
                          Score: 2,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 1,
                        },
                        {
                          Phoneme: "d",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 69600000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "d",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "d",
                          Score: 100,
                        },
                        {
                          Phoneme: "m",
                          Score: 25,
                        },
                        {
                          Phoneme: "t",
                          Score: 1,
                        },
                        {
                          Phoneme: "n",
                          Score: 0,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 70000000,
                    Duration: 300000,
                  },
                ],
              },
              {
                Word: "to",
                Offset: 70400000,
                Duration: 700000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.5267488e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.4589193,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "tə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 70400000,
                    Duration: 700000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "t",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 47,
                        },
                        {
                          Phoneme: "d",
                          Score: 1,
                        },
                        {
                          Phoneme: "ð",
                          Score: 0,
                        },
                        {
                          Phoneme: "h",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 70400000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ə",
                          Score: 100,
                        },
                        {
                          Phoneme: "h",
                          Score: 1,
                        },
                        {
                          Phoneme: "t",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                        {
                          Phoneme: "ʊ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 70800000,
                    Duration: 300000,
                  },
                ],
              },
              {
                Word: "hear",
                Offset: 71200000,
                Duration: 2100000,
                PronunciationAssessment: {
                  AccuracyScore: 98,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.5267488e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.4589193,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "hir",
                    PronunciationAssessment: {
                      AccuracyScore: 98,
                    },
                    Offset: 71200000,
                    Duration: 2100000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "h",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "h",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 5,
                        },
                        {
                          Phoneme: "i",
                          Score: 0,
                        },
                        {
                          Phoneme: "k",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɡ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 71200000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "i",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "i",
                          Score: 100,
                        },
                        {
                          Phoneme: "h",
                          Score: 25,
                        },
                        {
                          Phoneme: "r",
                          Score: 6,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 0,
                        },
                        {
                          Phoneme: "j",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 72000000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "r",
                    PronunciationAssessment: {
                      AccuracyScore: 94,
                      NBestPhonemes: [
                        {
                          Phoneme: "r",
                          Score: 100,
                        },
                        {
                          Phoneme: "d",
                          Score: 0,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                        {
                          Phoneme: "i",
                          Score: 0,
                        },
                        {
                          Phoneme: "u",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 72800000,
                    Duration: 500000,
                  },
                ],
              },
              {
                Word: "the",
                Offset: 73400000,
                Duration: 1100000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.5267488e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.4589193,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "ðʌ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 73400000,
                    Duration: 1100000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "ð",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ð",
                          Score: 100,
                        },
                        {
                          Phoneme: "r",
                          Score: 83,
                        },
                        {
                          Phoneme: "d",
                          Score: 0,
                        },
                        {
                          Phoneme: "v",
                          Score: 0,
                        },
                        {
                          Phoneme: "θ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 73400000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "ʌ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ʌ",
                          Score: 100,
                        },
                        {
                          Phoneme: "w",
                          Score: 3,
                        },
                        {
                          Phoneme: "ə",
                          Score: 1,
                        },
                        {
                          Phoneme: "ð",
                          Score: 1,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 73800000,
                    Duration: 700000,
                  },
                ],
              },
              {
                Word: "ones",
                Offset: 74600000,
                Duration: 2500000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.5267488e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.4589193,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "wʌnz",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 74600000,
                    Duration: 2500000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "w",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "w",
                          Score: 100,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 6,
                        },
                        {
                          Phoneme: "l",
                          Score: 0,
                        },
                        {
                          Phoneme: "v",
                          Score: 0,
                        },
                        {
                          Phoneme: "b",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 74600000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "ʌ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ʌ",
                          Score: 100,
                        },
                        {
                          Phoneme: "n",
                          Score: 9,
                        },
                        {
                          Phoneme: "u",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 1,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 75000000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "n",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "n",
                          Score: 100,
                        },
                        {
                          Phoneme: "z",
                          Score: 31,
                        },
                        {
                          Phoneme: "d",
                          Score: 1,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 1,
                        },
                        {
                          Phoneme: "t",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 75800000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "z",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "z",
                          Score: 100,
                        },
                        {
                          Phoneme: "s",
                          Score: 9,
                        },
                        {
                          Phoneme: "aɪ",
                          Score: 9,
                        },
                        {
                          Phoneme: "ð",
                          Score: 7,
                        },
                        {
                          Phoneme: "n",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 76400000,
                    Duration: 700000,
                  },
                ],
              },
              {
                Word: "i",
                Offset: 77200000,
                Duration: 500000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.5267488e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.4589193,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "aɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 77200000,
                    Duration: 500000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "aɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "aɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "æ",
                          Score: 9,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 3,
                        },
                        {
                          Phoneme: "z",
                          Score: 2,
                        },
                        {
                          Phoneme: "i",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 77200000,
                    Duration: 500000,
                  },
                ],
              },
              {
                Word: "asked",
                Offset: 77800000,
                Duration: 3300000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.5267488e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.4589193,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "æskt",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 77800000,
                    Duration: 3300000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "æ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "æ",
                          Score: 100,
                        },
                        {
                          Phoneme: "aɪ",
                          Score: 7,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 1,
                        },
                        {
                          Phoneme: "i",
                          Score: 0,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 77800000,
                    Duration: 1300000,
                  },
                  {
                    Phoneme: "s",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "s",
                          Score: 100,
                        },
                        {
                          Phoneme: "æ",
                          Score: 59,
                        },
                        {
                          Phoneme: "k",
                          Score: 34,
                        },
                        {
                          Phoneme: "t",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɑ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 79200000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "k",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "k",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 90,
                        },
                        {
                          Phoneme: "s",
                          Score: 2,
                        },
                        {
                          Phoneme: "p",
                          Score: 0,
                        },
                        {
                          Phoneme: "d",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 80200000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "t",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 100,
                        },
                        {
                          Phoneme: "k",
                          Score: 1,
                        },
                        {
                          Phoneme: "h",
                          Score: 0,
                        },
                        {
                          Phoneme: "d",
                          Score: 0,
                        },
                        {
                          Phoneme: "p",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 80600000,
                    Duration: 500000,
                  },
                ],
              },
              {
                Word: "him",
                Offset: 81200000,
                Duration: 3100000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.5267488e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.4589193,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "hɪm",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 81200000,
                    Duration: 3100000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "h",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "h",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 42,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 1,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 81200000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "ɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "m",
                          Score: 59,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 1,
                        },
                        {
                          Phoneme: "h",
                          Score: 1,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 81600000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "m",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "m",
                          Score: 100,
                        },
                        {
                          Phoneme: "n",
                          Score: 1,
                        },
                        {
                          Phoneme: "p",
                          Score: 1,
                        },
                        {
                          Phoneme: "v",
                          Score: 1,
                        },
                        {
                          Phoneme: "aʊ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 82400000,
                    Duration: 1900000,
                  },
                ],
                Punctuation: ".",
              },
            ],
          },
        ],
      },
      {
        Id: "8a23053c3165463bafc055ca2ad1b564",
        RecognitionStatus: 0,
        Offset: 92000000,
        Duration: 47500000,
        Channel: 0,
        DisplayText:
          "It was from words dropped by chance that little by little, everything was revealed to me.",
        SNR: 40.36674,
        NBest: [
          {
            Confidence: 0.9871295,
            Lexical:
              "it was from words dropped by chance that little by little everything was revealed to me",
            ITN: "it was from words dropped by chance that little by little everything was revealed to me",
            MaskedITN:
              "it was from words dropped by chance that little by little everything was revealed to me",
            Display:
              "It was from words dropped by chance that little by little, everything was revealed to me.",
            PronunciationAssessment: {
              AccuracyScore: 100,
              FluencyScore: 99,
              ProsodyScore: 90.7,
              CompletenessScore: 100,
              PronScore: 96.1,
            },
            Words: [
              {
                Word: "it",
                Offset: 92000000,
                Duration: 2000000,
                PronunciationAssessment: {
                  AccuracyScore: 97,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.381356,
                        },
                        MissingBreak: {
                          Confidence: 0,
                        },
                        BreakLength: 7600000,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.46888748,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "ɪt",
                    PronunciationAssessment: {
                      AccuracyScore: 97,
                    },
                    Offset: 92000000,
                    Duration: 2000000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "ɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 33,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 1,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 1,
                        },
                        {
                          Phoneme: "i",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 92000000,
                    Duration: 1400000,
                  },
                  {
                    Phoneme: "t",
                    PronunciationAssessment: {
                      AccuracyScore: 91,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 47,
                        },
                        {
                          Phoneme: "w",
                          Score: 16,
                        },
                        {
                          Phoneme: "d",
                          Score: 0,
                        },
                        {
                          Phoneme: "i",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 93500000,
                    Duration: 500000,
                  },
                ],
              },
              {
                Word: "was",
                Offset: 94100000,
                Duration: 1500000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.4491525e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.46888748,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "wʌz",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 94100000,
                    Duration: 1500000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "w",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "w",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 96,
                        },
                        {
                          Phoneme: "d",
                          Score: 0,
                        },
                        {
                          Phoneme: "b",
                          Score: 0,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 94100000,
                    Duration: 200000,
                  },
                  {
                    Phoneme: "ʌ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ʌ",
                          Score: 100,
                        },
                        {
                          Phoneme: "z",
                          Score: 11,
                        },
                        {
                          Phoneme: "ə",
                          Score: 4,
                        },
                        {
                          Phoneme: "w",
                          Score: 2,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 94400000,
                    Duration: 600000,
                  },
                  {
                    Phoneme: "z",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "z",
                          Score: 100,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 77,
                        },
                        {
                          Phoneme: "f",
                          Score: 33,
                        },
                        {
                          Phoneme: "ə",
                          Score: 1,
                        },
                        {
                          Phoneme: "s",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 95100000,
                    Duration: 500000,
                  },
                ],
              },
              {
                Word: "from",
                Offset: 95700000,
                Duration: 1600000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.4491525e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.46888748,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "frəm",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 95700000,
                    Duration: 1600000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "f",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "f",
                          Score: 100,
                        },
                        {
                          Phoneme: "r",
                          Score: 93,
                        },
                        {
                          Phoneme: "z",
                          Score: 87,
                        },
                        {
                          Phoneme: "s",
                          Score: 0,
                        },
                        {
                          Phoneme: "t",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 95700000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "r",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "r",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 43,
                        },
                        {
                          Phoneme: "f",
                          Score: 3,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɝ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 96100000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ə",
                          Score: 100,
                        },
                        {
                          Phoneme: "r",
                          Score: 89,
                        },
                        {
                          Phoneme: "m",
                          Score: 66,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 0,
                        },
                        {
                          Phoneme: "u",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 96500000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "m",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "m",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 67,
                        },
                        {
                          Phoneme: "w",
                          Score: 5,
                        },
                        {
                          Phoneme: "n",
                          Score: 0,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 96900000,
                    Duration: 400000,
                  },
                ],
              },
              {
                Word: "words",
                Offset: 97400000,
                Duration: 2900000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.4491525e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.46888748,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "wɝrdz",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 97400000,
                    Duration: 2900000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "w",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "w",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɔ",
                          Score: 5,
                        },
                        {
                          Phoneme: "m",
                          Score: 3,
                        },
                        {
                          Phoneme: "ɝ",
                          Score: 1,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 97400000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "ɝ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɝ",
                          Score: 100,
                        },
                        {
                          Phoneme: "r",
                          Score: 33,
                        },
                        {
                          Phoneme: "ɔ",
                          Score: 15,
                        },
                        {
                          Phoneme: "w",
                          Score: 7,
                        },
                        {
                          Phoneme: "ə",
                          Score: 3,
                        },
                      ],
                    },
                    Offset: 98000000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "r",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "r",
                          Score: 100,
                        },
                        {
                          Phoneme: "d",
                          Score: 17,
                        },
                        {
                          Phoneme: "ə",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɝ",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 98600000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "d",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "d",
                          Score: 100,
                        },
                        {
                          Phoneme: "r",
                          Score: 10,
                        },
                        {
                          Phoneme: "z",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                        {
                          Phoneme: "t",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 99000000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "z",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "z",
                          Score: 100,
                        },
                        {
                          Phoneme: "d",
                          Score: 50,
                        },
                        {
                          Phoneme: "s",
                          Score: 11,
                        },
                        {
                          Phoneme: "t",
                          Score: 0,
                        },
                        {
                          Phoneme: "θ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 99800000,
                    Duration: 500000,
                  },
                ],
              },
              {
                Word: "dropped",
                Offset: 100400000,
                Duration: 3900000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.4491525e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.46888748,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "drɑpt",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 100400000,
                    Duration: 3900000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "d",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "d",
                          Score: 100,
                        },
                        {
                          Phoneme: "z",
                          Score: 40,
                        },
                        {
                          Phoneme: "t",
                          Score: 2,
                        },
                        {
                          Phoneme: "dʒ",
                          Score: 1,
                        },
                        {
                          Phoneme: "θ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 100400000,
                    Duration: 1100000,
                  },
                  {
                    Phoneme: "r",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "r",
                          Score: 100,
                        },
                        {
                          Phoneme: "d",
                          Score: 49,
                        },
                        {
                          Phoneme: "ɑ",
                          Score: 0,
                        },
                        {
                          Phoneme: "ʊ",
                          Score: 0,
                        },
                        {
                          Phoneme: "dʒ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 101600000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "ɑ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɑ",
                          Score: 100,
                        },
                        {
                          Phoneme: "p",
                          Score: 41,
                        },
                        {
                          Phoneme: "r",
                          Score: 21,
                        },
                        {
                          Phoneme: "ɔ",
                          Score: 2,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 102400000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "p",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "p",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 72,
                        },
                        {
                          Phoneme: "f",
                          Score: 1,
                        },
                        {
                          Phoneme: "d",
                          Score: 0,
                        },
                        {
                          Phoneme: "k",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 103200000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "t",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 100,
                        },
                        {
                          Phoneme: "b",
                          Score: 34,
                        },
                        {
                          Phoneme: "p",
                          Score: 11,
                        },
                        {
                          Phoneme: "d",
                          Score: 3,
                        },
                        {
                          Phoneme: "k",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 103800000,
                    Duration: 500000,
                  },
                ],
              },
              {
                Word: "by",
                Offset: 104400000,
                Duration: 1300000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.4491525e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.46888748,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "baɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 104400000,
                    Duration: 1300000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "b",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "b",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 4,
                        },
                        {
                          Phoneme: "d",
                          Score: 0,
                        },
                        {
                          Phoneme: "m",
                          Score: 0,
                        },
                        {
                          Phoneme: "p",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 104400000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "aɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "aɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "b",
                          Score: 1,
                        },
                        {
                          Phoneme: "tʃ",
                          Score: 1,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 0,
                        },
                        {
                          Phoneme: "æ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 105000000,
                    Duration: 700000,
                  },
                ],
              },
              {
                Word: "chance",
                Offset: 105800000,
                Duration: 4300000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.4491525e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.46888748,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "tʃæns",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 105800000,
                    Duration: 4300000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "tʃ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "tʃ",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 3,
                        },
                        {
                          Phoneme: "æ",
                          Score: 2,
                        },
                        {
                          Phoneme: "ʃ",
                          Score: 1,
                        },
                        {
                          Phoneme: "dʒ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 105800000,
                    Duration: 1500000,
                  },
                  {
                    Phoneme: "æ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "æ",
                          Score: 100,
                        },
                        {
                          Phoneme: "n",
                          Score: 47,
                        },
                        {
                          Phoneme: "tʃ",
                          Score: 5,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 107400000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "n",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "n",
                          Score: 100,
                        },
                        {
                          Phoneme: "s",
                          Score: 24,
                        },
                        {
                          Phoneme: "t",
                          Score: 20,
                        },
                        {
                          Phoneme: "p",
                          Score: 5,
                        },
                        {
                          Phoneme: "æ",
                          Score: 2,
                        },
                      ],
                    },
                    Offset: 108400000,
                    Duration: 1100000,
                  },
                  {
                    Phoneme: "s",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "s",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 42,
                        },
                        {
                          Phoneme: "v",
                          Score: 12,
                        },
                        {
                          Phoneme: "f",
                          Score: 10,
                        },
                        {
                          Phoneme: "ð",
                          Score: 6,
                        },
                      ],
                    },
                    Offset: 109600000,
                    Duration: 500000,
                  },
                ],
              },
              {
                Word: "that",
                Offset: 110200000,
                Duration: 3700000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.4491525e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.46888748,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "ðæt",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 110200000,
                    Duration: 3700000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "ð",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ð",
                          Score: 100,
                        },
                        {
                          Phoneme: "v",
                          Score: 76,
                        },
                        {
                          Phoneme: "f",
                          Score: 42,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 13,
                        },
                        {
                          Phoneme: "æ",
                          Score: 8,
                        },
                      ],
                    },
                    Offset: 110200000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "æ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "æ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 86,
                        },
                        {
                          Phoneme: "ð",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 1,
                        },
                        {
                          Phoneme: "aʊ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 110800000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "t",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 100,
                        },
                        {
                          Phoneme: "æ",
                          Score: 27,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 6,
                        },
                        {
                          Phoneme: "d",
                          Score: 4,
                        },
                        {
                          Phoneme: "k",
                          Score: 2,
                        },
                      ],
                    },
                    Offset: 111800000,
                    Duration: 2100000,
                  },
                ],
                Punctuation: ",",
              },
              {
                Word: "little",
                Offset: 114200000,
                Duration: 2900000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["MissingBreak"],
                        UnexpectedBreak: {
                          Confidence: 0.088983096,
                        },
                        MissingBreak: {
                          Confidence: 0.9555085,
                        },
                        BreakLength: 200000,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.46888748,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "lɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 114200000,
                    Duration: 1700000,
                  },
                  {
                    Syllable: "təl",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 116000000,
                    Duration: 1100000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "l",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "l",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 2,
                        },
                        {
                          Phoneme: "r",
                          Score: 1,
                        },
                        {
                          Phoneme: "t",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɡ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 114200000,
                    Duration: 1300000,
                  },
                  {
                    Phoneme: "ɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "l",
                          Score: 2,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 0,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 115600000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "t",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 100,
                        },
                        {
                          Phoneme: "d",
                          Score: 13,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 7,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                        {
                          Phoneme: "ð",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 116000000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ə",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 1,
                        },
                        {
                          Phoneme: "t",
                          Score: 0,
                        },
                        {
                          Phoneme: "l",
                          Score: 0,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 116400000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "l",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "l",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 1,
                        },
                        {
                          Phoneme: "d",
                          Score: 1,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 0,
                        },
                        {
                          Phoneme: "r",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 116800000,
                    Duration: 300000,
                  },
                ],
              },
              {
                Word: "by",
                Offset: 117200000,
                Duration: 1500000,
                PronunciationAssessment: {
                  AccuracyScore: 97,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.4491525e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.46888748,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "baɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 97,
                    },
                    Offset: 117200000,
                    Duration: 1500000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "b",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "b",
                          Score: 100,
                        },
                        {
                          Phoneme: "v",
                          Score: 0,
                        },
                        {
                          Phoneme: "aɪ",
                          Score: 0,
                        },
                        {
                          Phoneme: "d",
                          Score: 0,
                        },
                        {
                          Phoneme: "l",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 117200000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "aɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 94,
                      NBestPhonemes: [
                        {
                          Phoneme: "aɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "l",
                          Score: 66,
                        },
                        {
                          Phoneme: "ə",
                          Score: 6,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 6,
                        },
                        {
                          Phoneme: "b",
                          Score: 3,
                        },
                      ],
                    },
                    Offset: 118000000,
                    Duration: 700000,
                  },
                ],
              },
              {
                Word: "little",
                Offset: 118800000,
                Duration: 4500000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.4491525e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.46888748,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "lɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 118800000,
                    Duration: 1500000,
                  },
                  {
                    Syllable: "təl",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 120400000,
                    Duration: 2900000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "l",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "l",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 1,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 1,
                        },
                        {
                          Phoneme: "t",
                          Score: 0,
                        },
                        {
                          Phoneme: "d",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 118800000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "ɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "l",
                          Score: 7,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                        {
                          Phoneme: "t",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 119800000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "t",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 100,
                        },
                        {
                          Phoneme: "d",
                          Score: 5,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 1,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                        {
                          Phoneme: "ð",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 120400000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ə",
                          Score: 100,
                        },
                        {
                          Phoneme: "l",
                          Score: 42,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 3,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 1,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 120800000,
                    Duration: 800000,
                  },
                  {
                    Phoneme: "l",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "l",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 27,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 4,
                        },
                        {
                          Phoneme: "t",
                          Score: 2,
                        },
                        {
                          Phoneme: "p",
                          Score: 2,
                        },
                      ],
                    },
                    Offset: 121700000,
                    Duration: 1600000,
                  },
                ],
                Punctuation: ",",
              },
              {
                Word: "everything",
                Offset: 123600000,
                Duration: 5400000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["MissingBreak"],
                        UnexpectedBreak: {
                          Confidence: 0.088983096,
                        },
                        MissingBreak: {
                          Confidence: 0.9555085,
                        },
                        BreakLength: 200000,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.46888748,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "ɛ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 123600000,
                    Duration: 1200000,
                  },
                  {
                    Syllable: "vri",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 124900000,
                    Duration: 2200000,
                  },
                  {
                    Syllable: "θɪŋ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 127200000,
                    Duration: 1800000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "ɛ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɛ",
                          Score: 100,
                        },
                        {
                          Phoneme: "v",
                          Score: 45,
                        },
                        {
                          Phoneme: "æ",
                          Score: 2,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 1,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 123600000,
                    Duration: 1200000,
                  },
                  {
                    Phoneme: "v",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "v",
                          Score: 100,
                        },
                        {
                          Phoneme: "r",
                          Score: 47,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 20,
                        },
                        {
                          Phoneme: "f",
                          Score: 0,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 124900000,
                    Duration: 800000,
                  },
                  {
                    Phoneme: "r",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "r",
                          Score: 100,
                        },
                        {
                          Phoneme: "i",
                          Score: 2,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 0,
                        },
                        {
                          Phoneme: "v",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 125800000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "i",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "i",
                          Score: 100,
                        },
                        {
                          Phoneme: "θ",
                          Score: 47,
                        },
                        {
                          Phoneme: "r",
                          Score: 2,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                        {
                          Phoneme: "f",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 126400000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "θ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "θ",
                          Score: 100,
                        },
                        {
                          Phoneme: "f",
                          Score: 2,
                        },
                        {
                          Phoneme: "p",
                          Score: 0,
                        },
                        {
                          Phoneme: "s",
                          Score: 0,
                        },
                        {
                          Phoneme: "i",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 127200000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "ɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "θ",
                          Score: 8,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 1,
                        },
                        {
                          Phoneme: "r",
                          Score: 0,
                        },
                        {
                          Phoneme: "f",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 127600000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "ŋ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ŋ",
                          Score: 100,
                        },
                        {
                          Phoneme: "w",
                          Score: 73,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 7,
                        },
                        {
                          Phoneme: "n",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɡ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 128400000,
                    Duration: 600000,
                  },
                ],
              },
              {
                Word: "was",
                Offset: 129100000,
                Duration: 1600000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.4491525e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.46888748,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "wəz",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 129100000,
                    Duration: 1600000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "w",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "w",
                          Score: 100,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 18,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 11,
                        },
                        {
                          Phoneme: "ə",
                          Score: 6,
                        },
                        {
                          Phoneme: "ɡ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 129100000,
                    Duration: 400000,
                  },
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ə",
                          Score: 100,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 40,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 1,
                        },
                        {
                          Phoneme: "z",
                          Score: 0,
                        },
                        {
                          Phoneme: "u",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 129600000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "z",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "z",
                          Score: 100,
                        },
                        {
                          Phoneme: "r",
                          Score: 41,
                        },
                        {
                          Phoneme: "w",
                          Score: 7,
                        },
                        {
                          Phoneme: "s",
                          Score: 0,
                        },
                        {
                          Phoneme: "h",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 130200000,
                    Duration: 500000,
                  },
                ],
              },
              {
                Word: "revealed",
                Offset: 130800000,
                Duration: 4100000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.4491525e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.46888748,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "rə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 130800000,
                    Duration: 1100000,
                  },
                  {
                    Syllable: "vild",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 132000000,
                    Duration: 2900000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "r",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "r",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 92,
                        },
                        {
                          Phoneme: "w",
                          Score: 37,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 7,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 130800000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ə",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 42,
                        },
                        {
                          Phoneme: "r",
                          Score: 3,
                        },
                        {
                          Phoneme: "v",
                          Score: 1,
                        },
                        {
                          Phoneme: "i",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 131400000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "v",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "v",
                          Score: 100,
                        },
                        {
                          Phoneme: "i",
                          Score: 12,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                        {
                          Phoneme: "j",
                          Score: 0,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 132000000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "i",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "i",
                          Score: 100,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 8,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                        {
                          Phoneme: "j",
                          Score: 0,
                        },
                        {
                          Phoneme: "l",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 132800000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "l",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "l",
                          Score: 100,
                        },
                        {
                          Phoneme: "i",
                          Score: 65,
                        },
                        {
                          Phoneme: "d",
                          Score: 5,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 1,
                        },
                        {
                          Phoneme: "ə",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 133600000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "d",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "d",
                          Score: 100,
                        },
                        {
                          Phoneme: "l",
                          Score: 3,
                        },
                        {
                          Phoneme: "t",
                          Score: 0,
                        },
                        {
                          Phoneme: "k",
                          Score: 0,
                        },
                        {
                          Phoneme: "b",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 134400000,
                    Duration: 500000,
                  },
                ],
              },
              {
                Word: "to",
                Offset: 135000000,
                Duration: 1100000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.4491525e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.46888748,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "tə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 135000000,
                    Duration: 1100000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "t",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 100,
                        },
                        {
                          Phoneme: "d",
                          Score: 1,
                        },
                        {
                          Phoneme: "ə",
                          Score: 0,
                        },
                        {
                          Phoneme: "h",
                          Score: 0,
                        },
                        {
                          Phoneme: "k",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 135000000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ə",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 9,
                        },
                        {
                          Phoneme: "m",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                        {
                          Phoneme: "u",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 135400000,
                    Duration: 700000,
                  },
                ],
              },
              {
                Word: "me",
                Offset: 136200000,
                Duration: 3300000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 4.4491525e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.46888748,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "mi",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 136200000,
                    Duration: 3300000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "m",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "m",
                          Score: 100,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 0,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 0,
                        },
                        {
                          Phoneme: "i",
                          Score: 0,
                        },
                        {
                          Phoneme: "b",
                          Score: 0,
                        },
                      ],
                    },
                    Offset: 136200000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "i",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "i",
                          Score: 100,
                        },
                        {
                          Phoneme: "n",
                          Score: 5,
                        },
                        {
                          Phoneme: "m",
                          Score: 4,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 3,
                        },
                        {
                          Phoneme: "t",
                          Score: 2,
                        },
                      ],
                    },
                    Offset: 136800000,
                    Duration: 2700000,
                  },
                ],
                Punctuation: ".",
              },
            ],
          },
        ],
      },
    ],
    scriptText:
      "It took me a long time to learn where he came from. The little prince, who asked me so many questions, never seemed to hear the ones I asked him. It was from words dropped by chance that, little by little, everything was revealed to me.",
  },
  {
    name: "sample-3",
    data: [
      {
        Id: "9b0bb3880c90435db16f4345096a564d",
        RecognitionStatus: 0,
        Offset: 400000,
        Duration: 85100000,
        Channel: 0,
        DisplayText:
          "Question The plausibility of 1 Explanation sometimes offered for dwarfing certain species, Living islands.",
        SNR: 13.500686,
        NBest: [
          {
            Confidence: 0.9406454,
            Lexical:
              "question the plausibility of one explanation sometimes offered for dwarfing certain species living islands",
            ITN: "question the plausibility of 1 explanation sometimes offered for dwarfing certain species living islands",
            MaskedITN:
              "question the plausibility of 1 explanation sometimes offered for dwarfing certain species living islands",
            Display:
              "Question The plausibility of 1 Explanation sometimes offered for dwarfing certain species, Living islands.",
            PronunciationAssessment: {
              AccuracyScore: 60,
              FluencyScore: 87,
              ProsodyScore: 53.2,
              CompletenessScore: 65,
              PronScore: 64,
            },
            Words: [
              {
                Word: "question",
                Offset: 400000,
                Duration: 6200000,
                PronunciationAssessment: {
                  AccuracyScore: 81,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: ["Monotone"],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.31069973,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "kwɛs",
                    PronunciationAssessment: {
                      AccuracyScore: 91,
                    },
                    Offset: 400000,
                    Duration: 2200000,
                  },
                  {
                    Syllable: "tʃən",
                    PronunciationAssessment: {
                      AccuracyScore: 76,
                    },
                    Offset: 2700000,
                    Duration: 3900000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "k",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "k",
                          Score: 100,
                        },
                        {
                          Phoneme: "w",
                          Score: 11,
                        },
                        {
                          Phoneme: "ɡ",
                          Score: 7,
                        },
                        {
                          Phoneme: "r",
                          Score: 1,
                        },
                        {
                          Phoneme: "s",
                          Score: 1,
                        },
                      ],
                    },
                    Offset: 400000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "w",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "w",
                          Score: 100,
                        },
                        {
                          Phoneme: "k",
                          Score: 64,
                        },
                        {
                          Phoneme: "r",
                          Score: 25,
                        },
                        {
                          Phoneme: "ɑ",
                          Score: 8,
                        },
                        {
                          Phoneme: "æ",
                          Score: 6,
                        },
                      ],
                    },
                    Offset: 1000000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "ɛ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɛ",
                          Score: 98,
                        },
                        {
                          Phoneme: "æ",
                          Score: 60,
                        },
                        {
                          Phoneme: "w",
                          Score: 60,
                        },
                        {
                          Phoneme: "aɪ",
                          Score: 59,
                        },
                        {
                          Phoneme: "r",
                          Score: 54,
                        },
                      ],
                    },
                    Offset: 1600000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "s",
                    PronunciationAssessment: {
                      AccuracyScore: 33,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 96,
                        },
                        {
                          Phoneme: "ʃ",
                          Score: 91,
                        },
                        {
                          Phoneme: "tʃ",
                          Score: 55,
                        },
                        {
                          Phoneme: "r",
                          Score: 44,
                        },
                        {
                          Phoneme: "k",
                          Score: 38,
                        },
                      ],
                    },
                    Offset: 2400000,
                    Duration: 200000,
                  },
                  {
                    Phoneme: "tʃ",
                    PronunciationAssessment: {
                      AccuracyScore: 91,
                      NBestPhonemes: [
                        {
                          Phoneme: "tʃ",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 97,
                        },
                        {
                          Phoneme: "ʃ",
                          Score: 52,
                        },
                        {
                          Phoneme: "dʒ",
                          Score: 20,
                        },
                        {
                          Phoneme: "s",
                          Score: 20,
                        },
                      ],
                    },
                    Offset: 2700000,
                    Duration: 800000,
                  },
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 79,
                      NBestPhonemes: [
                        {
                          Phoneme: "ə",
                          Score: 95,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 77,
                        },
                        {
                          Phoneme: "r",
                          Score: 70,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 56,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 55,
                        },
                      ],
                    },
                    Offset: 3600000,
                    Duration: 1100000,
                  },
                  {
                    Phoneme: "n",
                    PronunciationAssessment: {
                      AccuracyScore: 66,
                      NBestPhonemes: [
                        {
                          Phoneme: "n",
                          Score: 100,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 60,
                        },
                        {
                          Phoneme: "ə",
                          Score: 52,
                        },
                        {
                          Phoneme: "d",
                          Score: 36,
                        },
                        {
                          Phoneme: "i",
                          Score: 35,
                        },
                      ],
                    },
                    Offset: 4800000,
                    Duration: 1800000,
                  },
                ],
              },
              {
                Word: "the",
                Offset: 6900000,
                Duration: 1800000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 0.07187116,
                        },
                        MissingBreak: {
                          Confidence: 0.9640644,
                        },
                        BreakLength: 200000,
                      },
                      Intonation: {
                        ErrorTypes: ["Monotone"],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.31069973,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "ðə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 6900000,
                    Duration: 1800000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "ð",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ð",
                          Score: 100,
                        },
                        {
                          Phoneme: "d",
                          Score: 51,
                        },
                        {
                          Phoneme: "ə",
                          Score: 17,
                        },
                        {
                          Phoneme: "t",
                          Score: 16,
                        },
                        {
                          Phoneme: "θ",
                          Score: 6,
                        },
                      ],
                    },
                    Offset: 6900000,
                    Duration: 600000,
                  },
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ə",
                          Score: 100,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 48,
                        },
                        {
                          Phoneme: "ð",
                          Score: 23,
                        },
                        {
                          Phoneme: "r",
                          Score: 5,
                        },
                        {
                          Phoneme: "d",
                          Score: 5,
                        },
                      ],
                    },
                    Offset: 7600000,
                    Duration: 1100000,
                  },
                ],
              },
              {
                Word: "plausibility",
                Offset: 8800000,
                Duration: 9000000,
                PronunciationAssessment: {
                  AccuracyScore: 66,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.593556e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: ["Monotone"],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.31069973,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "plɔ",
                    PronunciationAssessment: {
                      AccuracyScore: 63,
                    },
                    Offset: 8800000,
                    Duration: 3100000,
                  },
                  {
                    Syllable: "zə",
                    PronunciationAssessment: {
                      AccuracyScore: 62,
                    },
                    Offset: 12000000,
                    Duration: 2100000,
                  },
                  {
                    Syllable: "bɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 92,
                    },
                    Offset: 14200000,
                    Duration: 1100000,
                  },
                  {
                    Syllable: "lɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 28,
                    },
                    Offset: 15400000,
                    Duration: 500000,
                  },
                  {
                    Syllable: "ti",
                    PronunciationAssessment: {
                      AccuracyScore: 70,
                    },
                    Offset: 16000000,
                    Duration: 1800000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "p",
                    PronunciationAssessment: {
                      AccuracyScore: 71,
                      NBestPhonemes: [
                        {
                          Phoneme: "p",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɔ",
                          Score: 17,
                        },
                        {
                          Phoneme: "ə",
                          Score: 16,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 13,
                        },
                        {
                          Phoneme: "ɑ",
                          Score: 11,
                        },
                      ],
                    },
                    Offset: 8800000,
                    Duration: 1900000,
                  },
                  {
                    Phoneme: "l",
                    PronunciationAssessment: {
                      AccuracyScore: 61,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɑ",
                          Score: 99,
                        },
                        {
                          Phoneme: "p",
                          Score: 88,
                        },
                        {
                          Phoneme: "ɔ",
                          Score: 87,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 60,
                        },
                        {
                          Phoneme: "ʊ",
                          Score: 22,
                        },
                      ],
                    },
                    Offset: 10800000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "ɔ",
                    PronunciationAssessment: {
                      AccuracyScore: 28,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɑ",
                          Score: 100,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 93,
                        },
                        {
                          Phoneme: "ɔ",
                          Score: 56,
                        },
                        {
                          Phoneme: "l",
                          Score: 45,
                        },
                        {
                          Phoneme: "r",
                          Score: 40,
                        },
                      ],
                    },
                    Offset: 11600000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "z",
                    PronunciationAssessment: {
                      AccuracyScore: 49,
                      NBestPhonemes: [
                        {
                          Phoneme: "s",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɑ",
                          Score: 50,
                        },
                        {
                          Phoneme: "z",
                          Score: 38,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 38,
                        },
                        {
                          Phoneme: "ə",
                          Score: 28,
                        },
                      ],
                    },
                    Offset: 12000000,
                    Duration: 1100000,
                  },
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 78,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 82,
                        },
                        {
                          Phoneme: "b",
                          Score: 71,
                        },
                        {
                          Phoneme: "s",
                          Score: 56,
                        },
                        {
                          Phoneme: "p",
                          Score: 51,
                        },
                      ],
                    },
                    Offset: 13200000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "b",
                    PronunciationAssessment: {
                      AccuracyScore: 96,
                      NBestPhonemes: [
                        {
                          Phoneme: "p",
                          Score: 100,
                        },
                        {
                          Phoneme: "b",
                          Score: 100,
                        },
                        {
                          Phoneme: "v",
                          Score: 11,
                        },
                        {
                          Phoneme: "t",
                          Score: 10,
                        },
                        {
                          Phoneme: "l",
                          Score: 9,
                        },
                      ],
                    },
                    Offset: 14200000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "ɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 87,
                      NBestPhonemes: [
                        {
                          Phoneme: "i",
                          Score: 99,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 87,
                        },
                        {
                          Phoneme: "p",
                          Score: 78,
                        },
                        {
                          Phoneme: "b",
                          Score: 74,
                        },
                        {
                          Phoneme: "l",
                          Score: 39,
                        },
                      ],
                    },
                    Offset: 14800000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "l",
                    PronunciationAssessment: {
                      AccuracyScore: 21,
                      NBestPhonemes: [
                        {
                          Phoneme: "i",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 79,
                        },
                        {
                          Phoneme: "l",
                          Score: 52,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 36,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 25,
                        },
                      ],
                    },
                    Offset: 15400000,
                    Duration: 200000,
                  },
                  {
                    Phoneme: "ɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 34,
                      NBestPhonemes: [
                        {
                          Phoneme: "i",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 73,
                        },
                        {
                          Phoneme: "l",
                          Score: 53,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 46,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 26,
                        },
                      ],
                    },
                    Offset: 15700000,
                    Duration: 200000,
                  },
                  {
                    Phoneme: "t",
                    PronunciationAssessment: {
                      AccuracyScore: 9,
                      NBestPhonemes: [
                        {
                          Phoneme: "i",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 66,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 50,
                        },
                        {
                          Phoneme: "l",
                          Score: 47,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 27,
                        },
                      ],
                    },
                    Offset: 16000000,
                    Duration: 200000,
                  },
                  {
                    Phoneme: "i",
                    PronunciationAssessment: {
                      AccuracyScore: 82,
                      NBestPhonemes: [
                        {
                          Phoneme: "i",
                          Score: 100,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 46,
                        },
                        {
                          Phoneme: "l",
                          Score: 42,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 34,
                        },
                        {
                          Phoneme: "n",
                          Score: 25,
                        },
                      ],
                    },
                    Offset: 16300000,
                    Duration: 1500000,
                  },
                ],
              },
              {
                Word: "of",
                Offset: 18100000,
                Duration: 2800000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 0.07187116,
                        },
                        MissingBreak: {
                          Confidence: 0.9640644,
                        },
                        BreakLength: 200000,
                      },
                      Intonation: {
                        ErrorTypes: ["Monotone"],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.31069973,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "ɔv",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 18100000,
                    Duration: 2800000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "ɔ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɔ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 12,
                        },
                        {
                          Phoneme: "v",
                          Score: 8,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 7,
                        },
                        {
                          Phoneme: "h",
                          Score: 7,
                        },
                      ],
                    },
                    Offset: 18100000,
                    Duration: 1600000,
                  },
                  {
                    Phoneme: "v",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "v",
                          Score: 100,
                        },
                        {
                          Phoneme: "w",
                          Score: 17,
                        },
                        {
                          Phoneme: "f",
                          Score: 7,
                        },
                        {
                          Phoneme: "r",
                          Score: 4,
                        },
                        {
                          Phoneme: "ɔ",
                          Score: 4,
                        },
                      ],
                    },
                    Offset: 19800000,
                    Duration: 1100000,
                  },
                ],
              },
              {
                Word: "one",
                Offset: 21000000,
                Duration: 6500000,
                PronunciationAssessment: {
                  AccuracyScore: 88,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.593556e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: ["Monotone"],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.31069973,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "wɑn",
                    PronunciationAssessment: {
                      AccuracyScore: 88,
                    },
                    Offset: 21000000,
                    Duration: 6500000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "w",
                    PronunciationAssessment: {
                      AccuracyScore: 86,
                      NBestPhonemes: [
                        {
                          Phoneme: "w",
                          Score: 100,
                        },
                        {
                          Phoneme: "v",
                          Score: 53,
                        },
                        {
                          Phoneme: "r",
                          Score: 30,
                        },
                        {
                          Phoneme: "ɡ",
                          Score: 22,
                        },
                        {
                          Phoneme: "l",
                          Score: 15,
                        },
                      ],
                    },
                    Offset: 21000000,
                    Duration: 1500000,
                  },
                  {
                    Phoneme: "ɑ",
                    PronunciationAssessment: {
                      AccuracyScore: 98,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɑ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ʌ",
                          Score: 67,
                        },
                        {
                          Phoneme: "ɔ",
                          Score: 58,
                        },
                        {
                          Phoneme: "r",
                          Score: 53,
                        },
                        {
                          Phoneme: "w",
                          Score: 52,
                        },
                      ],
                    },
                    Offset: 22600000,
                    Duration: 1900000,
                  },
                  {
                    Phoneme: "n",
                    PronunciationAssessment: {
                      AccuracyScore: 83,
                      NBestPhonemes: [
                        {
                          Phoneme: "n",
                          Score: 100,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 91,
                        },
                        {
                          Phoneme: "m",
                          Score: 43,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 28,
                        },
                        {
                          Phoneme: "r",
                          Score: 27,
                        },
                      ],
                    },
                    Offset: 24600000,
                    Duration: 2900000,
                  },
                ],
              },
              {
                Word: "explanation",
                Offset: 27800000,
                Duration: 6400000,
                PronunciationAssessment: {
                  AccuracyScore: 53,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 0.07187116,
                        },
                        MissingBreak: {
                          Confidence: 0.9640644,
                        },
                        BreakLength: 200000,
                      },
                      Intonation: {
                        ErrorTypes: ["Monotone"],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.31069973,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "ɛk",
                    PronunciationAssessment: {
                      AccuracyScore: 62,
                    },
                    Offset: 27800000,
                    Duration: 1500000,
                  },
                  {
                    Syllable: "splə",
                    PronunciationAssessment: {
                      AccuracyScore: 70,
                    },
                    Offset: 29400000,
                    Duration: 2400000,
                  },
                  {
                    Syllable: "neɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 0,
                    },
                    Offset: 31900000,
                    Duration: 500000,
                  },
                  {
                    Syllable: "ʃən",
                    PronunciationAssessment: {
                      AccuracyScore: 37,
                    },
                    Offset: 32500000,
                    Duration: 1700000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "ɛ",
                    PronunciationAssessment: {
                      AccuracyScore: 66,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɛ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 60,
                        },
                        {
                          Phoneme: "æ",
                          Score: 57,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 29,
                        },
                        {
                          Phoneme: "h",
                          Score: 10,
                        },
                      ],
                    },
                    Offset: 27800000,
                    Duration: 1200000,
                  },
                  {
                    Phoneme: "k",
                    PronunciationAssessment: {
                      AccuracyScore: 43,
                      NBestPhonemes: [
                        {
                          Phoneme: "s",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 91,
                        },
                        {
                          Phoneme: "z",
                          Score: 84,
                        },
                        {
                          Phoneme: "ə",
                          Score: 64,
                        },
                        {
                          Phoneme: "k",
                          Score: 16,
                        },
                      ],
                    },
                    Offset: 29100000,
                    Duration: 200000,
                  },
                  {
                    Phoneme: "s",
                    PronunciationAssessment: {
                      AccuracyScore: 74,
                      NBestPhonemes: [
                        {
                          Phoneme: "s",
                          Score: 100,
                        },
                        {
                          Phoneme: "z",
                          Score: 50,
                        },
                        {
                          Phoneme: "f",
                          Score: 7,
                        },
                        {
                          Phoneme: "t",
                          Score: 5,
                        },
                        {
                          Phoneme: "θ",
                          Score: 5,
                        },
                      ],
                    },
                    Offset: 29400000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "p",
                    PronunciationAssessment: {
                      AccuracyScore: 98,
                      NBestPhonemes: [
                        {
                          Phoneme: "p",
                          Score: 100,
                        },
                        {
                          Phoneme: "s",
                          Score: 56,
                        },
                        {
                          Phoneme: "t",
                          Score: 16,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 9,
                        },
                        {
                          Phoneme: "b",
                          Score: 9,
                        },
                      ],
                    },
                    Offset: 30200000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "l",
                    PronunciationAssessment: {
                      AccuracyScore: 38,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɛ",
                          Score: 100,
                        },
                        {
                          Phoneme: "æ",
                          Score: 77,
                        },
                        {
                          Phoneme: "p",
                          Score: 47,
                        },
                        {
                          Phoneme: "l",
                          Score: 38,
                        },
                        {
                          Phoneme: "ə",
                          Score: 20,
                        },
                      ],
                    },
                    Offset: 31200000,
                    Duration: 300000,
                  },
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 12,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɛ",
                          Score: 100,
                        },
                        {
                          Phoneme: "æ",
                          Score: 69,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 42,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 26,
                        },
                        {
                          Phoneme: "ə",
                          Score: 24,
                        },
                      ],
                    },
                    Offset: 31600000,
                    Duration: 200000,
                  },
                  {
                    Phoneme: "n",
                    PronunciationAssessment: {
                      AccuracyScore: 0,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɛ",
                          Score: 100,
                        },
                        {
                          Phoneme: "æ",
                          Score: 54,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 48,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 28,
                        },
                        {
                          Phoneme: "ə",
                          Score: 25,
                        },
                      ],
                    },
                    Offset: 31900000,
                    Duration: 200000,
                  },
                  {
                    Phoneme: "eɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 0,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɛ",
                          Score: 100,
                        },
                        {
                          Phoneme: "n",
                          Score: 90,
                        },
                        {
                          Phoneme: "æ",
                          Score: 47,
                        },
                        {
                          Phoneme: "ə",
                          Score: 43,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 42,
                        },
                      ],
                    },
                    Offset: 32200000,
                    Duration: 200000,
                  },
                  {
                    Phoneme: "ʃ",
                    PronunciationAssessment: {
                      AccuracyScore: 0,
                      NBestPhonemes: [
                        {
                          Phoneme: "n",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 75,
                        },
                        {
                          Phoneme: "ə",
                          Score: 60,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 55,
                        },
                        {
                          Phoneme: "æ",
                          Score: 41,
                        },
                      ],
                    },
                    Offset: 32500000,
                    Duration: 200000,
                  },
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 4,
                      NBestPhonemes: [
                        {
                          Phoneme: "n",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 67,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 65,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 34,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 32,
                        },
                      ],
                    },
                    Offset: 32800000,
                    Duration: 200000,
                  },
                  {
                    Phoneme: "n",
                    PronunciationAssessment: {
                      AccuracyScore: 55,
                      NBestPhonemes: [
                        {
                          Phoneme: "n",
                          Score: 100,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 59,
                        },
                        {
                          Phoneme: "m",
                          Score: 47,
                        },
                        {
                          Phoneme: "d",
                          Score: 40,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 22,
                        },
                      ],
                    },
                    Offset: 33100000,
                    Duration: 1100000,
                  },
                ],
              },
              {
                Word: "sometimes",
                Offset: 34500000,
                Duration: 5700000,
                PronunciationAssessment: {
                  AccuracyScore: 67,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 0.07187116,
                        },
                        MissingBreak: {
                          Confidence: 0.9640644,
                        },
                        BreakLength: 200000,
                      },
                      Intonation: {
                        ErrorTypes: ["Monotone"],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.31069973,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "sʌm",
                    PronunciationAssessment: {
                      AccuracyScore: 84,
                    },
                    Offset: 34500000,
                    Duration: 4500000,
                  },
                  {
                    Syllable: "taɪmz",
                    PronunciationAssessment: {
                      AccuracyScore: 1,
                    },
                    Offset: 39100000,
                    Duration: 1100000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "s",
                    PronunciationAssessment: {
                      AccuracyScore: 87,
                      NBestPhonemes: [
                        {
                          Phoneme: "s",
                          Score: 100,
                        },
                        {
                          Phoneme: "z",
                          Score: 48,
                        },
                        {
                          Phoneme: "t",
                          Score: 32,
                        },
                        {
                          Phoneme: "θ",
                          Score: 25,
                        },
                        {
                          Phoneme: "n",
                          Score: 22,
                        },
                      ],
                    },
                    Offset: 34500000,
                    Duration: 2400000,
                  },
                  {
                    Phoneme: "ʌ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ʌ",
                          Score: 100,
                        },
                        {
                          Phoneme: "m",
                          Score: 70,
                        },
                        {
                          Phoneme: "ɑ",
                          Score: 56,
                        },
                        {
                          Phoneme: "æ",
                          Score: 42,
                        },
                        {
                          Phoneme: "n",
                          Score: 21,
                        },
                      ],
                    },
                    Offset: 37000000,
                    Duration: 1300000,
                  },
                  {
                    Phoneme: "m",
                    PronunciationAssessment: {
                      AccuracyScore: 42,
                      NBestPhonemes: [
                        {
                          Phoneme: "m",
                          Score: 100,
                        },
                        {
                          Phoneme: "n",
                          Score: 72,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 30,
                        },
                        {
                          Phoneme: "ə",
                          Score: 16,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 15,
                        },
                      ],
                    },
                    Offset: 38400000,
                    Duration: 600000,
                  },
                  {
                    Phoneme: "t",
                    PronunciationAssessment: {
                      AccuracyScore: 0,
                      NBestPhonemes: [
                        {
                          Phoneme: "m",
                          Score: 100,
                        },
                        {
                          Phoneme: "n",
                          Score: 88,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 48,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 32,
                        },
                        {
                          Phoneme: "ə",
                          Score: 22,
                        },
                      ],
                    },
                    Offset: 39100000,
                    Duration: 200000,
                  },
                  {
                    Phoneme: "aɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 0,
                      NBestPhonemes: [
                        {
                          Phoneme: "m",
                          Score: 100,
                        },
                        {
                          Phoneme: "n",
                          Score: 80,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 41,
                        },
                        {
                          Phoneme: "d",
                          Score: 32,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 28,
                        },
                      ],
                    },
                    Offset: 39400000,
                    Duration: 200000,
                  },
                  {
                    Phoneme: "m",
                    PronunciationAssessment: {
                      AccuracyScore: 0,
                      NBestPhonemes: [
                        {
                          Phoneme: "m",
                          Score: 99,
                        },
                        {
                          Phoneme: "n",
                          Score: 75,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 42,
                        },
                        {
                          Phoneme: "p",
                          Score: 38,
                        },
                        {
                          Phoneme: "d",
                          Score: 37,
                        },
                      ],
                    },
                    Offset: 39700000,
                    Duration: 200000,
                  },
                  {
                    Phoneme: "z",
                    PronunciationAssessment: {
                      AccuracyScore: 3,
                      NBestPhonemes: [
                        {
                          Phoneme: "m",
                          Score: 93,
                        },
                        {
                          Phoneme: "n",
                          Score: 66,
                        },
                        {
                          Phoneme: "p",
                          Score: 50,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 45,
                        },
                        {
                          Phoneme: "t",
                          Score: 38,
                        },
                      ],
                    },
                    Offset: 40000000,
                    Duration: 200000,
                  },
                ],
              },
              {
                Word: "offered",
                Offset: 40500000,
                Duration: 5400000,
                PronunciationAssessment: {
                  AccuracyScore: 73,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 0.07187116,
                        },
                        MissingBreak: {
                          Confidence: 0.9640644,
                        },
                        BreakLength: 200000,
                      },
                      Intonation: {
                        ErrorTypes: ["Monotone"],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.31069973,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "ɔ",
                    PronunciationAssessment: {
                      AccuracyScore: 86,
                    },
                    Offset: 40500000,
                    Duration: 1400000,
                  },
                  {
                    Syllable: "fərd",
                    PronunciationAssessment: {
                      AccuracyScore: 68,
                    },
                    Offset: 42000000,
                    Duration: 3900000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "ɔ",
                    PronunciationAssessment: {
                      AccuracyScore: 86,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɔ",
                          Score: 100,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 57,
                        },
                        {
                          Phoneme: "m",
                          Score: 48,
                        },
                        {
                          Phoneme: "r",
                          Score: 42,
                        },
                        {
                          Phoneme: "l",
                          Score: 28,
                        },
                      ],
                    },
                    Offset: 40500000,
                    Duration: 1400000,
                  },
                  {
                    Phoneme: "f",
                    PronunciationAssessment: {
                      AccuracyScore: 81,
                      NBestPhonemes: [
                        {
                          Phoneme: "v",
                          Score: 100,
                        },
                        {
                          Phoneme: "f",
                          Score: 94,
                        },
                        {
                          Phoneme: "r",
                          Score: 63,
                        },
                        {
                          Phoneme: "p",
                          Score: 31,
                        },
                        {
                          Phoneme: "θ",
                          Score: 28,
                        },
                      ],
                    },
                    Offset: 42000000,
                    Duration: 1700000,
                  },
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 73,
                      NBestPhonemes: [
                        {
                          Phoneme: "r",
                          Score: 98,
                        },
                        {
                          Phoneme: "ə",
                          Score: 80,
                        },
                        {
                          Phoneme: "ɝ",
                          Score: 77,
                        },
                        {
                          Phoneme: "v",
                          Score: 46,
                        },
                        {
                          Phoneme: "ɔ",
                          Score: 40,
                        },
                      ],
                    },
                    Offset: 43800000,
                    Duration: 1100000,
                  },
                  {
                    Phoneme: "r",
                    PronunciationAssessment: {
                      AccuracyScore: 31,
                      NBestPhonemes: [
                        {
                          Phoneme: "r",
                          Score: 97,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 90,
                        },
                        {
                          Phoneme: "l",
                          Score: 77,
                        },
                        {
                          Phoneme: "ə",
                          Score: 69,
                        },
                        {
                          Phoneme: "u",
                          Score: 21,
                        },
                      ],
                    },
                    Offset: 45000000,
                    Duration: 200000,
                  },
                  {
                    Phoneme: "d",
                    PronunciationAssessment: {
                      AccuracyScore: 43,
                      NBestPhonemes: [
                        {
                          Phoneme: "r",
                          Score: 100,
                        },
                        {
                          Phoneme: "v",
                          Score: 72,
                        },
                        {
                          Phoneme: "f",
                          Score: 65,
                        },
                        {
                          Phoneme: "l",
                          Score: 59,
                        },
                        {
                          Phoneme: "ə",
                          Score: 42,
                        },
                      ],
                    },
                    Offset: 45300000,
                    Duration: 600000,
                  },
                ],
              },
              {
                Word: "for",
                Offset: 46000000,
                Duration: 4700000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.593556e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: ["Monotone"],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.31069973,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "fɔr",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 46000000,
                    Duration: 4700000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "f",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "f",
                          Score: 100,
                        },
                        {
                          Phoneme: "v",
                          Score: 83,
                        },
                        {
                          Phoneme: "ə",
                          Score: 62,
                        },
                        {
                          Phoneme: "ɔ",
                          Score: 19,
                        },
                        {
                          Phoneme: "b",
                          Score: 18,
                        },
                      ],
                    },
                    Offset: 46000000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "ɔ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "oʊ",
                          Score: 92,
                        },
                        {
                          Phoneme: "ɔ",
                          Score: 90,
                        },
                        {
                          Phoneme: "l",
                          Score: 64,
                        },
                        {
                          Phoneme: "ə",
                          Score: 62,
                        },
                        {
                          Phoneme: "r",
                          Score: 42,
                        },
                      ],
                    },
                    Offset: 47000000,
                    Duration: 2100000,
                  },
                  {
                    Phoneme: "r",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "l",
                          Score: 94,
                        },
                        {
                          Phoneme: "r",
                          Score: 89,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 77,
                        },
                        {
                          Phoneme: "n",
                          Score: 41,
                        },
                        {
                          Phoneme: "d",
                          Score: 40,
                        },
                      ],
                    },
                    Offset: 49200000,
                    Duration: 1500000,
                  },
                ],
              },
              {
                Word: "the",
                PronunciationAssessment: {
                  ErrorType: "Omission",
                },
                Phonemes: [],
              },
              {
                Word: "dwarfing",
                Offset: 51000000,
                Duration: 7600000,
                PronunciationAssessment: {
                  AccuracyScore: 40,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 0.07187116,
                        },
                        MissingBreak: {
                          Confidence: 0.9640644,
                        },
                        BreakLength: 200000,
                      },
                      Intonation: {
                        ErrorTypes: ["Monotone"],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.31069973,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "dwɔr",
                    PronunciationAssessment: {
                      AccuracyScore: 58,
                    },
                    Offset: 51000000,
                    Duration: 2300000,
                  },
                  {
                    Syllable: "fɪŋ",
                    PronunciationAssessment: {
                      AccuracyScore: 32,
                    },
                    Offset: 53400000,
                    Duration: 5200000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "d",
                    PronunciationAssessment: {
                      AccuracyScore: 55,
                      NBestPhonemes: [
                        {
                          Phoneme: "d",
                          Score: 100,
                        },
                        {
                          Phoneme: "r",
                          Score: 88,
                        },
                        {
                          Phoneme: "dʒ",
                          Score: 56,
                        },
                        {
                          Phoneme: "t",
                          Score: 49,
                        },
                        {
                          Phoneme: "w",
                          Score: 3,
                        },
                      ],
                    },
                    Offset: 51000000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "w",
                    PronunciationAssessment: {
                      AccuracyScore: 35,
                      NBestPhonemes: [
                        {
                          Phoneme: "r",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 21,
                        },
                        {
                          Phoneme: "ɑ",
                          Score: 18,
                        },
                        {
                          Phoneme: "ɔ",
                          Score: 13,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 7,
                        },
                      ],
                    },
                    Offset: 52000000,
                    Duration: 200000,
                  },
                  {
                    Phoneme: "ɔ",
                    PronunciationAssessment: {
                      AccuracyScore: 52,
                      NBestPhonemes: [
                        {
                          Phoneme: "r",
                          Score: 98,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 86,
                        },
                        {
                          Phoneme: "ɔ",
                          Score: 83,
                        },
                        {
                          Phoneme: "ɑ",
                          Score: 81,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 50,
                        },
                      ],
                    },
                    Offset: 52300000,
                    Duration: 200000,
                  },
                  {
                    Phoneme: "r",
                    PronunciationAssessment: {
                      AccuracyScore: 72,
                      NBestPhonemes: [
                        {
                          Phoneme: "l",
                          Score: 94,
                        },
                        {
                          Phoneme: "v",
                          Score: 88,
                        },
                        {
                          Phoneme: "ɑ",
                          Score: 70,
                        },
                        {
                          Phoneme: "f",
                          Score: 55,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 54,
                        },
                      ],
                    },
                    Offset: 52600000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "f",
                    PronunciationAssessment: {
                      AccuracyScore: 0,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 100,
                        },
                        {
                          Phoneme: "p",
                          Score: 86,
                        },
                        {
                          Phoneme: "f",
                          Score: 77,
                        },
                        {
                          Phoneme: "b",
                          Score: 45,
                        },
                        {
                          Phoneme: "v",
                          Score: 33,
                        },
                      ],
                    },
                    Offset: 53400000,
                    Duration: 1300000,
                  },
                  {
                    Phoneme: "ɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 32,
                      NBestPhonemes: [
                        {
                          Phoneme: "i",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 91,
                        },
                        {
                          Phoneme: "t",
                          Score: 86,
                        },
                        {
                          Phoneme: "n",
                          Score: 22,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 16,
                        },
                      ],
                    },
                    Offset: 54800000,
                    Duration: 1300000,
                  },
                  {
                    Phoneme: "ŋ",
                    PronunciationAssessment: {
                      AccuracyScore: 49,
                      NBestPhonemes: [
                        {
                          Phoneme: "n",
                          Score: 100,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 67,
                        },
                        {
                          Phoneme: "m",
                          Score: 60,
                        },
                        {
                          Phoneme: "θ",
                          Score: 43,
                        },
                        {
                          Phoneme: "i",
                          Score: 26,
                        },
                      ],
                    },
                    Offset: 56200000,
                    Duration: 2400000,
                  },
                ],
              },
              {
                Word: "of",
                PronunciationAssessment: {
                  ErrorType: "Omission",
                },
                Phonemes: [],
              },
              {
                Word: "certain",
                Offset: 58700000,
                Duration: 4600000,
                PronunciationAssessment: {
                  AccuracyScore: 85,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.593556e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: ["Monotone"],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.31069973,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "sɝr",
                    PronunciationAssessment: {
                      AccuracyScore: 93,
                    },
                    Offset: 58700000,
                    Duration: 2300000,
                  },
                  {
                    Syllable: "tən",
                    PronunciationAssessment: {
                      AccuracyScore: 76,
                    },
                    Offset: 61100000,
                    Duration: 2200000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "s",
                    PronunciationAssessment: {
                      AccuracyScore: 75,
                      NBestPhonemes: [
                        {
                          Phoneme: "θ",
                          Score: 100,
                        },
                        {
                          Phoneme: "s",
                          Score: 92,
                        },
                        {
                          Phoneme: "z",
                          Score: 79,
                        },
                        {
                          Phoneme: "d",
                          Score: 21,
                        },
                        {
                          Phoneme: "v",
                          Score: 10,
                        },
                      ],
                    },
                    Offset: 58700000,
                    Duration: 600000,
                  },
                  {
                    Phoneme: "ɝ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɝ",
                          Score: 100,
                        },
                        {
                          Phoneme: "θ",
                          Score: 74,
                        },
                        {
                          Phoneme: "ə",
                          Score: 22,
                        },
                        {
                          Phoneme: "r",
                          Score: 14,
                        },
                        {
                          Phoneme: "ɔ",
                          Score: 13,
                        },
                      ],
                    },
                    Offset: 59400000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "r",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "r",
                          Score: 100,
                        },
                        {
                          Phoneme: "d",
                          Score: 81,
                        },
                        {
                          Phoneme: "ɝ",
                          Score: 50,
                        },
                        {
                          Phoneme: "t",
                          Score: 27,
                        },
                        {
                          Phoneme: "ə",
                          Score: 10,
                        },
                      ],
                    },
                    Offset: 60400000,
                    Duration: 600000,
                  },
                  {
                    Phoneme: "t",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "t",
                          Score: 100,
                        },
                        {
                          Phoneme: "d",
                          Score: 95,
                        },
                        {
                          Phoneme: "r",
                          Score: 51,
                        },
                        {
                          Phoneme: "dʒ",
                          Score: 24,
                        },
                        {
                          Phoneme: "θ",
                          Score: 15,
                        },
                      ],
                    },
                    Offset: 61100000,
                    Duration: 600000,
                  },
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 92,
                      NBestPhonemes: [
                        {
                          Phoneme: "ə",
                          Score: 100,
                        },
                        {
                          Phoneme: "i",
                          Score: 91,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 75,
                        },
                        {
                          Phoneme: "t",
                          Score: 49,
                        },
                        {
                          Phoneme: "eɪ",
                          Score: 30,
                        },
                      ],
                    },
                    Offset: 61800000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "n",
                    PronunciationAssessment: {
                      AccuracyScore: 50,
                      NBestPhonemes: [
                        {
                          Phoneme: "n",
                          Score: 100,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 72,
                        },
                        {
                          Phoneme: "ə",
                          Score: 61,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 48,
                        },
                        {
                          Phoneme: "i",
                          Score: 43,
                        },
                      ],
                    },
                    Offset: 62400000,
                    Duration: 900000,
                  },
                ],
              },
              {
                Word: "species",
                Offset: 63400000,
                Duration: 6900000,
                PronunciationAssessment: {
                  AccuracyScore: 39,
                  ErrorType: "Mispronunciation",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.593556e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: ["Monotone"],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.31069973,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "spi",
                    PronunciationAssessment: {
                      AccuracyScore: 47,
                    },
                    Offset: 63400000,
                    Duration: 4700000,
                  },
                  {
                    Syllable: "siz",
                    PronunciationAssessment: {
                      AccuracyScore: 22,
                    },
                    Offset: 68200000,
                    Duration: 2100000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "s",
                    PronunciationAssessment: {
                      AccuracyScore: 99,
                      NBestPhonemes: [
                        {
                          Phoneme: "s",
                          Score: 100,
                        },
                        {
                          Phoneme: "p",
                          Score: 68,
                        },
                        {
                          Phoneme: "z",
                          Score: 64,
                        },
                        {
                          Phoneme: "t",
                          Score: 24,
                        },
                        {
                          Phoneme: "θ",
                          Score: 12,
                        },
                      ],
                    },
                    Offset: 63400000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "p",
                    PronunciationAssessment: {
                      AccuracyScore: 38,
                      NBestPhonemes: [
                        {
                          Phoneme: "p",
                          Score: 100,
                        },
                        {
                          Phoneme: "b",
                          Score: 63,
                        },
                        {
                          Phoneme: "t",
                          Score: 19,
                        },
                        {
                          Phoneme: "s",
                          Score: 7,
                        },
                        {
                          Phoneme: "f",
                          Score: 6,
                        },
                      ],
                    },
                    Offset: 64400000,
                    Duration: 1100000,
                  },
                  {
                    Phoneme: "i",
                    PronunciationAssessment: {
                      AccuracyScore: 31,
                      NBestPhonemes: [
                        {
                          Phoneme: "aɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "p",
                          Score: 73,
                        },
                        {
                          Phoneme: "z",
                          Score: 51,
                        },
                        {
                          Phoneme: "s",
                          Score: 34,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 22,
                        },
                      ],
                    },
                    Offset: 65600000,
                    Duration: 2500000,
                  },
                  {
                    Phoneme: "s",
                    PronunciationAssessment: {
                      AccuracyScore: 24,
                      NBestPhonemes: [
                        {
                          Phoneme: "s",
                          Score: 100,
                        },
                        {
                          Phoneme: "z",
                          Score: 95,
                        },
                        {
                          Phoneme: "t",
                          Score: 42,
                        },
                        {
                          Phoneme: "θ",
                          Score: 13,
                        },
                        {
                          Phoneme: "d",
                          Score: 13,
                        },
                      ],
                    },
                    Offset: 68200000,
                    Duration: 600000,
                  },
                  {
                    Phoneme: "i",
                    PronunciationAssessment: {
                      AccuracyScore: 0,
                      NBestPhonemes: [
                        {
                          Phoneme: "s",
                          Score: 100,
                        },
                        {
                          Phoneme: "z",
                          Score: 92,
                        },
                        {
                          Phoneme: "t",
                          Score: 52,
                        },
                        {
                          Phoneme: "θ",
                          Score: 16,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 14,
                        },
                      ],
                    },
                    Offset: 68900000,
                    Duration: 200000,
                  },
                  {
                    Phoneme: "z",
                    PronunciationAssessment: {
                      AccuracyScore: 26,
                      NBestPhonemes: [
                        {
                          Phoneme: "s",
                          Score: 100,
                        },
                        {
                          Phoneme: "z",
                          Score: 72,
                        },
                        {
                          Phoneme: "t",
                          Score: 58,
                        },
                        {
                          Phoneme: "n",
                          Score: 51,
                        },
                        {
                          Phoneme: "l",
                          Score: 22,
                        },
                      ],
                    },
                    Offset: 69200000,
                    Duration: 1100000,
                  },
                ],
              },
              {
                Word: "living",
                Offset: 70400000,
                Duration: 8100000,
                PronunciationAssessment: {
                  AccuracyScore: 68,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 3.593556e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: ["Monotone"],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.31069973,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "lɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 38,
                    },
                    Offset: 70400000,
                    Duration: 3700000,
                  },
                  {
                    Syllable: "vɪŋ",
                    PronunciationAssessment: {
                      AccuracyScore: 94,
                    },
                    Offset: 74200000,
                    Duration: 4300000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "l",
                    PronunciationAssessment: {
                      AccuracyScore: 37,
                      NBestPhonemes: [
                        {
                          Phoneme: "n",
                          Score: 100,
                        },
                        {
                          Phoneme: "l",
                          Score: 100,
                        },
                        {
                          Phoneme: "t",
                          Score: 32,
                        },
                        {
                          Phoneme: "ə",
                          Score: 14,
                        },
                        {
                          Phoneme: "m",
                          Score: 9,
                        },
                      ],
                    },
                    Offset: 70400000,
                    Duration: 1100000,
                  },
                  {
                    Phoneme: "ɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 38,
                      NBestPhonemes: [
                        {
                          Phoneme: "aɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "l",
                          Score: 81,
                        },
                        {
                          Phoneme: "v",
                          Score: 43,
                        },
                        {
                          Phoneme: "n",
                          Score: 28,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 16,
                        },
                      ],
                    },
                    Offset: 71600000,
                    Duration: 2500000,
                  },
                  {
                    Phoneme: "v",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "v",
                          Score: 100,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 54,
                        },
                        {
                          Phoneme: "r",
                          Score: 11,
                        },
                        {
                          Phoneme: "b",
                          Score: 6,
                        },
                        {
                          Phoneme: "ə",
                          Score: 4,
                        },
                      ],
                    },
                    Offset: 74200000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "ɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ɪ",
                          Score: 100,
                        },
                        {
                          Phoneme: "ŋ",
                          Score: 47,
                        },
                        {
                          Phoneme: "i",
                          Score: 6,
                        },
                        {
                          Phoneme: "n",
                          Score: 5,
                        },
                        {
                          Phoneme: "ə",
                          Score: 2,
                        },
                      ],
                    },
                    Offset: 75200000,
                    Duration: 1100000,
                  },
                  {
                    Phoneme: "ŋ",
                    PronunciationAssessment: {
                      AccuracyScore: 88,
                      NBestPhonemes: [
                        {
                          Phoneme: "ŋ",
                          Score: 100,
                        },
                        {
                          Phoneme: "n",
                          Score: 57,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 20,
                        },
                        {
                          Phoneme: "i",
                          Score: 18,
                        },
                        {
                          Phoneme: "m",
                          Score: 16,
                        },
                      ],
                    },
                    Offset: 76400000,
                    Duration: 2100000,
                  },
                ],
              },
              {
                Word: "on",
                PronunciationAssessment: {
                  ErrorType: "Omission",
                },
                Phonemes: [],
              },
              {
                Word: "islands",
                Offset: 81900000,
                Duration: 3600000,
                PronunciationAssessment: {
                  AccuracyScore: 65,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 1.1858735,
                        },
                        MissingBreak: {
                          Confidence: 0,
                        },
                        BreakLength: 3300000,
                      },
                      Intonation: {
                        ErrorTypes: ["Monotone"],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.31069973,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "aɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 0,
                    },
                    Offset: 81900000,
                    Duration: 200000,
                  },
                  {
                    Syllable: "ləndz",
                    PronunciationAssessment: {
                      AccuracyScore: 71,
                    },
                    Offset: 82200000,
                    Duration: 3300000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "aɪ",
                    PronunciationAssessment: {
                      AccuracyScore: 0,
                      NBestPhonemes: [
                        {
                          Phoneme: "ə",
                          Score: 100,
                        },
                        {
                          Phoneme: "l",
                          Score: 32,
                        },
                        {
                          Phoneme: "r",
                          Score: 27,
                        },
                        {
                          Phoneme: "oʊ",
                          Score: 16,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 8,
                        },
                      ],
                    },
                    Offset: 81900000,
                    Duration: 200000,
                  },
                  {
                    Phoneme: "l",
                    PronunciationAssessment: {
                      AccuracyScore: 55,
                      NBestPhonemes: [
                        {
                          Phoneme: "l",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 67,
                        },
                        {
                          Phoneme: "r",
                          Score: 50,
                        },
                        {
                          Phoneme: "t",
                          Score: 3,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 3,
                        },
                      ],
                    },
                    Offset: 82200000,
                    Duration: 500000,
                  },
                  {
                    Phoneme: "ə",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                      NBestPhonemes: [
                        {
                          Phoneme: "ə",
                          Score: 100,
                        },
                        {
                          Phoneme: "l",
                          Score: 82,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 42,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 37,
                        },
                        {
                          Phoneme: "æ",
                          Score: 20,
                        },
                      ],
                    },
                    Offset: 82800000,
                    Duration: 900000,
                  },
                  {
                    Phoneme: "n",
                    PronunciationAssessment: {
                      AccuracyScore: 88,
                      NBestPhonemes: [
                        {
                          Phoneme: "n",
                          Score: 100,
                        },
                        {
                          Phoneme: "d",
                          Score: 97,
                        },
                        {
                          Phoneme: "ə",
                          Score: 15,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 6,
                        },
                        {
                          Phoneme: "t",
                          Score: 5,
                        },
                      ],
                    },
                    Offset: 83800000,
                    Duration: 1100000,
                  },
                  {
                    Phoneme: "d",
                    PronunciationAssessment: {
                      AccuracyScore: 4,
                      NBestPhonemes: [
                        {
                          Phoneme: "d",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 31,
                        },
                        {
                          Phoneme: "t",
                          Score: 13,
                        },
                        {
                          Phoneme: "ð",
                          Score: 11,
                        },
                        {
                          Phoneme: "n",
                          Score: 8,
                        },
                      ],
                    },
                    Offset: 85000000,
                    Duration: 200000,
                  },
                  {
                    Phoneme: "z",
                    PronunciationAssessment: {
                      AccuracyScore: 0,
                      NBestPhonemes: [
                        {
                          Phoneme: "d",
                          Score: 100,
                        },
                        {
                          Phoneme: "ə",
                          Score: 99,
                        },
                        {
                          Phoneme: "ɛ",
                          Score: 26,
                        },
                        {
                          Phoneme: "ɪ",
                          Score: 22,
                        },
                        {
                          Phoneme: "æ",
                          Score: 15,
                        },
                      ],
                    },
                    Offset: 85300000,
                    Duration: 200000,
                  },
                ],
                Punctuation: ".",
              },
            ],
          },
        ],
      },
    ],
    scriptText:
      "Question the plausibility of one explanation sometimes offered for the dwarfing of certain species living on islands.",
  },
  {
    name: "sample-4",
    data: [
      {
        Id: "4858629237624d4dabfbad5d6845e024",
        RecognitionStatus: 0,
        Offset: 400000,
        Duration: 21100000,
        Channel: 0,
        DisplayText: "Taking a walk.",
        SNR: 17.876905,
        NBest: [
          {
            Confidence: 0.93863195,
            Lexical: "taking a walk",
            ITN: "taking a walk",
            MaskedITN: "taking a walk",
            Display: "Taking a walk.",
            PronunciationAssessment: {
              AccuracyScore: 100,
              FluencyScore: 87,
              ProsodyScore: 82.6,
              CompletenessScore: 100,
              PronScore: 90.4,
            },
            Words: [
              {
                Word: "taking",
                Offset: 400000,
                Duration: 9300000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.27864522,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "tey",
                    Grapheme: "ta",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 400000,
                    Duration: 2500000,
                  },
                  {
                    Syllable: "kihng",
                    Grapheme: "king",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 3000000,
                    Duration: 6700000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "t",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 400000,
                    Duration: 1100000,
                  },
                  {
                    Phoneme: "ey",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 1600000,
                    Duration: 1300000,
                  },
                  {
                    Phoneme: "k",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 3000000,
                    Duration: 1300000,
                  },
                  {
                    Phoneme: "ih",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 4400000,
                    Duration: 1700000,
                  },
                  {
                    Phoneme: "ng",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 6200000,
                    Duration: 3500000,
                  },
                ],
              },
              {
                Word: "a",
                Offset: 12500000,
                Duration: 2000000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["UnexpectedBreak"],
                        UnexpectedBreak: {
                          Confidence: 0.58378386,
                        },
                        MissingBreak: {
                          Confidence: 0,
                        },
                        BreakLength: 2700000,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.27864522,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "ax",
                    Grapheme: "a",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 12500000,
                    Duration: 2000000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "ax",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 12500000,
                    Duration: 2000000,
                  },
                ],
              },
              {
                Word: "walk",
                Offset: 14600000,
                Duration: 6900000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["None"],
                        UnexpectedBreak: {
                          Confidence: 2.1621622e-8,
                        },
                        MissingBreak: {
                          Confidence: 1,
                        },
                        BreakLength: 0,
                      },
                      Intonation: {
                        ErrorTypes: [],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.27864522,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "waok",
                    Grapheme: "walk",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 14600000,
                    Duration: 6900000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "w",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 14600000,
                    Duration: 700000,
                  },
                  {
                    Phoneme: "ao",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 15400000,
                    Duration: 1300000,
                  },
                  {
                    Phoneme: "k",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 16800000,
                    Duration: 4700000,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        Id: "342df7e24dd5476abf01cf3a2eba697c",
        RecognitionStatus: 0,
        Offset: 31800000,
        Duration: 14800000,
        Channel: 0,
        DisplayText: "That day.",
        SNR: 18.124744,
        NBest: [
          {
            Confidence: 0.9284379,
            Lexical: "that day",
            ITN: "that day",
            MaskedITN: "that day",
            Display: "That day.",
            PronunciationAssessment: {
              AccuracyScore: 92,
              FluencyScore: 81,
              ProsodyScore: 71.7,
              CompletenessScore: 100,
              PronScore: 83.3,
            },
            Words: [
              {
                Word: "that",
                Offset: 31800000,
                Duration: 4700000,
                PronunciationAssessment: {
                  AccuracyScore: 100,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["UnexpectedBreak"],
                        UnexpectedBreak: {
                          Confidence: 1.6859504,
                        },
                        MissingBreak: {
                          Confidence: 0,
                        },
                        BreakLength: 10200000,
                      },
                      Intonation: {
                        ErrorTypes: ["Monotone"],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.24836698,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "dhaet",
                    Grapheme: "that",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 31800000,
                    Duration: 4700000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "dh",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 31800000,
                    Duration: 1500000,
                  },
                  {
                    Phoneme: "ae",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 33400000,
                    Duration: 1300000,
                  },
                  {
                    Phoneme: "t",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 34800000,
                    Duration: 1700000,
                  },
                ],
              },
              {
                Word: "day",
                Offset: 39400000,
                Duration: 7200000,
                PronunciationAssessment: {
                  AccuracyScore: 85,
                  ErrorType: "None",
                  Feedback: {
                    Prosody: {
                      Break: {
                        ErrorTypes: ["UnexpectedBreak"],
                        UnexpectedBreak: {
                          Confidence: 0.46280992,
                        },
                        MissingBreak: {
                          Confidence: 0,
                        },
                        BreakLength: 2800000,
                      },
                      Intonation: {
                        ErrorTypes: ["Monotone"],
                        Monotone: {
                          SyllablePitchDeltaConfidence: 0.24836698,
                        },
                      },
                    },
                  },
                },
                Syllables: [
                  {
                    Syllable: "dey",
                    Grapheme: "day",
                    PronunciationAssessment: {
                      AccuracyScore: 85,
                    },
                    Offset: 39400000,
                    Duration: 7200000,
                  },
                ],
                Phonemes: [
                  {
                    Phoneme: "d",
                    PronunciationAssessment: {
                      AccuracyScore: 100,
                    },
                    Offset: 39400000,
                    Duration: 1300000,
                  },
                  {
                    Phoneme: "ey",
                    PronunciationAssessment: {
                      AccuracyScore: 82,
                    },
                    Offset: 40800000,
                    Duration: 5800000,
                  },
                ],
                Punctuation: ".",
              },
            ],
          },
        ],
      },
    ],
    scriptText: "There was no possibility of taking a leisurely walk that day.",
  },
];
