const assets = {
  images: {
    logo: {
      light: require("./images/United Associates BI Logo (Small) (Logo Only) (Blue) (Original).png"),
      dark: require("./images/United Associates BI Logo (Small) (Logo Only) (White) (Original).png"),
    },
  },
};
// United_Associates_BI_Logo_(Small)_(Transparent).png
export default assets;
