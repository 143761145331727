import React, { useEffect, useRef } from "react";

const BackToBottom = () => {
  const progressRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const progressPath = progressRef.current?.querySelector("path");
    if (!progressPath) return;

    const pathLength = progressPath.getTotalLength();
    progressPath.style.transition = "none";
    progressPath.style.strokeDasharray = `${pathLength} ${pathLength}`;
    progressPath.style.strokeDashoffset = `${pathLength}`;
    progressPath.getBoundingClientRect();
    progressPath.style.transition = "stroke-dashoffset 10ms linear";

    const updateProgress = () => {
      const scroll = window.pageYOffset || document.documentElement.scrollTop;
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const progress = (scroll * pathLength) / height;
      progressPath.style.strokeDashoffset = `${pathLength - progress}`;

      const rbtProgressParent = progressRef.current;
      if (rbtProgressParent) {
        if (scroll < height - 50) {
          rbtProgressParent.classList.add("rbt-backto-bottom-active");
        } else {
          rbtProgressParent.classList.remove("rbt-backto-bottom-active");
        }
      }
    };

    updateProgress();
    window.addEventListener("scroll", updateProgress);

    progressRef.current?.addEventListener("click", (event) => {
      event.preventDefault();
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    });

    return () => {
      window.removeEventListener("scroll", updateProgress);
    };
  }, []);

  return (
    <div className="rbt-progress-parent" ref={progressRef}>
      <svg
        className="rbt-back-circle svg-inner"
        width="100%"
        height="100%"
        viewBox="-1 -1 102 102"
      >
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"></path>
      </svg>
    </div>
  );
};

export default BackToBottom;
