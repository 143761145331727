import React from "react";
type TRangeInputFormProps = {
  value: number;
  setValue: any;
  title: string;
  min: number;
  max: number;
  step: number;
  onChange: any;
};
const RangeInputForm = ({
  value,
  setValue,
  title,
  min,
  max,
  step,
  onChange,
}: TRangeInputFormProps) => {
  const handleRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange();
    setValue(Number(e.target.value));
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange();
    const numValue = Number(e.target.value);
    setValue(
      isNaN(numValue)
        ? 0
        : numValue < min
        ? min
        : numValue > max
        ? max
        : numValue
    );
  };
  return (
    <div
      className="container custom-range-with-input-container"
      style={{ width: "80%" }}
    >
      <div className="row">
        <div className="col-8" style={{ paddingBottom: 10, textAlign: "left" }}>
          <label htmlFor="customRange1" className="form-label">
            {title}
          </label>
          <input
            type="range"
            className="form-range custom-range"
            id="customRange1"
            min={min}
            max={max}
            value={value}
            step={step}
            onChange={(e) => handleRangeChange(e)}
            style={
              {
                "--value": `${(value / max) * 100}%`,
              } as React.CSSProperties
            }
          />
        </div>
        <div className="col" style={{ display: "flex", alignItems: "center" }}>
          <input
            type="number"
            className="form-number custom-number-input"
            id="customNumberInput"
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={(e) => handleNumberChange(e)}
          />
        </div>
      </div>
    </div>
  );
};
export default RangeInputForm;
