import React from "react";
import { Message } from "react-chat-ui";
import "../components/styles.css";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  CHATTING_AI_MESSAGE_ID,
  CHATTING_AI_NAME,
  CHATTING_USER_MESSAGE_ID,
  CHATTING_USER_NAME,
  JSON_FILE_NAMES,
} from "../../../shared/constants/constants";
import {
  readChatJson,
  saveChatJson,
  parseMarkdownTable,
  getChattingNameById,
  parseMarkdownText,
  getChatGPTResponseDocument,
} from "../../../shared/utils";
// import myUIConstants from "../../../shared/constants/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  ChatComponentState,
  TChatbotResponseData,
  TChatMessageElement,
  THistoryTurnOne,
  TPromptData,
} from "shared/types";
import StaticBarDashboard from "../../../components/common/forms/StaticBar/StaticBarDashboard";
import ChatList from "../../../components/common/chats/ChatList";
import DropdownButton from "../../../components/common/dropdowns/Dropdown";
import { Button } from "react-bootstrap";

const CHAT_TYPE = 0;
const pwc_document_key_arr = ["Outline_Design_Drawing_P21_12231676_02"];

async function GetAIAnswer(history: THistoryTurnOne[]): Promise<any> {
  const lastUserMsg = history[history.length - 1].message;
  const rsp = await getChatGPTResponseDocument({
    prompt_text: lastUserMsg,
  });
  console.log(`rsp: ${JSON.stringify(rsp)}`);
  return {
    output: rsp.output,
    generate_ans_time: 0,
    generate_prompt_time: 0,
    speed: 0,
  };
}

type PwcChatComponentState = ChatComponentState & {
  file_name_backup_history: string;
  isBackedAlready: boolean;
  buttonArr: any[];
  selectedDocumentKey: string;
  hallucination_analysis: string;
  hallucination_probability: number;
  reasoning: string;
  is_used_long_context_llm: boolean;
} & {
  page_index: number;
};

class PwcRagDocumentComponent extends React.Component<
  {},
  PwcChatComponentState
> {
  constructor() {
    super({});
    this.state = {
      messages: [],
      history: [],
      selected: 4,
      prompt: "",
      chat_type: CHAT_TYPE,
      file_name_chat: JSON_FILE_NAMES.testPwcRagDocumentChat,
      file_name_prompt: "",
      file_name_backup_history: "",
      speed: -1,
      generate_ans_time: -1,
      generate_prompt_time: -1,
      isBackedAlready: false,
      buttonArr: [],
      input_message: "",
      selectedDocumentKey: pwc_document_key_arr[0],
      //hallucination analysis
      hallucination_analysis: "",
      hallucination_probability: 0,
      reasoning: "",
      // page control
      page_index: 1,
      is_used_long_context_llm: false,
    };
  }

  async componentDidMount() {
    const { history, prompt } = await readChatJson(this.state.file_name_chat);
    console.log(JSON.stringify(history));
    this.populateChatWindow(history, prompt);
    let document_key: string = pwc_document_key_arr[0];
    if (sessionStorage.getItem("document_key"))
      document_key = sessionStorage.getItem("document_key") as string;
    this.setState({
      selectedDocumentKey: document_key,
    });
  }

  populateChatWindow(history: THistoryTurnOne[], prompt: string) {
    const messageElements: TChatMessageElement[] = history.map(
      (historyTurn: THistoryTurnOne) => {
        return {
          id: historyTurn.id,
          message: (
            <div style={{ color: "black", margin: 0 }}>
              <ReactMarkdown className="nomargin" remarkPlugins={[remarkGfm]}>
                {parseMarkdownText(parseMarkdownTable(historyTurn.message))}
              </ReactMarkdown>
            </div>
          ),
          senderName: getChattingNameById(historyTurn.id),
        };
      }
    );
    this.setState({ messages: messageElements });
    this.setState({ history });
    this.setState({ prompt });
  }

  async onMessageSubmit(e: any) {
    e.preventDefault();
    const text = this.state.input_message;
    this.pushUserMessage(text);
    this.setState({ input_message: "" });
    const { output } = await GetAIAnswer(this.state.history);
    // const answerWithHallucination = `${output}\n> 1. **Hallucination Analysis**: ${hallucination_analysis}\n> 2. **Probability of Hallucination**: ${hallucination_probability}%\n> 3. **Reasoning**: ${reasoning}`;
    this.pushEllaMessage(output, "", "");
  }

  pushUserMessage(message: string) {
    const prevState = this.state;
    const newMessage = new Message({
      id: CHATTING_USER_MESSAGE_ID,
      message,
      senderName: CHATTING_USER_NAME,
    });
    prevState.history.push({ message, id: CHATTING_USER_MESSAGE_ID });
    prevState.messages.push(newMessage);
    this.setState(this.state);
  }

  pushEllaMessage(message: string, prompt: string, data: any) {
    const prevState = this.state;
    const messageElement = (
      <div style={{ color: "black", margin: 0 }}>
        <ReactMarkdown className="nomargin" remarkPlugins={[remarkGfm]}>
          {parseMarkdownTable(message)}
        </ReactMarkdown>
        {data ? (
          <div className="hallucination-info">
            <hr style={{ borderTop: "1px solid" }}></hr>
            <ReactMarkdown className="nomargin" remarkPlugins={[remarkGfm]}>
              {parseMarkdownTable(data)}
            </ReactMarkdown>
          </div>
        ) : null}
      </div>
    );
    const ellaMessage = new Message({
      id: CHATTING_AI_MESSAGE_ID,
      message: messageElement as any,
      senderName: CHATTING_AI_NAME,
    });
    prevState.messages.push(ellaMessage);
    prevState.history.push({ message, id: CHATTING_AI_MESSAGE_ID, data });
    this.setState({ prompt, isBackedAlready: false });
    saveChatJson(this.state.history, prompt, this.state.file_name_chat);
  }

  onClearHistory(e: any) {
    e.preventDefault();
    this.setState({ messages: [] });
    this.setState({ history: [] });
    saveChatJson(
      this.state.history,
      this.state.prompt,
      this.state.file_name_chat
    );
  }

  onApplyBackupToChatWindow(
    e: any,
    history: THistoryTurnOne[],
    prompt: string
  ) {
    e.preventDefault();
    this.populateChatWindow(history, prompt);
  }

  onDocumentChange(event: any) {
    event.preventDefault();
    this.setState({ selectedDocumentKey: event.target.value });
    sessionStorage.setItem("document_key", event.target.value);
  }
  onDocumentChangeNew(value: string) {
    this.setState({ selectedDocumentKey: value });
    sessionStorage.setItem("document_key", value);
  }

  // page control
  onClickTab(index: number) {
    this.setState({ page_index: index });
  }

  render() {
    return (
      <div className="chat-box-section " style={{ minWidth: 300 }}>
        <div className="single-settings-box profile-details-box overflow-hidden">
          <h3
            className="title pt-4"
            style={{
              textAlign: "center",
              marginBottom: 50,
            }}
          >
            PwC Design Interpretation
          </h3>
          <div className="profile-details-tab">
            <div className="advance-tab-button mb--30">
              <ul
                className="nav nav-tabs tab-button-style-2 justify-content-start"
                id="settinsTab-4"
                role="tablist"
              >
                <li role="presentation">
                  <a
                    href="#"
                    className={`tab-button ${
                      this.state.page_index === 1 && "active"
                    }`}
                    data-bs-toggle="tab"
                    role="tab"
                    aria-selected={this.state.page_index === 1}
                    onClick={() => this.onClickTab(1)}
                  >
                    <span className="title">Generate</span>
                  </a>
                </li>
                <li role="presentation">
                  {" "}
                  <a
                    href="#"
                    className={`tab-button ${
                      this.state.page_index === 2 && "active"
                    }`}
                    data-bs-toggle="tab"
                    role="tab"
                    aria-selected={this.state.page_index === 2}
                    onClick={() => this.onClickTab(2)}
                  >
                    <span className="title">Document Info</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="tab-content">
          <div
            className={`tab-pane fade ${
              this.state.page_index === 1 && "active show"
            }`}
            role="tabpanel"
          >
            <ChatList
              message={this.state.messages}
              aiName={"Ella"}
              userName={"You"}
            />
            <StaticBarDashboard
              onSubmit={(e) => this.onMessageSubmit(e)}
              onChangeTextarea={(e) => {
                this.setState({ input_message: e.target.value });
              }}
              textareaValue={this.state.input_message}
              onClearHistory={(e) => this.onClearHistory(e)}
            />
          </div>

          <div
            className={`tab-pane fade ${
              this.state.page_index === 2 && "active show"
            }`}
            role="tabpanel"
          >
            <div className="container" style={{ marginTop: 0 }}>
              <div style={{ marginLeft: 30, color: "grey" }}>
                <span>Document Download: </span>
                <Button
                  onClick={() => {
                    const link = document.createElement("a");
                    link.href =
                      "/test/Outline_Design_Drawing_P21_12231676_02.pdf"; // Path to your file in the public folder
                    link.download =
                      "test/Outline_Design_Drawing_P21_12231676_02.pdf"; // File name for download
                    link.click();
                  }}
                  variant="contained"
                  color="primary"
                >
                  Outline_Design_Drawing_P21_12231676_02
                </Button>
              </div>
            </div>
          </div>
        </div>
        <br></br>
      </div>
    );
  }
}

export default PwcRagDocumentComponent;
