import ChainOfThoughtChatComponent from "./ChainOfThoughtChatComponent";
import React from "react";

export default function ChainOfThoughtChat() {
  return (
    <div className="App">
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          margin: 0,
        }}
      >
        <h2> Chain Of Thought Chat </h2>
      </div>
      <ChainOfThoughtChatComponent />
    </div>
  );
}
