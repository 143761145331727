import React from "react";
import ImageGenerationChatComponent from "./ImageGenerationChatComponent";

export default function ImageGenerationChat() {
  return (
    <div className="App">
      <ImageGenerationChatComponent />
    </div>
  );
}
