
export const sampleTopicData:TSpeechAnalysisData = [
  {
      "Id": "78f884b0a5d24d179d3703dfe8d36390",
      "RecognitionStatus": 0,
      "Offset": 400000,
      "Duration": 22500000,
      "Channel": 0,
      "DisplayText": "Today was a burst of adventures.",
      "SNR": 41.04058,
      "NBest": [
          {
              "Confidence": 0.92669487,
              "Lexical": "today was a burst of adventures",
              "ITN": "today was a burst of adventures",
              "MaskedITN": "today was a burst of adventures",
              "Display": "Today was a burst of adventures.",
              "PronunciationAssessment": {
                  "AccuracyScore": 89,
                  "FluencyScore": 100,
                  "ProsodyScore": 90.9,
                  "CompletenessScore": 100,
                  "PronScore": 91.6
              },
              "Words": [
                  {
                      "Word": "today",
                      "Offset": 400000,
                      "Duration": 5100000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 91,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.75386196
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "tə",
                              "Grapheme": "to",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 88
                              },
                              "Offset": 400000,
                              "Duration": 2100000
                          },
                          {
                              "Syllable": "deɪ",
                              "Grapheme": "day",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 93
                              },
                              "Offset": 2600000,
                              "Duration": 2900000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "t",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 82,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "t",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 14
                                      },
                                      {
                                          "Phoneme": "tʃ",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "k",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "oʊ",
                                          "Score": 2
                                      }
                                  ]
                              },
                              "Offset": 400000,
                              "Duration": 1400000
                          },
                          {
                              "Phoneme": "ə",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ə",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 65
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 5
                                      },
                                      {
                                          "Phoneme": "ʊ",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 1900000,
                              "Duration": 600000
                          },
                          {
                              "Phoneme": "d",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "d",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "eɪ",
                                          "Score": 51
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "dʒ",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 2600000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "eɪ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 89,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "eɪ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "i",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 3600000,
                              "Duration": 1900000
                          }
                      ]
                  },
                  {
                      "Word": "was",
                      "Offset": 5600000,
                      "Duration": 2300000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 100,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.982301e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.75386196
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "wʌz",
                              "Grapheme": "was",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100
                              },
                              "Offset": 5600000,
                              "Duration": 2300000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "w",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "w",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "eɪ",
                                          "Score": 65
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 7
                                      },
                                      {
                                          "Phoneme": "i",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 4
                                      }
                                  ]
                              },
                              "Offset": 5600000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "ʌ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ʌ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "w",
                                          "Score": 36
                                      },
                                      {
                                          "Phoneme": "z",
                                          "Score": 17
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 6
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 6400000,
                              "Duration": 800000
                          },
                          {
                              "Phoneme": "z",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "z",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 74
                                      },
                                      {
                                          "Phoneme": "ʌ",
                                          "Score": 68
                                      },
                                      {
                                          "Phoneme": "ð",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "s",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 7300000,
                              "Duration": 600000
                          }
                      ]
                  },
                  {
                      "Word": "a",
                      "Offset": 8000000,
                      "Duration": 700000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 94,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.982301e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.75386196
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "ə",
                              "Grapheme": "a",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 94
                              },
                              "Offset": 8000000,
                              "Duration": 700000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "ə",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 94,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ə",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "b",
                                          "Score": 10
                                      },
                                      {
                                          "Phoneme": "ʌ",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 8000000,
                              "Duration": 700000
                          }
                      ]
                  },
                  {
                      "Word": "burst",
                      "Offset": 8800000,
                      "Duration": 4100000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 83,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.982301e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.75386196
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "bɝrst",
                              "Grapheme": "burst",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 83
                              },
                              "Offset": 8800000,
                              "Duration": 4100000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "b",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 86,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "b",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ɝ",
                                          "Score": 5
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "p",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 8800000,
                              "Duration": 1000000
                          },
                          {
                              "Phoneme": "ɝ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ɝ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "oʊ",
                                          "Score": 95
                                      },
                                      {
                                          "Phoneme": "b",
                                          "Score": 78
                                      },
                                      {
                                          "Phoneme": "ʌ",
                                          "Score": 23
                                      },
                                      {
                                          "Phoneme": "ʊ",
                                          "Score": 18
                                      }
                                  ]
                              },
                              "Offset": 9900000,
                              "Duration": 600000
                          },
                          {
                              "Phoneme": "r",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "r",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "oʊ",
                                          "Score": 80
                                      },
                                      {
                                          "Phoneme": "s",
                                          "Score": 69
                                      },
                                      {
                                          "Phoneme": "ɝ",
                                          "Score": 20
                                      },
                                      {
                                          "Phoneme": "ʊ",
                                          "Score": 14
                                      }
                                  ]
                              },
                              "Offset": 10600000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "s",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 96,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "s",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 28
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 6
                                      },
                                      {
                                          "Phoneme": "z",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "θ",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 11600000,
                              "Duration": 500000
                          },
                          {
                              "Phoneme": "t",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 34,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "t",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "s",
                                          "Score": 41
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "tʃ",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 12200000,
                              "Duration": 700000
                          }
                      ]
                  },
                  {
                      "Word": "of",
                      "Offset": 13000000,
                      "Duration": 1000000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 73,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.982301e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.75386196
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "əv",
                              "Grapheme": "of",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 73
                              },
                              "Offset": 13000000,
                              "Duration": 1000000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "ə",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ə",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "v",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 13000000,
                              "Duration": 500000
                          },
                          {
                              "Phoneme": "v",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 40,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "v",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 52
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "f",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 13600000,
                              "Duration": 400000
                          }
                      ]
                  },
                  {
                      "Word": "adventures",
                      "Offset": 14100000,
                      "Duration": 8800000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 94,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.982301e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.75386196
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "əd",
                              "Grapheme": "ad",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 58
                              },
                              "Offset": 14100000,
                              "Duration": 1100000
                          },
                          {
                              "Syllable": "vɛn",
                              "Grapheme": "ven",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100
                              },
                              "Offset": 15300000,
                              "Duration": 2400000
                          },
                          {
                              "Syllable": "tʃərz",
                              "Grapheme": "tures",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100
                              },
                              "Offset": 17800000,
                              "Duration": 5100000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "ə",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 0,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ə",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "v",
                                          "Score": 70
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 47
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 20
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 14100000,
                              "Duration": 400000
                          },
                          {
                              "Phoneme": "d",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "d",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 84
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 67
                                      },
                                      {
                                          "Phoneme": "v",
                                          "Score": 48
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 7
                                      }
                                  ]
                              },
                              "Offset": 14600000,
                              "Duration": 600000
                          },
                          {
                              "Phoneme": "v",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "v",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 52
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 15300000,
                              "Duration": 600000
                          },
                          {
                              "Phoneme": "ɛ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 47
                                      },
                                      {
                                          "Phoneme": "v",
                                          "Score": 5
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 16000000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "n",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "n",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "tʃ",
                                          "Score": 11
                                      },
                                      {
                                          "Phoneme": "ʃ",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 16800000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "tʃ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "tʃ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ʃ",
                                          "Score": 50
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 41
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "dʒ",
                                          "Score": 2
                                      }
                                  ]
                              },
                              "Offset": 17800000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "ə",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ə",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 61
                                      },
                                      {
                                          "Phoneme": "ɝ",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "ʊ",
                                          "Score": 2
                                      }
                                  ]
                              },
                              "Offset": 18800000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "r",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "r",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 87
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 14
                                      },
                                      {
                                          "Phoneme": "z",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 3
                                      }
                                  ]
                              },
                              "Offset": 19600000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "z",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "z",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "s",
                                          "Score": 76
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 27
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 8
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 6
                                      }
                                  ]
                              },
                              "Offset": 20400000,
                              "Duration": 2500000
                          }
                      ],
                      "Punctuation": "."
                  }
              ]
          }
      ]
  },
  {
      "Id": "d524e1b77f8d4424a696c1e8a46bae08",
      "RecognitionStatus": 0,
      "Offset": 30400000,
      "Duration": 178100000,
      "Channel": 0,
      "DisplayText": "I am wake up bright and early to help my dad make pancakes. Later we set them on a nature hike. We spotted some fascinating birds. After that I joined my friends for a lively virtual dance class and we had an absolute blast, grooving and laughing together.",
      "SNR": 31.922112,
      "NBest": [
          {
              "Confidence": 0.9269963,
              "Lexical": "i am wake up bright and early to help my dad make pancakes later we set them on a nature hike we spotted some fascinating birds after that i joined my friends for a lively virtual dance class and we had an absolute blast grooving and laughing together",
              "ITN": "i am wake up bright and early to help my dad make pancakes later we set them on a nature hike we spotted some fascinating birds after that i joined my friends for a lively virtual dance class and we had an absolute blast grooving and laughing together",
              "MaskedITN": "i am wake up bright and early to help my dad make pancakes later we set them on a nature hike we spotted some fascinating birds after that i joined my friends for a lively virtual dance class and we had an absolute blast grooving and laughing together",
              "Display": "I am wake up bright and early to help my dad make pancakes. Later we set them on a nature hike. We spotted some fascinating birds. After that I joined my friends for a lively virtual dance class and we had an absolute blast, grooving and laughing together.",
              "PronunciationAssessment": {
                  "AccuracyScore": 87,
                  "FluencyScore": 98,
                  "ProsodyScore": 84.6,
                  "CompletenessScore": 100,
                  "PronScore": 87.8
              },
              "Words": [
                  {
                      "Word": "i",
                      "Offset": 30400000,
                      "Duration": 2700000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 100,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 2.797251
                                      },
                                      "MissingBreak": {
                                          "Confidence": 0
                                      },
                                      "BreakLength": 7400000
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "aɪ",
                              "Grapheme": "i",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100
                              },
                              "Offset": 30400000,
                              "Duration": 2700000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "aɪ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "aɪ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "h",
                                          "Score": 74
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 21
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 13
                                      },
                                      {
                                          "Phoneme": "ɑ",
                                          "Score": 8
                                      }
                                  ]
                              },
                              "Offset": 30400000,
                              "Duration": 2700000
                          }
                      ]
                  },
                  {
                      "Word": "am",
                      "Offset": 33200000,
                      "Duration": 5300000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 88,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "æm",
                              "Grapheme": "am",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 88
                              },
                              "Offset": 33200000,
                              "Duration": 5300000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "æ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "æ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "aɪ",
                                          "Score": 77
                                      },
                                      {
                                          "Phoneme": "m",
                                          "Score": 49
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 44
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 44
                                      }
                                  ]
                              },
                              "Offset": 33200000,
                              "Duration": 2100000
                          },
                          {
                              "Phoneme": "m",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 80,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "m",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 51
                                      },
                                      {
                                          "Phoneme": "oʊ",
                                          "Score": 30
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 22
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 21
                                      }
                                  ]
                              },
                              "Offset": 35400000,
                              "Duration": 3100000
                          }
                      ]
                  },
                  {
                      "Word": "wake",
                      "Offset": 39400000,
                      "Duration": 3500000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 77,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "UnexpectedBreak"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 0.30240554
                                      },
                                      "MissingBreak": {
                                          "Confidence": 0
                                      },
                                      "BreakLength": 800000
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "weɪk",
                              "Grapheme": "wake",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 77
                              },
                              "Offset": 39400000,
                              "Duration": 3500000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "w",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 61,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "w",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 35
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 26
                                      },
                                      {
                                          "Phoneme": "b",
                                          "Score": 23
                                      },
                                      {
                                          "Phoneme": "oʊ",
                                          "Score": 13
                                      }
                                  ]
                              },
                              "Offset": 39400000,
                              "Duration": 1500000
                          },
                          {
                              "Phoneme": "eɪ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 78,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "eɪ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "w",
                                          "Score": 41
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 6
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "i",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 41000000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "k",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "k",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "eɪ",
                                          "Score": 11
                                      },
                                      {
                                          "Phoneme": "ɡ",
                                          "Score": 11
                                      },
                                      {
                                          "Phoneme": "ʌ",
                                          "Score": 10
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 2
                                      }
                                  ]
                              },
                              "Offset": 42000000,
                              "Duration": 900000
                          }
                      ]
                  },
                  {
                      "Word": "up",
                      "Offset": 43000000,
                      "Duration": 1700000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 62,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "ʌp",
                              "Grapheme": "up",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 62
                              },
                              "Offset": 43000000,
                              "Duration": 1700000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "ʌ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ʌ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "p",
                                          "Score": 7
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "k",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 43000000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "p",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 14,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "p",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "b",
                                          "Score": 49
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "k",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "f",
                                          "Score": 2
                                      }
                                  ]
                              },
                              "Offset": 44000000,
                              "Duration": 700000
                          }
                      ]
                  },
                  {
                      "Word": "bright",
                      "Offset": 44800000,
                      "Duration": 3500000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 100,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "braɪt",
                              "Grapheme": "bright",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100
                              },
                              "Offset": 44800000,
                              "Duration": 3500000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "b",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 98,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "b",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "p",
                                          "Score": 35
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 7
                                      },
                                      {
                                          "Phoneme": "k",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 44800000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "r",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "r",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "b",
                                          "Score": 28
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 13
                                      },
                                      {
                                          "Phoneme": "aɪ",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "w",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 45600000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "aɪ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "aɪ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 43
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 33
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 7
                                      },
                                      {
                                          "Phoneme": "eɪ",
                                          "Score": 4
                                      }
                                  ]
                              },
                              "Offset": 46400000,
                              "Duration": 1300000
                          },
                          {
                              "Phoneme": "t",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "t",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 57
                                      },
                                      {
                                          "Phoneme": "k",
                                          "Score": 7
                                      },
                                      {
                                          "Phoneme": "θ",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 3
                                      }
                                  ]
                              },
                              "Offset": 47800000,
                              "Duration": 500000
                          }
                      ]
                  },
                  {
                      "Word": "and",
                      "Offset": 48600000,
                      "Duration": 1400000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 77,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 0.075601414
                                      },
                                      "MissingBreak": {
                                          "Confidence": 0.9621993
                                      },
                                      "BreakLength": 200000
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "ən",
                              "Grapheme": "and",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 77
                              },
                              "Offset": 48600000,
                              "Duration": 1400000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "ə",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 56,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ə",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 68
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 19
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 6
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 2
                                      }
                                  ]
                              },
                              "Offset": 48600000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "n",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "n",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ɝ",
                                          "Score": 52
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 38
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 5
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 3
                                      }
                                  ]
                              },
                              "Offset": 49400000,
                              "Duration": 600000
                          }
                      ]
                  },
                  {
                      "Word": "early",
                      "Offset": 50100000,
                      "Duration": 4200000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 94,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "ɝr",
                              "Grapheme": "ear",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100
                              },
                              "Offset": 50100000,
                              "Duration": 2000000
                          },
                          {
                              "Syllable": "li",
                              "Grapheme": "ly",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 89
                              },
                              "Offset": 52200000,
                              "Duration": 2100000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "ɝ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ɝ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 24
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 13
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 50100000,
                              "Duration": 1400000
                          },
                          {
                              "Phoneme": "r",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "r",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ɝ",
                                          "Score": 78
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 6
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 3
                                      }
                                  ]
                              },
                              "Offset": 51600000,
                              "Duration": 500000
                          },
                          {
                              "Phoneme": "l",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "l",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 45
                                      },
                                      {
                                          "Phoneme": "i",
                                          "Score": 23
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 2
                                      }
                                  ]
                              },
                              "Offset": 52200000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "i",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 80,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "i",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 30
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 25
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 22
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 10
                                      }
                                  ]
                              },
                              "Offset": 53200000,
                              "Duration": 1100000
                          }
                      ]
                  },
                  {
                      "Word": "to",
                      "Offset": 54400000,
                      "Duration": 1700000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 100,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "tə",
                              "Grapheme": "to",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100
                              },
                              "Offset": 54400000,
                              "Duration": 1700000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "t",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "t",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 7
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "ʊ",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "i",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 54400000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "ə",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ə",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 28
                                      },
                                      {
                                          "Phoneme": "h",
                                          "Score": 6
                                      },
                                      {
                                          "Phoneme": "ʊ",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "oʊ",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 55200000,
                              "Duration": 900000
                          }
                      ]
                  },
                  {
                      "Word": "help",
                      "Offset": 56200000,
                      "Duration": 2500000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 91,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "hɛlp",
                              "Grapheme": "help",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 91
                              },
                              "Offset": 56200000,
                              "Duration": 2500000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "h",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 95,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "h",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 6
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "v",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 56200000,
                              "Duration": 500000
                          },
                          {
                              "Phoneme": "ɛ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 25
                                      },
                                      {
                                          "Phoneme": "h",
                                          "Score": 17
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "aʊ",
                                          "Score": 3
                                      }
                                  ]
                              },
                              "Offset": 56800000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "l",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "l",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "p",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "aʊ",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "oʊ",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 57600000,
                              "Duration": 500000
                          },
                          {
                              "Phoneme": "p",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 68,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "p",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 28
                                      },
                                      {
                                          "Phoneme": "k",
                                          "Score": 14
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 13
                                      },
                                      {
                                          "Phoneme": "m",
                                          "Score": 9
                                      }
                                  ]
                              },
                              "Offset": 58200000,
                              "Duration": 500000
                          }
                      ]
                  },
                  {
                      "Word": "my",
                      "Offset": 58800000,
                      "Duration": 1500000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 94,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "maɪ",
                              "Grapheme": "my",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 94
                              },
                              "Offset": 58800000,
                              "Duration": 1500000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "m",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 89,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "m",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "p",
                                          "Score": 41
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 5
                                      },
                                      {
                                          "Phoneme": "k",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 58800000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "aɪ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "aɪ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "m",
                                          "Score": 68
                                      },
                                      {
                                          "Phoneme": "ʌ",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "ɑ",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 59600000,
                              "Duration": 700000
                          }
                      ]
                  },
                  {
                      "Word": "dad",
                      "Offset": 60400000,
                      "Duration": 4100000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 86,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "dæd",
                              "Grapheme": "dad",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 86
                              },
                              "Offset": 60400000,
                              "Duration": 4100000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "d",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "d",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "aɪ",
                                          "Score": 28
                                      },
                                      {
                                          "Phoneme": "dʒ",
                                          "Score": 27
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "ɡ",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 60400000,
                              "Duration": 1100000
                          },
                          {
                              "Phoneme": "æ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "æ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 37
                                      },
                                      {
                                          "Phoneme": "ɑ",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "dʒ",
                                          "Score": 2
                                      }
                                  ]
                              },
                              "Offset": 61600000,
                              "Duration": 1900000
                          },
                          {
                              "Phoneme": "d",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 40,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "d",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "m",
                                          "Score": 48
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 6
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 5
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 5
                                      }
                                  ]
                              },
                              "Offset": 63600000,
                              "Duration": 900000
                          }
                      ]
                  },
                  {
                      "Word": "make",
                      "Offset": 64600000,
                      "Duration": 2700000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 82,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "meɪk",
                              "Grapheme": "make",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 82
                              },
                              "Offset": 64600000,
                              "Duration": 2700000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "m",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 90,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "m",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 12
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "eɪ",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 64600000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "eɪ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "eɪ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 56
                                      },
                                      {
                                          "Phoneme": "k",
                                          "Score": 32
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 9
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 6
                                      }
                                  ]
                              },
                              "Offset": 65400000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "k",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 59,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "k",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "p",
                                          "Score": 75
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 27
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 21
                                      },
                                      {
                                          "Phoneme": "ɡ",
                                          "Score": 14
                                      }
                                  ]
                              },
                              "Offset": 66400000,
                              "Duration": 900000
                          }
                      ]
                  },
                  {
                      "Word": "pancakes",
                      "Offset": 67400000,
                      "Duration": 8800000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 70,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "pæn",
                              "Grapheme": "pan",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 23
                              },
                              "Offset": 67400000,
                              "Duration": 2300000
                          },
                          {
                              "Syllable": "keɪks",
                              "Grapheme": "cakes",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 88
                              },
                              "Offset": 69800000,
                              "Duration": 6400000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "p",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 11,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "p",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 8
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 6
                                      },
                                      {
                                          "Phoneme": "k",
                                          "Score": 5
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 4
                                      }
                                  ]
                              },
                              "Offset": 67400000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "æ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 13,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "p",
                                          "Score": 50
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 37
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 19
                                      },
                                      {
                                          "Phoneme": "i",
                                          "Score": 13
                                      }
                                  ]
                              },
                              "Offset": 68200000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "n",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 44,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "m",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 79
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 67
                                      },
                                      {
                                          "Phoneme": "i",
                                          "Score": 13
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 9
                                      }
                                  ]
                              },
                              "Offset": 69000000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "k",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "k",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 70
                                      },
                                      {
                                          "Phoneme": "m",
                                          "Score": 65
                                      },
                                      {
                                          "Phoneme": "eɪ",
                                          "Score": 23
                                      },
                                      {
                                          "Phoneme": "p",
                                          "Score": 20
                                      }
                                  ]
                              },
                              "Offset": 69800000,
                              "Duration": 1100000
                          },
                          {
                              "Phoneme": "eɪ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "eɪ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "k",
                                          "Score": 44
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 26
                                      },
                                      {
                                          "Phoneme": "i",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 2
                                      }
                                  ]
                              },
                              "Offset": 71000000,
                              "Duration": 1300000
                          },
                          {
                              "Phoneme": "k",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "k",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 21
                                      },
                                      {
                                          "Phoneme": "z",
                                          "Score": 16
                                      },
                                      {
                                          "Phoneme": "s",
                                          "Score": 13
                                      },
                                      {
                                          "Phoneme": "ɡ",
                                          "Score": 10
                                      }
                                  ]
                              },
                              "Offset": 72400000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "s",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 72,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "s",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "z",
                                          "Score": 88
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 23
                                      },
                                      {
                                          "Phoneme": "oʊ",
                                          "Score": 13
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 12
                                      }
                                  ]
                              },
                              "Offset": 73400000,
                              "Duration": 2800000
                          }
                      ],
                      "Punctuation": "."
                  },
                  {
                      "Word": "later",
                      "Offset": 76500000,
                      "Duration": 4400000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 75,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "MissingBreak"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 0.075601414
                                      },
                                      "MissingBreak": {
                                          "Confidence": 0.9621993
                                      },
                                      "BreakLength": 200000
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "leɪ",
                              "Grapheme": "lat",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 83
                              },
                              "Offset": 76500000,
                              "Duration": 2200000
                          },
                          {
                              "Syllable": "tər",
                              "Grapheme": "er",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 67
                              },
                              "Offset": 78800000,
                              "Duration": 2100000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "l",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 77,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "l",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "eɪ",
                                          "Score": 37
                                      },
                                      {
                                          "Phoneme": "s",
                                          "Score": 25
                                      },
                                      {
                                          "Phoneme": "z",
                                          "Score": 8
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 3
                                      }
                                  ]
                              },
                              "Offset": 76500000,
                              "Duration": 1600000
                          },
                          {
                              "Phoneme": "eɪ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "eɪ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 7
                                      },
                                      {
                                          "Phoneme": "aɪ",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 2
                                      }
                                  ]
                              },
                              "Offset": 78200000,
                              "Duration": 500000
                          },
                          {
                              "Phoneme": "t",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "t",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "eɪ",
                                          "Score": 47
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 10
                                      },
                                      {
                                          "Phoneme": "h",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 78800000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "ə",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 86,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ə",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 33
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 10
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "ɝ",
                                          "Score": 4
                                      }
                                  ]
                              },
                              "Offset": 79600000,
                              "Duration": 500000
                          },
                          {
                              "Phoneme": "r",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 20,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "r",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "w",
                                          "Score": 36
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 23
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 2
                                      }
                                  ]
                              },
                              "Offset": 80200000,
                              "Duration": 700000
                          }
                      ]
                  },
                  {
                      "Word": "we",
                      "Offset": 81000000,
                      "Duration": 1300000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 93,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "wi",
                              "Grapheme": "we",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 93
                              },
                              "Offset": 81000000,
                              "Duration": 1300000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "w",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "w",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 87
                                      },
                                      {
                                          "Phoneme": "i",
                                          "Score": 39
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 2
                                      }
                                  ]
                              },
                              "Offset": 81000000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "i",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 84,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "i",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 9
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "w",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "u",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 81800000,
                              "Duration": 500000
                          }
                      ]
                  },
                  {
                      "Word": "set",
                      "Offset": 82400000,
                      "Duration": 3300000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 87,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "sɛt",
                              "Grapheme": "set",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 87
                              },
                              "Offset": 82400000,
                              "Duration": 3300000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "s",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 99,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "s",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "i",
                                          "Score": 47
                                      },
                                      {
                                          "Phoneme": "ʃ",
                                          "Score": 36
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 12
                                      },
                                      {
                                          "Phoneme": "z",
                                          "Score": 6
                                      }
                                  ]
                              },
                              "Offset": 82400000,
                              "Duration": 1500000
                          },
                          {
                              "Phoneme": "ɛ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 99,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 70
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 42
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 37
                                      },
                                      {
                                          "Phoneme": "eɪ",
                                          "Score": 13
                                      }
                                  ]
                              },
                              "Offset": 84000000,
                              "Duration": 1300000
                          },
                          {
                              "Phoneme": "t",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 0,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "t",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "k",
                                          "Score": 68
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 44
                                      },
                                      {
                                          "Phoneme": "θ",
                                          "Score": 13
                                      },
                                      {
                                          "Phoneme": "ɡ",
                                          "Score": 7
                                      }
                                  ]
                              },
                              "Offset": 85400000,
                              "Duration": 300000
                          }
                      ]
                  },
                  {
                      "Word": "them",
                      "Offset": 85800000,
                      "Duration": 4700000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 79,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "ðəm",
                              "Grapheme": "them",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 79
                              },
                              "Offset": 85800000,
                              "Duration": 4700000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "ð",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 71,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "t",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "θ",
                                          "Score": 76
                                      },
                                      {
                                          "Phoneme": "ð",
                                          "Score": 75
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 65
                                      },
                                      {
                                          "Phoneme": "k",
                                          "Score": 56
                                      }
                                  ]
                              },
                              "Offset": 85800000,
                              "Duration": 500000
                          },
                          {
                              "Phoneme": "ə",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 85,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ʌ",
                                          "Score": 95
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 91
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 88
                                      },
                                      {
                                          "Phoneme": "ɑ",
                                          "Score": 48
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 33
                                      }
                                  ]
                              },
                              "Offset": 86400000,
                              "Duration": 1100000
                          },
                          {
                              "Phoneme": "m",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 78,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "m",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 71
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 37
                                      },
                                      {
                                          "Phoneme": "oʊ",
                                          "Score": 23
                                      },
                                      {
                                          "Phoneme": "ʌ",
                                          "Score": 23
                                      }
                                  ]
                              },
                              "Offset": 87600000,
                              "Duration": 2900000
                          }
                      ]
                  },
                  {
                      "Word": "on",
                      "Offset": 91600000,
                      "Duration": 2100000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 87,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "UnexpectedBreak"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 0.3780069
                                      },
                                      "MissingBreak": {
                                          "Confidence": 0
                                      },
                                      "BreakLength": 1000000
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "ɑn",
                              "Grapheme": "on",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 87
                              },
                              "Offset": 91600000,
                              "Duration": 2100000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "ɑ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ɑ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ʌ",
                                          "Score": 38
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "aɪ",
                                          "Score": 2
                                      }
                                  ]
                              },
                              "Offset": 91600000,
                              "Duration": 1500000
                          },
                          {
                              "Phoneme": "n",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 53,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "n",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ɑ",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "m",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 2
                                      }
                                  ]
                              },
                              "Offset": 93200000,
                              "Duration": 500000
                          }
                      ]
                  },
                  {
                      "Word": "a",
                      "Offset": 93800000,
                      "Duration": 900000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 95,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "ə",
                              "Grapheme": "a",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 95
                              },
                              "Offset": 93800000,
                              "Duration": 900000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "ə",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 95,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ə",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 51
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 13
                                      },
                                      {
                                          "Phoneme": "ð",
                                          "Score": 9
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 5
                                      }
                                  ]
                              },
                              "Offset": 93800000,
                              "Duration": 900000
                          }
                      ]
                  },
                  {
                      "Word": "nature",
                      "Offset": 94800000,
                      "Duration": 4300000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 88,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "neɪ",
                              "Grapheme": "na",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 98
                              },
                              "Offset": 94800000,
                              "Duration": 1900000
                          },
                          {
                              "Syllable": "tʃər",
                              "Grapheme": "ture",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 79
                              },
                              "Offset": 96800000,
                              "Duration": 2300000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "n",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 99,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "n",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "m",
                                          "Score": 11
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 7
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 6
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 3
                                      }
                                  ]
                              },
                              "Offset": 94800000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "eɪ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 97,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "eɪ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 58
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 57
                                      },
                                      {
                                          "Phoneme": "tʃ",
                                          "Score": 15
                                      },
                                      {
                                          "Phoneme": "i",
                                          "Score": 4
                                      }
                                  ]
                              },
                              "Offset": 95600000,
                              "Duration": 1100000
                          },
                          {
                              "Phoneme": "tʃ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "tʃ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 51
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 32
                                      },
                                      {
                                          "Phoneme": "eɪ",
                                          "Score": 5
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 2
                                      }
                                  ]
                              },
                              "Offset": 96800000,
                              "Duration": 1300000
                          },
                          {
                              "Phoneme": "ə",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ə",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 65
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "ɝ",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "ʊ",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 98200000,
                              "Duration": 400000
                          },
                          {
                              "Phoneme": "r",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 0,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "r",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 59
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "ɝ",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "h",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 98700000,
                              "Duration": 400000
                          }
                      ]
                  },
                  {
                      "Word": "hike",
                      "Offset": 99200000,
                      "Duration": 3300000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 80,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "haɪk",
                              "Grapheme": "hike",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 80
                              },
                              "Offset": 99200000,
                              "Duration": 3300000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "h",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 86,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "h",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 36
                                      },
                                      {
                                          "Phoneme": "aɪ",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 99200000,
                              "Duration": 1100000
                          },
                          {
                              "Phoneme": "aɪ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "aɪ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "h",
                                          "Score": 40
                                      },
                                      {
                                          "Phoneme": "i",
                                          "Score": 7
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 2
                                      }
                                  ]
                              },
                              "Offset": 100400000,
                              "Duration": 1100000
                          },
                          {
                              "Phoneme": "k",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 50,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "k",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "i",
                                          "Score": 13
                                      },
                                      {
                                          "Phoneme": "aɪ",
                                          "Score": 9
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 5
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 3
                                      }
                                  ]
                              },
                              "Offset": 101600000,
                              "Duration": 900000
                          }
                      ],
                      "Punctuation": "."
                  },
                  {
                      "Word": "we",
                      "Offset": 102600000,
                      "Duration": 3100000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 74,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "MissingBreak"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "wi",
                              "Grapheme": "we",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 74
                              },
                              "Offset": 102600000,
                              "Duration": 3100000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "w",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 83,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "w",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 98
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 61
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 47
                                      },
                                      {
                                          "Phoneme": "i",
                                          "Score": 32
                                      }
                                  ]
                              },
                              "Offset": 102600000,
                              "Duration": 2300000
                          },
                          {
                              "Phoneme": "i",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 46,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "i",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 60
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 52
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 21
                                      },
                                      {
                                          "Phoneme": "u",
                                          "Score": 7
                                      }
                                  ]
                              },
                              "Offset": 105000000,
                              "Duration": 700000
                          }
                      ]
                  },
                  {
                      "Word": "spotted",
                      "Offset": 105800000,
                      "Duration": 5100000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 82,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "spɑ",
                              "Grapheme": "spot",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 87
                              },
                              "Offset": 105800000,
                              "Duration": 2900000
                          },
                          {
                              "Syllable": "tɪd",
                              "Grapheme": "ted",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 74
                              },
                              "Offset": 108800000,
                              "Duration": 2100000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "s",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 90,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "s",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "i",
                                          "Score": 49
                                      },
                                      {
                                          "Phoneme": "z",
                                          "Score": 35
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 31
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 4
                                      }
                                  ]
                              },
                              "Offset": 105800000,
                              "Duration": 1100000
                          },
                          {
                              "Phoneme": "p",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 78,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "p",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ɑ",
                                          "Score": 29
                                      },
                                      {
                                          "Phoneme": "b",
                                          "Score": 6
                                      },
                                      {
                                          "Phoneme": "s",
                                          "Score": 5
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 3
                                      }
                                  ]
                              },
                              "Offset": 107000000,
                              "Duration": 1100000
                          },
                          {
                              "Phoneme": "ɑ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ɑ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "aɪ",
                                          "Score": 14
                                      },
                                      {
                                          "Phoneme": "ɔ",
                                          "Score": 5
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "aʊ",
                                          "Score": 3
                                      }
                                  ]
                              },
                              "Offset": 108200000,
                              "Duration": 500000
                          },
                          {
                              "Phoneme": "t",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 98,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "t",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ɑ",
                                          "Score": 41
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 7
                                      },
                                      {
                                          "Phoneme": "aɪ",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "ɔ",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 108800000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "ɪ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 16
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 9
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 109600000,
                              "Duration": 500000
                          },
                          {
                              "Phoneme": "d",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 31,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "d",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "s",
                                          "Score": 26
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "z",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "θ",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 110200000,
                              "Duration": 700000
                          }
                      ]
                  },
                  {
                      "Word": "some",
                      "Offset": 111000000,
                      "Duration": 2500000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 81,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "sʌm",
                              "Grapheme": "some",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 81
                              },
                              "Offset": 111000000,
                              "Duration": 2500000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "s",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 79,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "s",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "z",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "θ",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 111000000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "ʌ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ʌ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "s",
                                          "Score": 59
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 53
                                      },
                                      {
                                          "Phoneme": "m",
                                          "Score": 11
                                      },
                                      {
                                          "Phoneme": "ɑ",
                                          "Score": 6
                                      }
                                  ]
                              },
                              "Offset": 111800000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "m",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 68,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "m",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 34
                                      },
                                      {
                                          "Phoneme": "ŋ",
                                          "Score": 5
                                      },
                                      {
                                          "Phoneme": "θ",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 2
                                      }
                                  ]
                              },
                              "Offset": 112600000,
                              "Duration": 900000
                          }
                      ]
                  },
                  {
                      "Word": "fascinating",
                      "Offset": 113600000,
                      "Duration": 7900000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 53,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "fæ",
                              "Grapheme": "fas",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 35
                              },
                              "Offset": 113600000,
                              "Duration": 1900000
                          },
                          {
                              "Syllable": "sən",
                              "Grapheme": "ci",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 52
                              },
                              "Offset": 115600000,
                              "Duration": 2300000
                          },
                          {
                              "Syllable": "eɪ",
                              "Grapheme": "na",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 55
                              },
                              "Offset": 118000000,
                              "Duration": 1300000
                          },
                          {
                              "Syllable": "tɪŋ",
                              "Grapheme": "ting",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 70
                              },
                              "Offset": 119400000,
                              "Duration": 2100000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "f",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 23,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "f",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "θ",
                                          "Score": 43
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 22
                                      },
                                      {
                                          "Phoneme": "v",
                                          "Score": 17
                                      },
                                      {
                                          "Phoneme": "m",
                                          "Score": 13
                                      }
                                  ]
                              },
                              "Offset": 113600000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "æ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 47,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "eɪ",
                                          "Score": 57
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 51
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 25
                                      },
                                      {
                                          "Phoneme": "ɝ",
                                          "Score": 17
                                      }
                                  ]
                              },
                              "Offset": 114600000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "s",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 24,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "z",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "s",
                                          "Score": 97
                                      },
                                      {
                                          "Phoneme": "ʃ",
                                          "Score": 55
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 53
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 41
                                      }
                                  ]
                              },
                              "Offset": 115600000,
                              "Duration": 1100000
                          },
                          {
                              "Phoneme": "ə",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 98,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ə",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 94
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 37
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 36
                                      },
                                      {
                                          "Phoneme": "s",
                                          "Score": 23
                                      }
                                  ]
                              },
                              "Offset": 116800000,
                              "Duration": 500000
                          },
                          {
                              "Phoneme": "n",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 63,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "n",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 39
                                      },
                                      {
                                          "Phoneme": "i",
                                          "Score": 36
                                      },
                                      {
                                          "Phoneme": "ŋ",
                                          "Score": 29
                                      },
                                      {
                                          "Phoneme": "m",
                                          "Score": 28
                                      }
                                  ]
                              },
                              "Offset": 117400000,
                              "Duration": 500000
                          },
                          {
                              "Phoneme": "eɪ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 55,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "i",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "eɪ",
                                          "Score": 78
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 74
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 59
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 48
                                      }
                                  ]
                              },
                              "Offset": 118000000,
                              "Duration": 1300000
                          },
                          {
                              "Phoneme": "t",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 93,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "t",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 92
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 82
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 22
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 14
                                      }
                                  ]
                              },
                              "Offset": 119400000,
                              "Duration": 500000
                          },
                          {
                              "Phoneme": "ɪ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ŋ",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "i",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 2
                                      }
                                  ]
                              },
                              "Offset": 120000000,
                              "Duration": 500000
                          },
                          {
                              "Phoneme": "ŋ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 38,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ŋ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 33
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 19
                                      },
                                      {
                                          "Phoneme": "b",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 3
                                      }
                                  ]
                              },
                              "Offset": 120600000,
                              "Duration": 900000
                          }
                      ]
                  },
                  {
                      "Word": "birds",
                      "Offset": 121600000,
                      "Duration": 4500000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 81,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "bɝrdz",
                              "Grapheme": "birds",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 81
                              },
                              "Offset": 121600000,
                              "Duration": 4500000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "b",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 71,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "b",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ŋ",
                                          "Score": 10
                                      },
                                      {
                                          "Phoneme": "ɡ",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "v",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 121600000,
                              "Duration": 500000
                          },
                          {
                              "Phoneme": "ɝ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ɝ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "b",
                                          "Score": 84
                                      },
                                      {
                                          "Phoneme": "oʊ",
                                          "Score": 78
                                      },
                                      {
                                          "Phoneme": "ʌ",
                                          "Score": 35
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 26
                                      }
                                  ]
                              },
                              "Offset": 122200000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "r",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "r",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "oʊ",
                                          "Score": 90
                                      },
                                      {
                                          "Phoneme": "ɝ",
                                          "Score": 43
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 20
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 15
                                      }
                                  ]
                              },
                              "Offset": 123200000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "d",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "d",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 49
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 44
                                      },
                                      {
                                          "Phoneme": "oʊ",
                                          "Score": 23
                                      },
                                      {
                                          "Phoneme": "z",
                                          "Score": 9
                                      }
                                  ]
                              },
                              "Offset": 124200000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "z",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 29,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "z",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "s",
                                          "Score": 98
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 43
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 34
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 31
                                      }
                                  ]
                              },
                              "Offset": 125200000,
                              "Duration": 900000
                          }
                      ],
                      "Punctuation": "."
                  },
                  {
                      "Word": "after",
                      "Offset": 126200000,
                      "Duration": 3500000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 93,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "MissingBreak"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "æf",
                              "Grapheme": "af",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100
                              },
                              "Offset": 126200000,
                              "Duration": 2100000
                          },
                          {
                              "Syllable": "tər",
                              "Grapheme": "ter",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 82
                              },
                              "Offset": 128400000,
                              "Duration": 1300000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "æ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "æ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "f",
                                          "Score": 27
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 5
                                      },
                                      {
                                          "Phoneme": "z",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "s",
                                          "Score": 3
                                      }
                                  ]
                              },
                              "Offset": 126200000,
                              "Duration": 1300000
                          },
                          {
                              "Phoneme": "f",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "f",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 19
                                      },
                                      {
                                          "Phoneme": "k",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "v",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "s",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 127600000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "t",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "t",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "f",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "k",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 128400000,
                              "Duration": 500000
                          },
                          {
                              "Phoneme": "ə",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ə",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "ɔ",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "ɝ",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 129000000,
                              "Duration": 300000
                          },
                          {
                              "Phoneme": "r",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 37,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "r",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "u",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "ð",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 129400000,
                              "Duration": 300000
                          }
                      ]
                  },
                  {
                      "Word": "that",
                      "Offset": 129800000,
                      "Duration": 4300000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 100,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "ðæt",
                              "Grapheme": "that",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100
                              },
                              "Offset": 129800000,
                              "Duration": 4300000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "ð",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ð",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 129800000,
                              "Duration": 300000
                          },
                          {
                              "Phoneme": "æ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "æ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ð",
                                          "Score": 42
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "ɑ",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 3
                                      }
                                  ]
                              },
                              "Offset": 130200000,
                              "Duration": 1700000
                          },
                          {
                              "Phoneme": "t",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "t",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "h",
                                          "Score": 11
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 10
                                      },
                                      {
                                          "Phoneme": "oʊ",
                                          "Score": 8
                                      },
                                      {
                                          "Phoneme": "s",
                                          "Score": 8
                                      }
                                  ]
                              },
                              "Offset": 132000000,
                              "Duration": 2100000
                          }
                      ]
                  },
                  {
                      "Word": "i",
                      "Offset": 134600000,
                      "Duration": 2500000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 100,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "UnexpectedBreak"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 0.15120278
                                      },
                                      "MissingBreak": {
                                          "Confidence": 0.9243986
                                      },
                                      "BreakLength": 400000
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "aɪ",
                              "Grapheme": "i",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100
                              },
                              "Offset": 134600000,
                              "Duration": 2500000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "aɪ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "aɪ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "h",
                                          "Score": 37
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "s",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 4
                                      }
                                  ]
                              },
                              "Offset": 134600000,
                              "Duration": 2500000
                          }
                      ]
                  },
                  {
                      "Word": "joined",
                      "Offset": 137200000,
                      "Duration": 3700000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 93,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "dʒɔɪnd",
                              "Grapheme": "joined",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 93
                              },
                              "Offset": 137200000,
                              "Duration": 3700000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "dʒ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "dʒ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 17
                                      },
                                      {
                                          "Phoneme": "ɔɪ",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "ɡ",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 137200000,
                              "Duration": 1500000
                          },
                          {
                              "Phoneme": "ɔɪ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ɔɪ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 17
                                      },
                                      {
                                          "Phoneme": "ɔ",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "dʒ",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 138800000,
                              "Duration": 1100000
                          },
                          {
                              "Phoneme": "n",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "n",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ɔɪ",
                                          "Score": 42
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 29
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "m",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 140000000,
                              "Duration": 300000
                          },
                          {
                              "Phoneme": "d",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 56,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "d",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 10
                                      },
                                      {
                                          "Phoneme": "m",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 140400000,
                              "Duration": 500000
                          }
                      ]
                  },
                  {
                      "Word": "my",
                      "Offset": 141000000,
                      "Duration": 1700000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 100,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "maɪ",
                              "Grapheme": "my",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100
                              },
                              "Offset": 141000000,
                              "Duration": 1700000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "m",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "m",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 39
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 141000000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "aɪ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "aɪ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "f",
                                          "Score": 47
                                      },
                                      {
                                          "Phoneme": "m",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "v",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 141800000,
                              "Duration": 900000
                          }
                      ]
                  },
                  {
                      "Word": "friends",
                      "Offset": 142800000,
                      "Duration": 4700000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 87,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "frɛndz",
                              "Grapheme": "friends",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 87
                              },
                              "Offset": 142800000,
                              "Duration": 4700000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "f",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "f",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 43
                                      },
                                      {
                                          "Phoneme": "v",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "θ",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 142800000,
                              "Duration": 1100000
                          },
                          {
                              "Phoneme": "r",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "r",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "f",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "ɝ",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 144000000,
                              "Duration": 500000
                          },
                          {
                              "Phoneme": "ɛ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 36
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 8
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 144600000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "n",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "n",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 71
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 10
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 145600000,
                              "Duration": 500000
                          },
                          {
                              "Phoneme": "d",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "d",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "z",
                                          "Score": 27
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 5
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "s",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 146200000,
                              "Duration": 600000
                          },
                          {
                              "Phoneme": "z",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 11,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "z",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 68
                                      },
                                      {
                                          "Phoneme": "s",
                                          "Score": 5
                                      },
                                      {
                                          "Phoneme": "f",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 146900000,
                              "Duration": 600000
                          }
                      ]
                  },
                  {
                      "Word": "for",
                      "Offset": 147600000,
                      "Duration": 1700000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 100,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "fər",
                              "Grapheme": "for",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100
                              },
                              "Offset": 147600000,
                              "Duration": 1700000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "f",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "f",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "z",
                                          "Score": 48
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 11
                                      },
                                      {
                                          "Phoneme": "s",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 147600000,
                              "Duration": 600000
                          },
                          {
                              "Phoneme": "ə",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ə",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "f",
                                          "Score": 85
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 28
                                      },
                                      {
                                          "Phoneme": "ʊ",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "ɝ",
                                          "Score": 2
                                      }
                                  ]
                              },
                              "Offset": 148300000,
                              "Duration": 400000
                          },
                          {
                              "Phoneme": "r",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "r",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 62
                                      },
                                      {
                                          "Phoneme": "ʌ",
                                          "Score": 18
                                      },
                                      {
                                          "Phoneme": "ɑ",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 148800000,
                              "Duration": 500000
                          }
                      ]
                  },
                  {
                      "Word": "a",
                      "Offset": 149400000,
                      "Duration": 700000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 100,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "ə",
                              "Grapheme": "a",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100
                              },
                              "Offset": 149400000,
                              "Duration": 700000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "ə",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ə",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 53
                                      },
                                      {
                                          "Phoneme": "ʌ",
                                          "Score": 33
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 12
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 2
                                      }
                                  ]
                              },
                              "Offset": 149400000,
                              "Duration": 700000
                          }
                      ]
                  },
                  {
                      "Word": "lively",
                      "Offset": 150200000,
                      "Duration": 4900000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 83,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "laɪv",
                              "Grapheme": "live",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 93
                              },
                              "Offset": 150200000,
                              "Duration": 3300000
                          },
                          {
                              "Syllable": "li",
                              "Grapheme": "ly",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 62
                              },
                              "Offset": 153600000,
                              "Duration": 1500000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "l",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 93,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "l",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "aɪ",
                                          "Score": 37
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 14
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "ɑ",
                                          "Score": 3
                                      }
                                  ]
                              },
                              "Offset": 150200000,
                              "Duration": 1300000
                          },
                          {
                              "Phoneme": "aɪ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 99,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "aɪ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "v",
                                          "Score": 7
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 6
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 5
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 4
                                      }
                                  ]
                              },
                              "Offset": 151600000,
                              "Duration": 1100000
                          },
                          {
                              "Phoneme": "v",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 83,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "v",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "z",
                                          "Score": 85
                                      },
                                      {
                                          "Phoneme": "f",
                                          "Score": 30
                                      },
                                      {
                                          "Phoneme": "aɪ",
                                          "Score": 7
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 5
                                      }
                                  ]
                              },
                              "Offset": 152800000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "l",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "l",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "i",
                                          "Score": 24
                                      },
                                      {
                                          "Phoneme": "v",
                                          "Score": 11
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 7
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 4
                                      }
                                  ]
                              },
                              "Offset": 153600000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "i",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 0,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "i",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "v",
                                          "Score": 24
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 9
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "eɪ",
                                          "Score": 2
                                      }
                                  ]
                              },
                              "Offset": 154600000,
                              "Duration": 500000
                          }
                      ]
                  },
                  {
                      "Word": "virtual",
                      "Offset": 155200000,
                      "Duration": 5300000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 89,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "vɝr",
                              "Grapheme": "vir",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 96
                              },
                              "Offset": 155200000,
                              "Duration": 2200000
                          },
                          {
                              "Syllable": "tʃu",
                              "Grapheme": "tu",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100
                              },
                              "Offset": 157500000,
                              "Duration": 1900000
                          },
                          {
                              "Syllable": "əl",
                              "Grapheme": "al",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 53
                              },
                              "Offset": 159500000,
                              "Duration": 1000000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "v",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "v",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "i",
                                          "Score": 10
                                      },
                                      {
                                          "Phoneme": "ɝ",
                                          "Score": 8
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 6
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 155200000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "ɝ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ɝ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "v",
                                          "Score": 22
                                      },
                                      {
                                          "Phoneme": "ʌ",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "oʊ",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 156200000,
                              "Duration": 500000
                          },
                          {
                              "Phoneme": "r",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 88,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "r",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "tʃ",
                                          "Score": 47
                                      },
                                      {
                                          "Phoneme": "ɝ",
                                          "Score": 21
                                      },
                                      {
                                          "Phoneme": "oʊ",
                                          "Score": 6
                                      },
                                      {
                                          "Phoneme": "ʌ",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 156800000,
                              "Duration": 600000
                          },
                          {
                              "Phoneme": "tʃ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "tʃ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "u",
                                          "Score": 51
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 40
                                      },
                                      {
                                          "Phoneme": "dʒ",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "ʃ",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 157500000,
                              "Duration": 1000000
                          },
                          {
                              "Phoneme": "u",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "u",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 52
                                      },
                                      {
                                          "Phoneme": "tʃ",
                                          "Score": 15
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 158600000,
                              "Duration": 800000
                          },
                          {
                              "Phoneme": "ə",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 98,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ə",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 68
                                      },
                                      {
                                          "Phoneme": "u",
                                          "Score": 44
                                      },
                                      {
                                          "Phoneme": "oʊ",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 159500000,
                              "Duration": 500000
                          },
                          {
                              "Phoneme": "l",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 0,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "l",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 60
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 5
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 2
                                      }
                                  ]
                              },
                              "Offset": 160100000,
                              "Duration": 400000
                          }
                      ]
                  },
                  {
                      "Word": "dance",
                      "Offset": 160600000,
                      "Duration": 3700000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 73,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "dæns",
                              "Grapheme": "dance",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 73
                              },
                              "Offset": 160600000,
                              "Duration": 3700000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "d",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 46,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "d",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "dʒ",
                                          "Score": 66
                                      },
                                      {
                                          "Phoneme": "ɡ",
                                          "Score": 55
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 22
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 14
                                      }
                                  ]
                              },
                              "Offset": 160600000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "æ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 30
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 6
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 2
                                      }
                                  ]
                              },
                              "Offset": 161600000,
                              "Duration": 800000
                          },
                          {
                              "Phoneme": "n",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "n",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 41
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 11
                                      },
                                      {
                                          "Phoneme": "s",
                                          "Score": 9
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 3
                                      }
                                  ]
                              },
                              "Offset": 162500000,
                              "Duration": 1000000
                          },
                          {
                              "Phoneme": "s",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 37,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "s",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 50
                                      },
                                      {
                                          "Phoneme": "k",
                                          "Score": 17
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 8
                                      },
                                      {
                                          "Phoneme": "z",
                                          "Score": 6
                                      }
                                  ]
                              },
                              "Offset": 163600000,
                              "Duration": 700000
                          }
                      ]
                  },
                  {
                      "Word": "class",
                      "Offset": 164400000,
                      "Duration": 5900000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 100,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "klæs",
                              "Grapheme": "class",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100
                              },
                              "Offset": 164400000,
                              "Duration": 5900000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "k",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "k",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 97
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 51
                                      },
                                      {
                                          "Phoneme": "p",
                                          "Score": 18
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 2
                                      }
                                  ]
                              },
                              "Offset": 164400000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "l",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "l",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 30
                                      },
                                      {
                                          "Phoneme": "k",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "ɑ",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "ʌ",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 165400000,
                              "Duration": 800000
                          },
                          {
                              "Phoneme": "æ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "æ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 45
                                      },
                                      {
                                          "Phoneme": "aʊ",
                                          "Score": 16
                                      },
                                      {
                                          "Phoneme": "s",
                                          "Score": 11
                                      },
                                      {
                                          "Phoneme": "ɑ",
                                          "Score": 4
                                      }
                                  ]
                              },
                              "Offset": 166300000,
                              "Duration": 1000000
                          },
                          {
                              "Phoneme": "s",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "s",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 18
                                      },
                                      {
                                          "Phoneme": "z",
                                          "Score": 15
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 11
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 3
                                      }
                                  ]
                              },
                              "Offset": 167400000,
                              "Duration": 2900000
                          }
                      ]
                  },
                  {
                      "Word": "and",
                      "Offset": 171400000,
                      "Duration": 2300000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 86,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "UnexpectedBreak"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 0.3780069
                                      },
                                      "MissingBreak": {
                                          "Confidence": 0
                                      },
                                      "BreakLength": 1000000
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "ən",
                              "Grapheme": "and",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 86
                              },
                              "Offset": 171400000,
                              "Duration": 2300000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "ə",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 92,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ə",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "s",
                                          "Score": 14
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 6
                                      },
                                      {
                                          "Phoneme": "h",
                                          "Score": 5
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 3
                                      }
                                  ]
                              },
                              "Offset": 171400000,
                              "Duration": 1700000
                          },
                          {
                              "Phoneme": "n",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 70,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "n",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "w",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 173200000,
                              "Duration": 500000
                          }
                      ]
                  },
                  {
                      "Word": "we",
                      "Offset": 173800000,
                      "Duration": 1100000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 96,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "wi",
                              "Grapheme": "we",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 96
                              },
                              "Offset": 173800000,
                              "Duration": 1100000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "w",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "w",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 14
                                      },
                                      {
                                          "Phoneme": "i",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 173800000,
                              "Duration": 500000
                          },
                          {
                              "Phoneme": "i",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 92,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "i",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 18
                                      },
                                      {
                                          "Phoneme": "w",
                                          "Score": 8
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 2
                                      }
                                  ]
                              },
                              "Offset": 174400000,
                              "Duration": 500000
                          }
                      ]
                  },
                  {
                      "Word": "had",
                      "Offset": 175000000,
                      "Duration": 1900000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 72,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "hæd",
                              "Grapheme": "had",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 72
                              },
                              "Offset": 175000000,
                              "Duration": 1900000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "h",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "h",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "i",
                                          "Score": 15
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 11
                                      },
                                      {
                                          "Phoneme": "ɡ",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "v",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 175000000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "æ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 94,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "æ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "h",
                                          "Score": 58
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 5
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "ɝ",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 175800000,
                              "Duration": 500000
                          },
                          {
                              "Phoneme": "d",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 13,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "d",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "v",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 176400000,
                              "Duration": 500000
                          }
                      ]
                  },
                  {
                      "Word": "an",
                      "Offset": 177000000,
                      "Duration": 2300000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 97,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "ən",
                              "Grapheme": "an",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 97
                              },
                              "Offset": 177000000,
                              "Duration": 2300000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "ə",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ə",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "ɑ",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 177000000,
                              "Duration": 500000
                          },
                          {
                              "Phoneme": "n",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 96,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "n",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 82
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 5
                                      },
                                      {
                                          "Phoneme": "aʊ",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "ɑ",
                                          "Score": 2
                                      }
                                  ]
                              },
                              "Offset": 177600000,
                              "Duration": 1700000
                          }
                      ]
                  },
                  {
                      "Word": "absolute",
                      "Offset": 179400000,
                      "Duration": 5800000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 90,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "æb",
                              "Grapheme": "ab",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 95
                              },
                              "Offset": 179400000,
                              "Duration": 1900000
                          },
                          {
                              "Syllable": "sə",
                              "Grapheme": "so",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100
                              },
                              "Offset": 181400000,
                              "Duration": 1500000
                          },
                          {
                              "Syllable": "lut",
                              "Grapheme": "lute",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 78
                              },
                              "Offset": 183000000,
                              "Duration": 2200000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "æ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 90,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "æ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ɑ",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "aʊ",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 179400000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "b",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "b",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 12
                                      },
                                      {
                                          "Phoneme": "s",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "p",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 180400000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "s",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "s",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 71
                                      },
                                      {
                                          "Phoneme": "b",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "z",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 181400000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "ə",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ə",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "s",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "u",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 182200000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "l",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "l",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "u",
                                          "Score": 59
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "b",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 183000000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "u",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "u",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 66
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 183800000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "t",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 0,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "t",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "b",
                                          "Score": 61
                                      },
                                      {
                                          "Phoneme": "u",
                                          "Score": 11
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 9
                                      },
                                      {
                                          "Phoneme": "k",
                                          "Score": 2
                                      }
                                  ]
                              },
                              "Offset": 184800000,
                              "Duration": 400000
                          }
                      ]
                  },
                  {
                      "Word": "blast",
                      "Offset": 185300000,
                      "Duration": 4800000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 88,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "blæst",
                              "Grapheme": "blast",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 88
                              },
                              "Offset": 185300000,
                              "Duration": 4800000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "b",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 87,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "b",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 56
                                      },
                                      {
                                          "Phoneme": "p",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "k",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 185300000,
                              "Duration": 600000
                          },
                          {
                              "Phoneme": "l",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "l",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "b",
                                          "Score": 60
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 8
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 6
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 186000000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "æ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "æ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 72
                                      },
                                      {
                                          "Phoneme": "ʌ",
                                          "Score": 28
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 8
                                      },
                                      {
                                          "Phoneme": "aʊ",
                                          "Score": 7
                                      }
                                  ]
                              },
                              "Offset": 187000000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "s",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "s",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 58
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 17
                                      },
                                      {
                                          "Phoneme": "ʃ",
                                          "Score": 16
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 8
                                      }
                                  ]
                              },
                              "Offset": 188000000,
                              "Duration": 1100000
                          },
                          {
                              "Phoneme": "t",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 51,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "t",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "s",
                                          "Score": 12
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "ɡ",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "ʃ",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 189200000,
                              "Duration": 900000
                          }
                      ],
                      "Punctuation": ","
                  },
                  {
                      "Word": "grooving",
                      "Offset": 190200000,
                      "Duration": 5700000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 82,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "MissingBreak"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "ɡru",
                              "Grapheme": "groo",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100
                              },
                              "Offset": 190200000,
                              "Duration": 2500000
                          },
                          {
                              "Syllable": "vɪŋ",
                              "Grapheme": "ving",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 67
                              },
                              "Offset": 192800000,
                              "Duration": 3100000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "ɡ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ɡ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 50
                                      },
                                      {
                                          "Phoneme": "k",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 190200000,
                              "Duration": 500000
                          },
                          {
                              "Phoneme": "r",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "r",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ɡ",
                                          "Score": 52
                                      },
                                      {
                                          "Phoneme": "u",
                                          "Score": 41
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 12
                                      },
                                      {
                                          "Phoneme": "w",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 190800000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "u",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "u",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 18
                                      },
                                      {
                                          "Phoneme": "v",
                                          "Score": 15
                                      },
                                      {
                                          "Phoneme": "oʊ",
                                          "Score": 11
                                      },
                                      {
                                          "Phoneme": "ʊ",
                                          "Score": 3
                                      }
                                  ]
                              },
                              "Offset": 191800000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "v",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 72,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "v",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 14
                                      },
                                      {
                                          "Phoneme": "u",
                                          "Score": 9
                                      },
                                      {
                                          "Phoneme": "ð",
                                          "Score": 6
                                      },
                                      {
                                          "Phoneme": "f",
                                          "Score": 2
                                      }
                                  ]
                              },
                              "Offset": 192800000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "ɪ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ŋ",
                                          "Score": 55
                                      },
                                      {
                                          "Phoneme": "v",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "eɪ",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 193600000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "ŋ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 41,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ŋ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 60
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "h",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 194600000,
                              "Duration": 1300000
                          }
                      ]
                  },
                  {
                      "Word": "and",
                      "Offset": 196000000,
                      "Duration": 1500000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 100,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "ən",
                              "Grapheme": "and",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100
                              },
                              "Offset": 196000000,
                              "Duration": 1500000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "ə",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "æ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 40
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 7
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 196000000,
                              "Duration": 300000
                          },
                          {
                              "Phoneme": "n",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "n",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 12
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "m",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 196400000,
                              "Duration": 1100000
                          }
                      ]
                  },
                  {
                      "Word": "laughing",
                      "Offset": 197600000,
                      "Duration": 4700000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 90,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "læ",
                              "Grapheme": "laugh",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 97
                              },
                              "Offset": 197600000,
                              "Duration": 2100000
                          },
                          {
                              "Syllable": "fɪŋ",
                              "Grapheme": "ing",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 84
                              },
                              "Offset": 199800000,
                              "Duration": 2500000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "l",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 93,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "l",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 22
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 19
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "m",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 197600000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "æ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "æ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "f",
                                          "Score": 6
                                      },
                                      {
                                          "Phoneme": "l",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "ʌ",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "ɑ",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 198600000,
                              "Duration": 1100000
                          },
                          {
                              "Phoneme": "f",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "f",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "ʌ",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "θ",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 199800000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "ɪ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ŋ",
                                          "Score": 36
                                      },
                                      {
                                          "Phoneme": "h",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "f",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 200800000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "ŋ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 48,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ŋ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 24
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 11
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "m",
                                          "Score": 1
                                      }
                                  ]
                              },
                              "Offset": 201600000,
                              "Duration": 700000
                          }
                      ]
                  },
                  {
                      "Word": "together",
                      "Offset": 202400000,
                      "Duration": 6100000,
                      "PronunciationAssessment": {
                          "AccuracyScore": 96,
                          "ErrorType": "None",
                          "Feedback": {
                              "Prosody": {
                                  "Break": {
                                      "ErrorTypes": [
                                          "None"
                                      ],
                                      "UnexpectedBreak": {
                                          "Confidence": 3.780069e-8
                                      },
                                      "MissingBreak": {
                                          "Confidence": 1
                                      },
                                      "BreakLength": 0
                                  },
                                  "Intonation": {
                                      "ErrorTypes": [],
                                      "Monotone": {
                                          "SyllablePitchDeltaConfidence": 0.6340182
                                      }
                                  }
                              }
                          }
                      },
                      "Syllables": [
                          {
                              "Syllable": "tə",
                              "Grapheme": "to",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100
                              },
                              "Offset": 202400000,
                              "Duration": 1100000
                          },
                          {
                              "Syllable": "ɡɛ",
                              "Grapheme": "geth",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100
                              },
                              "Offset": 203600000,
                              "Duration": 1500000
                          },
                          {
                              "Syllable": "ðər",
                              "Grapheme": "er",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 93
                              },
                              "Offset": 205200000,
                              "Duration": 3300000
                          }
                      ],
                      "Phonemes": [
                          {
                              "Phoneme": "t",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "t",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "ŋ",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "k",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 202400000,
                              "Duration": 500000
                          },
                          {
                              "Phoneme": "ə",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ə",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ɡ",
                                          "Score": 20
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "ʊ",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "ɪ",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 203000000,
                              "Duration": 500000
                          },
                          {
                              "Phoneme": "ɡ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ɡ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 3
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "k",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "d",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 203600000,
                              "Duration": 500000
                          },
                          {
                              "Phoneme": "ɛ",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ð",
                                          "Score": 5
                                      },
                                      {
                                          "Phoneme": "æ",
                                          "Score": 2
                                      },
                                      {
                                          "Phoneme": "ʌ",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "ɡ",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 204200000,
                              "Duration": 900000
                          },
                          {
                              "Phoneme": "ð",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ð",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 0
                                      },
                                      {
                                          "Phoneme": "θ",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 205200000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "ə",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 100,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "ə",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "r",
                                          "Score": 5
                                      },
                                      {
                                          "Phoneme": "ð",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "ɛ",
                                          "Score": 1
                                      },
                                      {
                                          "Phoneme": "ʌ",
                                          "Score": 0
                                      }
                                  ]
                              },
                              "Offset": 206000000,
                              "Duration": 700000
                          },
                          {
                              "Phoneme": "r",
                              "PronunciationAssessment": {
                                  "AccuracyScore": 87,
                                  "NBestPhonemes": [
                                      {
                                          "Phoneme": "r",
                                          "Score": 100
                                      },
                                      {
                                          "Phoneme": "ə",
                                          "Score": 47
                                      },
                                      {
                                          "Phoneme": "t",
                                          "Score": 5
                                      },
                                      {
                                          "Phoneme": "n",
                                          "Score": 4
                                      },
                                      {
                                          "Phoneme": "oʊ",
                                          "Score": 3
                                      }
                                  ]
                              },
                              "Offset": 206800000,
                              "Duration": 1700000
                          }
                      ],
                      "Punctuation": "."
                  }
              ]
          }
      ]
  },
  {
      "Id": "1d6225bcb3164a7f92d78b7d198936e7",
      "RecognitionStatus": 5,
      "Offset": 215800000,
      "Duration": 0,
      "Channel": 0,
      "DisplayText": ".",
      "SNR": 0,
      "NBest": [
          {
              "Confidence": 0,
              "Lexical": ".",
              "ITN": ".",
              "MaskedITN": ".",
              "Display": ".",
              "ContentAssessment": {
                  "GrammarScore": 50,
                  "VocabularyScore": 54,
                  "TopicScore": 76
              }
          }
      ]
  }
]