import React from "react";
import { Pie, PieChart, Sector } from "recharts";

interface ICustomShapePieChartProps {
  score: number;
}

const renderActiveShape = (props: any) => {
  // console.log("renderActiveShape :", props);
  const { cx, cy, innerRadius, outerRadius, fill, payload, value } = props;
  const startAngle = 90;
  const endAngle = 90 - (value / 100) * 360;
  const radiusMid = outerRadius - innerRadius;
  const cornerRadius = radiusMid / 2;
  const trackRadius = innerRadius + cornerRadius;
  return (
    <g>
      <circle
        cx={cx}
        cy={cy}
        r={trackRadius}
        fill="none"
        stroke="#f5f5f5"
        strokeWidth={10}
      />
      {payload.selectName !== "" && (
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle + 0.5}
          endAngle={endAngle - 0.5}
          fill={payload.stroke}
          cornerRadius={cornerRadius}
        />
      )}
      {payload.selectName !== "" && (
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius + 2}
          outerRadius={outerRadius - 2}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          cornerRadius={cornerRadius}
        />
      )}
      {payload.selectName === "" && (
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          cornerRadius={cornerRadius}
        />
      )}
    </g>
  );
};
// 0이상 60 미만
// 60이상 80미만
// 80이상
const GREEN = {
  fill: "#66ff4b",
  stroke: "#d9ffd2",
};
//f9e354
const YELLOW = {
  fill: "#ffff00",
  stroke: "#ffffbb",
};
const RED = {
  fill: "#E50709",
  stroke: "#F58789",
};
type TPieDataState = {
  name: string;
  value: number;
  fill: string;
  stroke: string;
};
const initData: TPieDataState[] = [
  {
    name: "score",
    value: 0,
    fill: "",
    stroke: "",
  },
];

export default function CustomShapePieChart({
  score,
}: ICustomShapePieChartProps) {
  const chartRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = React.useState<TPieDataState[]>(initData);
  React.useEffect(() => {
    const updateData = (value: number) => {
      let updatedData = { ...initData[0], value };
      if (value >= 80) {
        updatedData = {
          ...updatedData,
          fill: GREEN.fill,
          stroke: GREEN.stroke,
        };
      } else if (value >= 60 && value < 80) {
        updatedData = {
          ...updatedData,
          fill: YELLOW.fill,
          stroke: YELLOW.stroke,
        };
      } else {
        updatedData = { ...updatedData, fill: RED.fill, stroke: RED.stroke };
      }
      setData([updatedData]);
    };

    updateData(score);
  }, [score]);
  React.useEffect(() => {
    if (chartRef.current) {
      const clientWidth = chartRef.current.clientWidth;
      const offsetWidth = chartRef.current.offsetWidth;
      const scrollWidth = chartRef.current.scrollWidth;
      // console.log(
      //   `clientWidth :${clientWidth}\noffsetWidth :${offsetWidth}\nscrollWidth :${scrollWidth}`
      // );
    }
  }, [chartRef]);
  return (
    <div ref={chartRef}>
      <PieChart
        width={300}
        height={300}
        style={{
          userSelect: "none",
        }}
      >
        <circle
          cx="50%"
          cy="50%"
          r={100}
          fill="none"
          stroke="#ecf2ff"
          strokeWidth={20}
        />
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          activeIndex={0}
          activeShape={(p: any) => renderActiveShape(p)}
          cx="50%"
          cy="50%"
          innerRadius={92.5}
          outerRadius={107.5}
          fill="#00be91"
        />
        <text
          dx="52%"
          dy="54%"
          textAnchor="middle"
          fontFamily="Nunito"
          fontWeight={800}
          fontStyle={"normal"}
          fontSize={44}
          fill="#222"
          style={{
            textShadow: "2px 2px 0 rgba(0,0,0,0.16)",
            userSelect: "none",
          }}
        >
          {score}
          <tspan
            fontFamily="Nunito"
            fontWeight={800}
            fontStyle={"normal"}
            fontSize={20}
            fill="#999999"
            style={{ userSelect: "none" }}
          >
            /100
          </tspan>
        </text>
      </PieChart>
    </div>
  );
}
