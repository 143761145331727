import { useEffect, useState } from "react";
import { RouteType } from "../../routes/config";
import SidebarItem from "./SidebarItem";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import React from "react";

type Props = {
  item: RouteType;
};

const SidebarItemCollapse = ({ item }: Props) => {
  const [open, setOpen] = useState(false);

  const { appState } = useSelector((state: RootState) => state.appState);

  useEffect(() => {
    if (appState.includes(item.state)) {
      setOpen(true);
    }
  }, [appState, item]);

  return item.sidebarProps ? (
    <li className="has-submenu" style={{ userSelect: "none" }}>
      <a
        className={open ? "collapse-btn" : "collapse-btn collapsed"}
        role="button"
        aria-expanded={open}
        aria-controls="collapseExampleMenu"
        onClick={() => setOpen(!open)}
      >
        <i>{item.sidebarProps.icon && item.sidebarProps.icon}</i>
        <span>{item.sidebarProps.displayText}</span>
      </a>
      <div className={open ? "collapse show" : "collapse"}>
        <ul className="submenu rbt-default-sidebar-list">
          {item.child?.map((route, index) =>
            route.sidebarProps ? (
              route.child ? (
                <SidebarItemCollapse item={route} key={index} />
              ) : (
                <SidebarItem item={route} key={index} />
              )
            ) : null
          )}
        </ul>
      </div>
    </li>
  ) : null;
};

export default SidebarItemCollapse;
