import React from "react";
import TTSMeloComponent from "./components/TTS-melo";

export default function TTSMelo() {
  return (
    <div className="App">
      <TTSMeloComponent />
    </div>
  );
}
