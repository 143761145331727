import { ReactNode } from "react";
import { Route, Routes } from "react-router-dom";
import PageWrapper from "../components/layout/PageWrapper";
import { appRoutes,defaultAppRoutes } from "./appRoutes";
import { RouteType } from "./config";
import HomePage from "../pages/home/HomePage";
import PrivateRoute from "./PrivateRoute";
import MainLayout from "../components/layout/MainLayout";
import React from "react";

const generateRoute = (routes: RouteType[]): ReactNode => {
  return routes.map((route, index) => (
    route.index ? (
      <Route
        index
        path={route.path}
        element={<PageWrapper state={route.state}>
          {route.element}
        </PageWrapper>}
        key={index}
      />
    ) : (
      <Route
        path={route.path}
        element={
          <PageWrapper state={route.child ? undefined : route.state}>
            {route.element}
          </PageWrapper>
        }
        key={index}
      >
        {route.child && (
          generateRoute(route.child)
        )}
      </Route>
    )
  ));
};

const routes: ReactNode = generateRoute(appRoutes);
const defaultRoutes: ReactNode = generateRoute(defaultAppRoutes);

export default function Router() {

  return (
    <Routes>
      <Route path='/' element={<MainLayout />} >
        {defaultRoutes}
      </Route>
      <Route element={<PrivateRoute authentication={true} />} >
        <Route path='/' element={<MainLayout />} >
          {routes}
        </Route>
      </Route> 
    </Routes>
  )
}