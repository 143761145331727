import React from "react";
import styles from "./SpeechAnalysisComp.module.scss";

interface IPronunciationAssessmentOriginalTextProps {
  text: string;
  setText: (value: string) => void;
}
const PronunciationAssessmentOriginalText = ({
  text,
  setText,
}: IPronunciationAssessmentOriginalTextProps) => {
  return (
    <div className={styles.wordOriginalText}>
      <textarea
        rows={6}
        value={text}
        onChange={(e) => setText(e.currentTarget.value)}
      />
    </div>
  );
};
export default PronunciationAssessmentOriginalText;
