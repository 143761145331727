import React from "react";
import TTSGoogleComponent from "./components/TTS-google";

export default function TTSGoogle() {
  return (
    <div className="App">
      <TTSGoogleComponent />
    </div>
  );
}
