import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { SliderCustom } from "../../../components/common/slider.component";
import myUIConstants, {
  JSON_FILE_NAMES,
} from "../../../shared/constants/constants";
import { readPromptJson, savePromptJson } from "../../../shared/utils";
import React from "react";
import { TPromptData } from "shared/types";

import RangeInputForm from "../../../components/common/forms/RangeInputForm";
import Form from "../../../components/common/forms/Form";
import TextInputForm from "../../../components/common/forms/TextInputForm";
import TextareaForm from "../../../components/common/forms/TextareaForm";
import PageTitleForm from "../../../components/common/forms/PageTitleForm";

function KipaRagPrompt() {
  const text =
    "child name must be {CHILDNAME}, ai name must be {AINAME}, your input must be {INPUT} (required), last N turns must be {CHAT_HISTORY}";
  const [prompt, set_prompt] = useState("");
  const [top_p, set_top_p] = useState(-1);
  const [temperature, set_temperature] = useState(-1);
  const [chat_prompt_size, set_chat_prompt_size] = useState(-1);
  const [embedding_chunk_size, set_embedding_chunk_size] = useState(-1);
  const [max_new_tokens, set_max_new_tokens] = useState(-1);
  const [repetition_penalty, set_repetition_penalty] = useState(-1);
  const [top_k, set_top_k] = useState(-1);
  const [received, set_received] = useState(false);
  const [username, set_username] = useState("");
  const [ainame, set_ainame] = useState("");
  const onPageOpen = async () => {
    if (!received) {
      const data: TPromptData = await readPromptJson(
        JSON_FILE_NAMES.testKipaRagPrompt
      );
      set_prompt(data.prompt);
      set_top_p(data.top_p);
      set_temperature(data.temperature);
      set_chat_prompt_size(data.chat_prompt_size);
      set_max_new_tokens(data.max_new_tokens);
      set_repetition_penalty(data.repetition_penalty);
      set_top_k(data.top_k);
      set_received(true);
      set_ainame(data.ainame);
      set_username(data.username);
      set_embedding_chunk_size(
        data.embedding_chunk_size ? data.embedding_chunk_size : 4
      );
    }
  };
  const onUpdatePromptData = () => {
    const dto: TPromptData = {
      prompt,
      temperature,
      top_p,
      max_new_tokens,
      chat_prompt_size,
      repetition_penalty,
      top_k,
      ainame,
      username,
      embedding_chunk_size,
    };
    savePromptJson(
      {
        ...dto,
      },
      JSON_FILE_NAMES.testKipaRagPrompt
    );
  };
  onPageOpen();
  return (
    <div className="chat-box-section " style={{ minWidth: 300 }}>
      <PageTitleForm
        title={"KIPA AI Capacity Builder Prep"}
        descriptionText={text}
      />

      <div
        className="rbt-static-bar-top"
        style={{ paddingLeft: 24, paddingRight: 24 }}
      >
        <Form
          onSubmit={(event) => {
            event.preventDefault();
            onUpdatePromptData();
          }}
        >
          <TextareaForm
            onChangeTextarea={(event) => {
              set_prompt(event.target.value);
              onUpdatePromptData();
            }}
            textareaValue={prompt}
          />
          <div style={{ height: 24 }} />
          <RangeInputForm
            value={temperature}
            setValue={set_temperature}
            title={"Temperature"}
            min={0}
            max={1}
            step={0.01}
            onChange={() => {
              onUpdatePromptData();
            }}
          />

          <RangeInputForm
            value={top_p}
            setValue={set_top_p}
            title={"Top P"}
            min={0}
            max={1}
            step={0.01}
            onChange={() => {
              onUpdatePromptData();
            }}
          />
          <RangeInputForm
            value={top_k}
            setValue={set_top_k}
            title={"Top K"}
            min={0}
            max={100}
            step={1}
            onChange={() => {
              onUpdatePromptData();
            }}
          />
          <RangeInputForm
            value={max_new_tokens}
            setValue={set_max_new_tokens}
            title={"Max New Tokens"}
            min={16}
            max={1024}
            step={2}
            onChange={() => {
              onUpdatePromptData();
            }}
          />
          <RangeInputForm
            value={chat_prompt_size}
            setValue={set_chat_prompt_size}
            title={"Chat Prompt Size"}
            min={16}
            max={2048}
            step={4}
            onChange={() => {
              onUpdatePromptData();
            }}
          />
          <RangeInputForm
            value={repetition_penalty}
            setValue={set_repetition_penalty}
            title={"Repetition Penalty"}
            min={0}
            max={2}
            step={0.01}
            onChange={() => {
              onUpdatePromptData();
            }}
          />

          <RangeInputForm
            value={embedding_chunk_size}
            setValue={set_embedding_chunk_size}
            title={"Embedding Chunk Size"}
            min={1}
            max={100}
            step={1}
            onChange={() => {
              onUpdatePromptData();
            }}
          />
          <TextInputForm
            items={[
              {
                value: username,
                onChange: (event) => {
                  set_username(event.target.value);
                  onUpdatePromptData();
                },
                placeholder: "child name",
                title: "CHILDNAME:  ",
              },
              {
                value: ainame,
                onChange: (event) => {
                  set_ainame(event.target.value);
                  onUpdatePromptData();
                },
                placeholder: "ai name",
                title: "AINAME:  ",
              },
            ]}
          />
        </Form>
      </div>
    </div>
  );
}

export default KipaRagPrompt;
