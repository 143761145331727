import ChainOfThoughtChatComponent from "./ChainOfThoughtChatComponent";
import React from "react";

export default function ChainOfThoughtChat() {
  return (
    <div className="App">
      
      <ChainOfThoughtChatComponent />
    </div>
  );
}
