import axios from "axios";
import React, { useState } from "react";
// import * as mime from "mime";
// import { lookup } from "mime-types";
interface IRecordButtonProps {
  referenceText: string;
  setStatesData: (
    value: TSpeechAnalysisData,
    targetText: string
  ) => Promise<void>;
}
const RecordButton: React.FC<IRecordButtonProps> = ({
  referenceText,
  setStatesData,
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState<string | null>(null);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );

  // 녹음 시작 및 중지 핸들러
  const handleRecord = async () => {
    if (!isRecording) {
      // 녹음 시작
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      let chunks: BlobPart[] = [];

      recorder.ondataavailable = (event) => chunks.push(event.data);
      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: "audio/wave" });
        setAudioBlob(blob);
        setAudioURL(URL.createObjectURL(blob));
      };

      recorder.start();
      setMediaRecorder(recorder);
      setIsRecording(true);
    } else {
      // 녹음 중지
      mediaRecorder?.stop();
      setIsRecording(false);
    }
  };

  // 파일을 API로 전송하는 함수
  const sendAudioToApi = async () => {
    console.log("sendAudioToApi Start!");
    console.log("mediaRecorder :", mediaRecorder);
    console.log("audioBlob :", audioBlob);
    console.log("audioURL:", audioURL);
    if (!audioBlob) return;
    const formData = new FormData();
    console.log("referenceText :", referenceText);
    const mimeType = "audio/wav";
    formData.append("audio", audioBlob, `recording.${mimeType.split("/")[1]}`);
    formData.append("reference_text", referenceText);
    try {
      const url = process.env.REACT_APP_AZURE_PRONUNCIATION_ASSESSMENT
        ? process.env.REACT_APP_AZURE_PRONUNCIATION_ASSESSMENT
        : "";
      console.log("url :", url);
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      });
      if (response.data.statusCode === 200) {
        console.log("파일 전송 성공!");
        console.log("response.data.data :", response.data.data.json_str);
        const jsonData: TSpeechAnalysisData = JSON.parse(
          JSON.stringify(response.data.data.json_str)
        );
        await setStatesData(jsonData, referenceText);
      } else {
        console.error("파일 전송 실패 :", response);
      }
    } catch (error) {
      console.error("API 전송 오류:", error);
    }
  };

  return (
    <div>
      <button onClick={handleRecord}>
        {isRecording ? "녹음 중지" : "녹음 시작"}
      </button>
      {audioURL && (
        <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
          <audio src={audioURL} controls />
          <button onClick={sendAudioToApi}>Submit</button>
        </div>
      )}
    </div>
  );
};

export default RecordButton;
