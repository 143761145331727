import { ListItemButton, ListItemIcon } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import { RootState } from "../../redux/store";
import { RouteType } from "../../routes/config";
import myUIConstants from "../../shared/constants/constants";
import React from "react";

type Props = {
  item: RouteType;
};

const SidebarItem = ({ item }: Props) => {
  const { appState } = useSelector((state: RootState) => state.appState);

  return item.sidebarProps && item.path ? (
    <ListItemButton
      component={Link}
      to={item.path}
      sx={{
        "&: hover": {
          backgroundColor: colorConfigs.sidebar.hoverBg,
        },
        marginLeft: "10px",
        marginRight: "10px",
        marginBottom: "10px",
        borderRadius: "10px",
        backgroundColor:
          appState === item.state ? colorConfigs.sidebar.activeBg : "unset",
        paddingY: "12px",
        paddingX: "24px",
      }}
      style={{
        fontSize: sizeConfigs.sidebar.subTextSize,
        fontFamily: myUIConstants.fontFamily,
        fontWeight: 500,
      }}
    >
      <ListItemIcon
        sx={{
          color: colorConfigs.sidebar.color,
          minWidth: 44,
        }}
      >
        {item.sidebarProps.icon && item.sidebarProps.icon}
      </ListItemIcon>

      {item.sidebarProps.displayText}
    </ListItemButton>
  ) : null;
};

export default SidebarItem;
