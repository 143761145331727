import { ListItemButton, ListItemIcon } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import { RootState } from "../../redux/store";
import { RouteType } from "../../routes/config";
import React from "react";

type Props = {
  item: RouteType;
};

const SidebarItem = ({ item }: Props) => {
  const { appState } = useSelector((state: RootState) => state.appState);

  return item.sidebarProps && item.path ? (
    <li style={{ userSelect: "none" }}>
      <Link
        className={appState === item.state ? "active" : ""}
        to={item.path}
        style={{
          fontSize: sizeConfigs.sidebar.subTextSize,
          fontWeight: 500,
        }}
      >
        <i style={{ color: colorConfigs.sidebar.color }}>
          {item.sidebarProps.icon && item.sidebarProps.icon}
        </i>
        <span>{item.sidebarProps.displayText}</span>
      </Link>
    </li>
  ) : null;
};

export default SidebarItem;
