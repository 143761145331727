import React from "react";
import { ChatFeed, Message } from "react-chat-ui";
import { Button } from "@mui/material";
import "../components/styles.css";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  CHATBOT_API_URL,
  CHATTING_AI_MESSAGE_ID,
  CHATTING_AI_NAME,
  CHATTING_USER_MESSAGE_ID,
  CHATTING_USER_NAME,
  JSON_FILE_NAMES,
  NEW_CHATBOT_API_URL,
  URL_JSON_BACKEND_READ,
  URL_JSON_BACKEND_WRITE,
} from "../../../shared/constants/constants";
import {
  readChatJson,
  readPromptJson,
  saveChatJson,
  removeUnnecessaryResponseFromAIResponse,
  parseMarkdownTable,
  getResponseFromLLMServer,
  getChattingNameById,
  postData,
  getStoppingStringsForAIRsp,
  fromHistoryToString,
  fromHistoryToOldHistory,
  parseMarkdownText,
} from "../../../shared/utils";
import myUIConstants from "../../../shared/constants/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  ChatComponentState,
  TAIRequestObj,
  TChatbotResponseData,
  TChatMessageElement,
  THistoryTurnOne,
  TPromptData,
} from "shared/types";
import ChatList from "../../../components/common/chats/ChatList";
import StaticBarDashboard from "../../../components/common/forms/StaticBar/StaticBarDashboard";

const CHAT_TYPE = 0;
const URL_CHAT = NEW_CHATBOT_API_URL;

type BackupObj = {
  timestamp: number;
  history: THistoryTurnOne[];
  prompt: string;
};

async function readBackupChatHistory(file_name: string): Promise<BackupObj[]> {
  const url = URL_JSON_BACKEND_READ;
  try {
    const backupRsp: { curBackup: BackupObj[] } = await postData(url, {
      file_name,
    });
    return backupRsp.curBackup;
  } catch (error) {
    return [];
  }
}

async function updateBackup(
  curBackup: BackupObj[],
  file_name: string
): Promise<void> {
  const url = URL_JSON_BACKEND_WRITE;
  await postData(url, { curBackup, file_name });
}

function backupChatHistory(
  history: THistoryTurnOne[],
  prompt: string,
  file_name: string,
  curBackup: BackupObj[]
): void {
  const backupObj: BackupObj = {
    timestamp: new Date().getTime(),
    history,
    prompt,
  };
  //dont save duplicates
  for (const oldObj of curBackup) {
    if (JSON.stringify(oldObj.history) === JSON.stringify(backupObj.history)) {
      return;
    }
  }
  curBackup.push(backupObj);
  updateBackup(curBackup, file_name);
}

async function getLLMResponse(data: {
  history: string[][];
  ai_name: string;
  child_name: string;
}): Promise<{ text: string; prompt_text: string }> {
  const chatURL = `${process.env.REACT_APP_LLM_SERVER_URL}/chit-chat/test-yanadoo-rag`;
  const rsp: { text: string; prompt_text: string } =
    await getResponseFromLLMServer(chatURL, data);
  return rsp;
}

async function GetAIAnswer(
  history: THistoryTurnOne[],
  file_name_read_prompt: string
): Promise<any[]> {
  const promptData: TPromptData = await readPromptJson(file_name_read_prompt);
  const {
    temperature,
    top_p,
    max_new_tokens,
    chat_prompt_size,
    repetition_penalty,
    top_k,
    ainame,
    username,
  } = promptData;
  // const lastUserMsg = history[history.length - 1].message;
  // const chunkArr = await getRagChunks(lastUserMsg, 4);
  // promptData.prompt = promptData.prompt.replace(
  //   /{RAG_DOCUMENTS}/g,
  //   chunkArr.join("\n")
  // );
  // promptData.prompt = promptData.prompt.replace(
  //   /{CONVERSATION_HISTORY}/g,
  //   fromHistoryToString(history, ainame, username)
  // );
  // promptData.prompt = promptData.prompt.replace(/{AINAME}/g, ainame);
  // promptData.prompt = promptData.prompt.replace(/{CHILDNAME}/g, username);

  // const aiRequestObj: TAIRequestObj = {
  //   text: "s",
  //   selected: 4,
  //   clear_previous: false,
  //   prompt: promptData.prompt,
  //   temperature,
  //   top_p,
  //   max_new_tokens,
  //   chat_prompt_size,
  //   repetition_penalty,
  //   top_k,
  //   ainame,
  //   username,
  //   chat_type: CHAT_TYPE,
  //   stopping_strings: getStoppingStringsForAIRsp(ainame, username),
  // };
  // const chatbotRspData: TChatbotResponseData = await postData(
  //   URL_CHAT,
  //   aiRequestObj
  // );
  // const { output, prompt, generate_ans_time, generate_prompt_time, speed } =
  //   chatbotRspData;
  // const revisedAnswer = removeUnnecessaryResponseFromAIResponse(
  //   output,
  //   ainame,
  //   username
  // );
  // return [
  //   revisedAnswer,
  //   prompt,
  //   generate_ans_time,
  //   generate_prompt_time,
  //   speed,
  // ];
  const chatbotRspData = await getLLMResponse({
    history: fromHistoryToOldHistory(history),
    ai_name: ainame,
    child_name: username,
  });
  return [
    removeUnnecessaryResponseFromAIResponse(
      chatbotRspData.text,
      ainame,
      username
    ),
    chatbotRspData.prompt_text,
    0,
    0,
    0,
  ];
}

type YandooChatComponentState = ChatComponentState & {
  file_name_backup_history: string;
  isBackedAlready: boolean;
  buttonArr: any[];
  page_index: number;
};

class YanadooRagChatComponent extends React.Component<
  {},
  YandooChatComponentState
> {
  constructor() {
    super({});
    this.state = {
      messages: [],
      history: [],
      selected: 4,
      prompt: "",
      chat_type: CHAT_TYPE,
      file_name_chat: JSON_FILE_NAMES.testYanadooRagChat,
      file_name_prompt: JSON_FILE_NAMES.testYanadooRagPrompt,
      file_name_backup_history: JSON_FILE_NAMES.testYanadoooRagBackupHistory,
      speed: -1,
      generate_ans_time: -1,
      generate_prompt_time: -1,
      isBackedAlready: false,
      buttonArr: [],
      input_message: "",
      page_index: 1,
    };
  }

  async componentDidMount() {
    const { history, prompt } = await readChatJson(this.state.file_name_chat);
    this.populateChatWindow(history, prompt);
    this.setState({ buttonArr: await this.getBackupButtonArr() });
  }

  populateChatWindow(history: THistoryTurnOne[], prompt: string) {
    const messageElements: TChatMessageElement[] = history.map(
      (historyTurn: THistoryTurnOne) => {
        return {
          id: historyTurn.id,
          message: (
            <div style={{ color: "black", margin: 0 }}>
              <ReactMarkdown className="nomargin" remarkPlugins={[remarkGfm]}>
                {parseMarkdownText(parseMarkdownTable(historyTurn.message))}
              </ReactMarkdown>
            </div>
          ),
          senderName: getChattingNameById(historyTurn.id),
        };
      }
    );
    this.setState({ messages: messageElements });
    this.setState({ history });
    this.setState({ prompt });
  }

  async onMessageSubmit(e: any) {
    e.preventDefault();
    const text = this.state.input_message;
    this.pushUserMessage(text);
    this.setState({ input_message: "" });
    const [reply, prompt, generate_ans_time, generate_prompt_time, speed] =
      await GetAIAnswer(this.state.history, this.state.file_name_prompt);
    this.pushEllaMessage(reply, prompt);
    this.setState({
      generate_ans_time,
      generate_prompt_time,
      speed,
    });
  }

  pushUserMessage(message: string) {
    const prevState = this.state;
    const newMessage = new Message({
      id: CHATTING_USER_MESSAGE_ID,
      message,
      senderName: CHATTING_USER_NAME,
    });
    prevState.history.push({ message, id: CHATTING_USER_MESSAGE_ID });
    prevState.messages.push(newMessage);
    this.setState(this.state);
  }

  pushEllaMessage(message: string, prompt: string) {
    const prevState = this.state;
    const messageElement = (
      <div style={{ color: "black", margin: 0 }}>
        <ReactMarkdown className="nomargin" remarkPlugins={[remarkGfm]}>
          {parseMarkdownTable(message)}
        </ReactMarkdown>
      </div>
    );
    const ellaMessage = new Message({
      id: CHATTING_AI_MESSAGE_ID,
      message: messageElement as any,
      senderName: CHATTING_AI_NAME,
    });
    prevState.messages.push(ellaMessage);
    prevState.history.push({ message, id: CHATTING_AI_MESSAGE_ID });
    this.setState({ prompt, isBackedAlready: false });
    saveChatJson(this.state.history, prompt, this.state.file_name_chat);
  }

  onClearHistory(e: any) {
    e.preventDefault();
    this.setState({ messages: [] });
    this.setState({ history: [] });
    saveChatJson(
      this.state.history,
      this.state.prompt,
      this.state.file_name_chat
    );
  }

  async onBackupDelete(e: any, timestamp: number) {
    const backupArr = await this.getBackupArr();
    const newArr: BackupObj[] = [];
    for (const obj of backupArr) {
      if (obj.timestamp !== timestamp) {
        newArr.push(obj);
      }
    }
    await updateBackup(newArr, this.state.file_name_backup_history);
    this.setState({ buttonArr: await this.getBackupButtonArr() });
  }

  onApplyBackupToChatWindow(
    e: any,
    history: THistoryTurnOne[],
    prompt: string
  ) {
    e.preventDefault();
    this.populateChatWindow(history, prompt);
  }

  async getBackupButtonArr(): Promise<any[]> {
    const backupArr = await this.getBackupArr();
    backupArr.sort((a, b) => b.timestamp - a.timestamp);
    const buttonArr: any[] = [];
    backupArr.forEach((backupObj) => {
      const { timestamp, history, prompt } = backupObj;
      buttonArr.push(
        <div>
          <Button
            onClick={(e) => this.onApplyBackupToChatWindow(e, history, prompt)}
            style={{ margin: 10, width: 400 }}
            type="submit"
            variant="outlined"
            color="success"
          >
            {new Date(timestamp).toISOString()}
          </Button>
          <Button
            onClick={(e) => this.onBackupDelete(e, timestamp)}
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </div>
      );
    });
    return buttonArr;
  }

  async getBackupArr(): Promise<BackupObj[]> {
    const curBackupArr: BackupObj[] = await readBackupChatHistory(
      this.state.file_name_backup_history
    );
    return curBackupArr;
  }

  async onBackupCurrentChat(e: any) {
    e.preventDefault();
    const backupArr: BackupObj[] = await this.getBackupArr();
    backupChatHistory(
      this.state.history,
      this.state.prompt,
      this.state.file_name_backup_history,
      backupArr
    );
    this.setState({ isBackedAlready: true });
  }

  // page control
  onClickTab(index: number) {
    this.setState({ page_index: index });
  }

  render() {
    return (
      <div className="chat-box-section " style={{ minWidth: 300 }}>
        <div className="single-settings-box profile-details-box overflow-hidden">
          <h3
            className="title pt-4"
            style={{
              textAlign: "center",
              marginBottom: 50,
            }}
          >
            Yanadoo CS Chat (RAG)
          </h3>
          <div className="profile-details-tab">
            <div className="advance-tab-button mb--30">
              <ul
                className="nav nav-tabs tab-button-style-2 justify-content-start"
                id="settinsTab-4"
                role="tablist"
              >
                <li role="presentation">
                  <a
                    href="#"
                    className={`tab-button ${
                      this.state.page_index === 1 && "active"
                    }`}
                    data-bs-toggle="tab"
                    role="tab"
                    aria-selected={this.state.page_index === 1}
                    onClick={() => this.onClickTab(1)}
                  >
                    <span className="title">Chat</span>
                  </a>
                </li>
                <li role="presentation">
                  <a
                    href="#"
                    className={`tab-button ${
                      this.state.page_index === 2 && "active"
                    }`}
                    data-bs-toggle="tab"
                    role="tab"
                    aria-selected={this.state.page_index === 2}
                    onClick={() => this.onClickTab(2)}
                  >
                    <span className="title">Prompt looks like this</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="tab-content">
          <div
            className={`tab-pane fade ${
              this.state.page_index === 1 && "active show"
            }`}
            role="tabpanel"
          >
            <ChatList
              message={this.state.messages}
              aiName={"Ella"}
              userName={"You"}
            />
            <StaticBarDashboard
              onSubmit={(e) => this.onMessageSubmit(e)}
              onChangeTextarea={(e) => {
                this.setState({ input_message: e.target.value });
              }}
              textareaValue={this.state.input_message}
              onBackupCurrentChat={(e) => this.onBackupCurrentChat(e)}
              onClearHistory={(e) => this.onClearHistory(e)}
            />
          </div>
          <div
            className={`tab-pane fade ${
              this.state.page_index === 2 && "active show"
            }`}
            role="tabpanel"
          >
            <div
              style={{
                direction: "rtl",
                width: "100%",
                padding: 10,
                fontSize: 15,
                display: "flex",
                flexDirection: "row",
              }}
            >
              {this.state.generate_ans_time !== -1 ? (
                <p>
                  Prompt was generated in {this.state.generate_prompt_time} s.
                  Answer was generated in {this.state.generate_ans_time} s.
                  Speed is {this.state.speed} t/s
                </p>
              ) : null}
            </div>
            <div>
              <h3>Previous conversations:</h3>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {this.state.buttonArr}
              </div>
            </div>
            <div style={{ marginTop: "10px" }}>
              <h3>Prompt looks like this:</h3>
              <textarea
                readOnly={true}
                rows={50}
                style={{
                  marginTop: 0,
                  marginBottom: 50,
                  width: "100%",
                  borderRadius: 5,
                  padding: 5,
                }}
                color="primary"
                value={this.state.prompt}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default YanadooRagChatComponent;
