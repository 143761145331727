import React from "react";
import { Message } from "react-chat-ui";
// import { Button, Divider } from "@mui/material";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { CHATBOT_API_URL } from "../../../shared/constants/constants";
// import myUIConstants from "../../../shared/constants/constants";
import ChatList from "../../../components/common/chats/ChatList";
import StaticBarDashboard from "../../../components/common/forms/StaticBar/StaticBarDashboard";

const URL_CHAT = CHATBOT_API_URL;
const URL_MEMORY_CHAT_READ = `${process.env.REACT_APP_JSON_BACKEND_HOST}/json/read/vicuna/7b/chat/memory`;
const URL_MEMORY_CHAT_WRITE = `${process.env.REACT_APP_JSON_BACKEND_HOST}/json/write/vicuna/7b/chat/memory`;
const URL_MEMORY_PROMPT = `${process.env.REACT_APP_JSON_BACKEND_HOST}/json/read/vicuna/7b/memory`;
const CHAT_TYPE = 2;

async function postData(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response; // parses JSON response into native JavaScript objects
}
async function getData(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
  return response; // parses JSON response into native JavaScript objects
}

async function GetAIAnswer(
  text,
  selected,
  clearPrevious,
  url_prompt,
  chat_type,
  clear_memory
) {
  return await getData(url_prompt)
    .then((rsp) => rsp.json())
    .then(async (data) => {
      return await postData(URL_CHAT, {
        text,
        selected,
        clear_previous: clearPrevious,
        prompt: data.prompt,
        temperature: data.temperature,
        top_p: data.top_p,
        max_new_tokens: data.max_new_tokens,
        chat_prompt_size: data.chat_prompt_size,
        repetition_penalty: data.repetition_penalty,
        top_k: data.top_k,
        ainame: data.ainame,
        username: data.username,
        chat_type,
        clear_memory,
      })
        .then((data) => {
          return data.json();
        })
        .then(async (data) => {
          return [
            data.output,
            data.prompt,
            data.generate_ans_time,
            data.generate_prompt_time,
            data.speed,
          ];
        })
        .catch((e) => {
          return "Server is not running";
        });
    });
}

const users = {
  0: "You",
  1: "Ella",
};

const customBubble = (props) => (
  <div>
    <p>{`${props.message.senderName} ${props.message.id ? "says" : "said"}: ${
      props.message.message
    }`}</p>
  </div>
);

const readJson = async (url_chat) => {
  return await getData(url_chat)
    .then((rsp) => rsp.json())
    .then((data) => {
      const history = data.history;
      const prompt = data.prompt;
      const rsp = [];
      history.forEach((obj) => {
        const message = obj.message;
        const id = obj.id;
        rsp.push(new Message({ message, id, senderName: users[id] }));
      });
      return [rsp, history, prompt];
    });
};

const saveJson = (url_chat, json, prompt) => {
  const history = [];
  json.forEach((obj) => {
    const msg = obj.message;
    const id = obj.id;
    history.push({ message: msg, id });
  });
  postData(url_chat, { history, prompt });
};

class MemoryChatComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      messages: [],
      history: [],
      useCustomBubble: false,
      curr_user: 0,
      selected: 4,
      prompt: "",
      chat_type: CHAT_TYPE,
      url_prompt: URL_MEMORY_PROMPT,
      url_chat_read: URL_MEMORY_CHAT_READ,
      url_chat_write: URL_MEMORY_CHAT_WRITE,
      clear_memory: false,
      speed: -1,
      generate_ans_time: -1,
      generate_prompt_time: -1,
      page_index: 1,
      input_message: "",
    };
  }
  async componentDidMount() {
    const [messages, history, prompt] = await readJson(
      this.state.url_chat_read
    );
    const messageElements = [];
    messages.forEach((message) => {
      const messageElement = {
        id: message.id,
        message:
          message.senderName === users[1] ? (
            <div style={{ color: "black", margin: 0 }}>
              <ReactMarkdown
                className="nomargin"
                style={{ margin: 0 }}
                remarkPlugins={[remarkGfm]}
              >
                {this.parseMarkdownTable(message.message)}
              </ReactMarkdown>
            </div>
          ) : (
            message.message
          ),
        senderName: message.senderName,
      };
      messageElements.push(messageElement);
    });

    this.setState({ messages: messageElements });
    this.setState({ history });
    this.setState({ prompt });
  }

  onPress(user) {
    this.setState({ curr_user: user });
  }

  async onMessageSubmit(e) {
    e.preventDefault();
    const text = this.state.input_message;
    this.pushUserMessage(this.state.curr_user, text);
    this.setState({ input_message: "" });
    const clearPrevious = this.state.history.length == 1;
    const [reply, prompt, generate_ans_time, generate_prompt_time, speed] =
      await GetAIAnswer(
        text,
        this.state.selected,
        clearPrevious,
        this.state.url_prompt,
        this.state.chat_type,
        this.state.clear_memory
      );
    this.pushEllaMessage(reply, prompt);
    this.setState({
      clear_memory: false,
      generate_ans_time,
      generate_prompt_time,
      speed,
    });
  }

  pushUserMessage(recipient, message) {
    const prevState = this.state;
    const newMessage = new Message({
      id: recipient,
      message,
      senderName: users[recipient],
    });
    prevState.history.push({ message, id: recipient });
    prevState.messages.push(newMessage);
    this.setState(this.state);
  }

  parseMarkdownTable(message) {
    const count = (message.match(/\|\s*\|/g) || []).length;

    if (count < 1) return message;
    message = message.replace(/\|\s*\|/g, "|\n|");
    message = message.replace(/\|/, "\n|");
    const lastPipeIndex = message.lastIndexOf("|");
    message =
      message.slice(0, lastPipeIndex + 1) +
      "\n\n" +
      message.slice(lastPipeIndex + 1);
    return message;
  }

  pushEllaMessage(message, prompt) {
    const prevState = this.state;
    const messageElement = (
      <div style={{ color: "black", margin: 0 }}>
        <ReactMarkdown
          // className="nomargin"
          style={{ margin: 0 }}
          remarkPlugins={[remarkGfm]}
        >
          {this.parseMarkdownTable(message)}
        </ReactMarkdown>
      </div>
    );
    const newMessage = new Message({
      id: 1,
      message: messageElement,
      senderName: "Ella",
    });
    prevState.messages.push(newMessage);
    prevState.history.push({ message, id: 1 });
    this.setState({ ...this.state, prompt });
    saveJson(this.state.url_chat_write, this.state.history, prompt);
  }

  setSelected(x) {
    this.setState({ selected: x });
  }

  onClearHistory(e) {
    e.preventDefault();
    this.setState({ clearPrevious: true });
    this.setState({ messages: [] });
    this.setState({ history: [] });
    saveJson(this.state.url_chat_write, this.state.history, this.state.prompt);
  }

  onClearMemory(e) {
    e.preventDefault();
    this.setState({ clear_memory: !this.state.clear_memory });
  }

  // page control
  onClickTab(index) {
    this.setState({ page_index: index });
  }

  render() {
    return (
      <div className="chat-box-section " style={{ minWidth: 300 }}>
        <div className="single-settings-box profile-details-box overflow-hidden">
          <h3
            className="title pt-4"
            style={{
              textAlign: "center",
              marginBottom: 50,
            }}
          >
            Episodic Memory Chat (RAG)
          </h3>
          <div className="profile-details-tab">
            <div className="advance-tab-button mb--30">
              <ul
                className="nav nav-tabs tab-button-style-2 justify-content-start"
                id="plain-chat-component-free-chat-tab"
                role="tablist"
              >
                <li role="presentation">
                  <a
                    href="#"
                    className={`tab-button ${
                      this.state.page_index === 1 && "active"
                    }`}
                    data-bs-toggle="tab"
                    role="tab"
                    aria-selected={this.state.page_index === 1}
                    onClick={() => this.onClickTab(1)}
                  >
                    <span className="title">Chat</span>
                  </a>
                </li>
                <li role="presentation">
                  <a
                    href="#"
                    className={`tab-button ${
                      this.state.page_index === 2 && "active"
                    }`}
                    data-bs-toggle="tab"
                    role="tab"
                    aria-selected={this.state.page_index === 2}
                    onClick={() => this.onClickTab(2)}
                  >
                    <span className="title">Prompt looks like this</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="tab-content">
          <div
            className={`tab-pane fade ${
              this.state.page_index === 1 && "active show"
            }`}
            role="tabpanel"
          >
            <ChatList
              message={this.state.messages}
              aiName={"Ella"}
              userName={"You"}
            />
            <StaticBarDashboard
              onSubmit={(e) => this.onMessageSubmit(e)}
              onChangeTextarea={(e) => {
                this.setState({ input_message: e.target.value });
              }}
              textareaValue={this.state.input_message}
              onClearHistory={(e) => this.onClearHistory(e)}
              onClearMemory={(e) => this.onClearMemory(e)}
            />
          </div>

          <div
            className={`tab-pane fade ${
              this.state.page_index === 2 && "active show"
            }`}
            role="tabpanel"
          >
            <div className="container" style={{ marginTop: 0 }}>
              <div
                style={{
                  direction: "rtl",
                  width: "100%",
                  padding: 10,
                  fontSize: 15,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {this.state.generate_ans_time !== -1 ? (
                  <p>
                    Prompt was generated in {this.state.generate_prompt_time} s.
                    Answer was generated in {this.state.generate_ans_time} s.
                    Speed is {this.state.speed} t/s
                  </p>
                ) : null}
              </div>
              <div style={{ marginTop: "10px" }}>
                <h3>Prompt looks like this:</h3>
                <textarea
                  readOnly={true}
                  rows={50}
                  style={{
                    marginTop: 0,
                    marginBottom: 50,
                    width: "100%",
                    borderRadius: 5,
                    padding: 5,
                  }}
                  color="primary"
                  value={this.state.prompt}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MemoryChatComponent;
