import { BrowserRouter } from "react-router-dom";
import Router from "./routes";
import React from "react";
import "bootstrap/scss/bootstrap.scss";

// ========= Plugins CSS START =========
import "tools/css/plugins/feature.css";
import "tools/css/plugins/fontawesome-all.min.css";
import "tools/css/plugins/animation.css";
// import "../node_modules/sal.js/dist/sal.css";
import "sal.js/dist/sal.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-tooltip/dist/react-tooltip.css";
// ========= Plugins CSS END =========

import "tools/scss/style.scss";

function App() {
  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
}

export default App;
