import React from "react";
import { ChatFeed, Message } from "react-chat-ui";
import { Button, Divider } from "@mui/material";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  CHATBOT_API_URL,
  JSON_FILE_NAMES,
} from "../../../shared/constants/constants";
import myUIConstants from "../../../shared/constants/constants";
import {
  getSubtext,
  postFormData,
  readChatJson,
  readPromptJson,
  saveChatJson,
  getStoppingStringsForAIRsp,
} from "../../../shared/utils";
import ChatList from "../../../components/common/chats/ChatList";
import StaticBarDashboard from "../../../components/common/forms/StaticBar/StaticBarDashboard";

const URL_CHAT = CHATBOT_API_URL;
const CHAT_TYPE = 1;
const examples = [
  `Give me a short summary of the story.`,
  `Generate some questions based on the story.`,
  `Who is the main character of this story?`,
];

async function GetAIAnswer(
  text,
  selected,
  clearPrevious,
  file_name,
  chat_type,
  clear_memory,
  file
) {
  const promptData = await readPromptJson(file_name);

  var formData = new FormData();
  formData.append("file", file);
  formData.append("text", text);
  formData.append("selected", selected);
  formData.append("prompt", promptData.prompt);
  formData.append("min_len", promptData.min_len);
  formData.append("max_len", promptData.max_len);
  formData.append("beam_size", promptData.beam_size);
  formData.append("len_penalty", promptData.len_penalty);
  formData.append("ainame", promptData.ainame);
  formData.append("username", promptData.username);
  formData.append("repetition_penalty", promptData.repetition_penalty);
  formData.append("max_new_tokens", promptData.max_new_tokens);
  formData.append("chat_prompt_size", promptData.chat_prompt_size);
  formData.append("top_k", promptData.top_k);
  formData.append("top_p", promptData.top_p);
  formData.append("clear_previous", clearPrevious);
  formData.append("temperature", promptData.temperature);
  formData.append("chat_type", chat_type);
  formData.append("clear_memory", clear_memory);
  formData.append(
    "stopping_strings",
    getStoppingStringsForAIRsp(promptData.ainame, promptData.username)
  );
  return await postFormData(URL_CHAT, formData)
    .then((data) => {
      return data.json();
    })
    .then(async (data) => {
      return [
        data.output,
        data.prompt,
        data.generate_ans_time,
        data.generate_prompt_time,
        data.speed,
      ];
    })
    .catch((e) => {
      return "Server is not running";
    });
}

const users = {
  0: "You",
  1: "Ella",
};

const customBubble = (props) => (
  <div>
    <p>{`${props.message.senderName} ${props.message.id ? "says" : "said"}: ${
      props.message.message
    }`}</p>
  </div>
);

class PdfChatComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      messages: [],
      history: [],
      useCustomBubble: false,
      curr_user: 0,
      selected: 0,
      prompt: "",
      chat_type: CHAT_TYPE,
      file_name_chat: JSON_FILE_NAMES.pdfChat,
      file_name_prompt: JSON_FILE_NAMES.pdfPrompt,
      clear_memory: false,
      speed: -1,
      generate_ans_time: -1,
      generate_prompt_time: -1,
      selectedFile: null,
      userMessage: "",
      page_index: 1,
    };
  }
  async componentDidMount() {
    const [messages, history, prompt] = await readChatJson(
      this.state.file_name_chat
    );
    const messageElements = [];
    messages.forEach((message) => {
      const messageElement = {
        id: message.id,
        message:
          message.senderName === users[1] ? (
            <div style={{ color: "black", margin: 0 }}>
              <ReactMarkdown
                className="nomargin"
                style={{ margin: 0 }}
                remarkPlugins={[remarkGfm]}
              >
                {this.parseMarkdownTable(message.message)}
              </ReactMarkdown>
            </div>
          ) : (
            message.message
          ),
        senderName: message.senderName,
      };
      messageElements.push(messageElement);
    });

    this.setState({ messages: messageElements, history, prompt });
  }

  onPress(user) {
    this.setState({ curr_user: user });
  }

  async onMessageSubmit(e) {
    // const input = this.state.userMessage;
    e.preventDefault();
    // if (!input.value) {
    //   return false;
    // }
    const text = this.state.userMessage;
    this.pushUserMessage(this.state.curr_user, text);
    this.setState({ userMessage: "" });
    const clearPrevious = this.state.history.length === 1;
    const [reply, prompt, generate_ans_time, generate_prompt_time, speed] =
      await GetAIAnswer(
        text,
        this.state.selected,
        clearPrevious,
        this.state.file_name_prompt,
        this.state.chat_type,
        this.state.clear_memory,
        this.state.selectedFile
      );
    this.pushEllaMessage(reply, prompt);
    this.setState({
      clear_memory: false,
      generate_ans_time,
      generate_prompt_time,
      speed,
    });
  }

  pushUserMessage(recipient, message) {
    const prevState = this.state;
    const newMessage = new Message({
      id: recipient,
      message,
      senderName: users[recipient],
    });
    prevState.history.push({ message, id: recipient });
    prevState.messages.push(newMessage);
    this.setState(this.state);
  }

  parseMarkdownTable(message) {
    const count = (message.match(/\|\s*\|/g) || []).length;
    if (count < 1) return message;
    message = message.replace(/\|\s*\|/g, "|\n|");
    message = message.replace(/\|/, "\n|");
    const lastPipeIndex = message.lastIndexOf("|");
    message =
      message.slice(0, lastPipeIndex + 1) +
      "\n\n" +
      message.slice(lastPipeIndex + 1);
    return message;
  }

  pushEllaMessage(message, prompt) {
    const prevState = this.state;
    const messageElement = (
      <div style={{ color: "black", margin: 0 }}>
        <ReactMarkdown
          className="nomargin"
          style={{ margin: 0 }}
          remarkPlugins={[remarkGfm]}
        >
          {this.parseMarkdownTable(message)}
        </ReactMarkdown>
      </div>
    );
    const newMessage = new Message({
      id: 1,
      message: messageElement,
      senderName: "Ella",
    });
    prevState.messages.push(newMessage);
    prevState.history.push({ message, id: 1 });
    this.setState({ ...this.state, prompt });
    saveChatJson(this.state.history, prompt, this.state.file_name_chat);
  }

  setSelected(x) {
    this.setState({ selected: x });
  }

  onClearHistory(e) {
    e.preventDefault();
    this.setState({ clearPrevious: true });
    this.setState({ messages: [] });
    this.setState({ history: [] });
    saveChatJson(
      this.state.history,
      this.state.prompt,
      this.state.file_name_chat
    );
  }

  onClearMemory(e) {
    e.preventDefault();
    this.setState({ clear_memory: !this.state.clear_memory });
  }

  onFileChange = (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0] || this.state.selectedFile) this.setSelected(2);
    else this.setSelected(0);
  };

  // On file upload (click the upload button)
  onFileUpload = () => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append(
      "myFile",
      this.state.selectedFile,
      this.state.selectedFile.name
    );

    // Details of the uploaded file

    // Request made to the backend api
    // Send formData object
    // axios.post("api/uploadfile", formData);
  };

  // File content to be displayed after
  // file upload is complete
  fileData = () => {
    if (this.state.selectedFile) {
      return (
        <div>
          <h2>File Details:</h2>
          <p>File Name: {this.state.selectedFile.name}</p>

          <p>File Type: {this.state.selectedFile.type}</p>

          <p>
            Last Modified:{" "}
            {this.state.selectedFile.lastModifiedDate.toDateString()}
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <h4>Choose before Pressing the Upload button</h4>
        </div>
      );
    }
  };
  // page control
  onClickTab(index) {
    this.setState({ page_index: index });
  }

  render() {
    return (
      <div className="chat-box-section " style={{ minWidth: 300 }}>
        <div className="single-settings-box profile-details-box overflow-hidden">
          <h3
            className="title pt-4"
            style={{
              textAlign: "center",
              marginBottom: 50,
            }}
          >
            Story Tuned Chat (RAG)
          </h3>
        </div>
        <div className="profile-details-tab">
          <div className="advance-tab-button mb--30">
            <ul
              className="nav nav-tabs tab-button-style-2 justify-content-start"
              id="settinsTab-4"
              role="tablist"
            >
              <li role="presentation">
                <a
                  href="#"
                  className={`tab-button ${
                    this.state.page_index === 1 && "active"
                  }`}
                  data-bs-toggle="tab"
                  role="tab"
                  aria-selected={this.state.page_index === 1}
                  onClick={() => this.onClickTab(1)}
                >
                  <span className="title">Chat</span>
                </a>
              </li>
              <li role="presentation">
                <a
                  href="#"
                  className={`tab-button ${
                    this.state.page_index === 2 && "active"
                  }`}
                  data-bs-toggle="tab"
                  role="tab"
                  aria-selected={this.state.page_index === 2}
                  onClick={() => this.onClickTab(2)}
                >
                  <span className="title">Selected story contents</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="tab-content">
          <div
            className={`tab-pane fade ${
              this.state.page_index === 1 && "active show"
            }`}
            role="tabpanel"
          >
            <ChatList
              message={this.state.messages}
              aiName={users[1]}
              userName={users[0]}
            />
            <StaticBarDashboard
              onSubmit={(e) => this.onMessageSubmit(e)}
              onChangeTextarea={(e) => {
                this.setState({ userMessage: e.target.value });
              }}
              textareaValue={this.state.userMessage}
              onClearHistory={(e) => this.onClearHistory(e)}
            />
          </div>
          <div
            className={`tab-pane fade ${
              this.state.page_index === 2 && "active show"
            }`}
            role="tabpanel"
          >
            <div className="container" style={{ marginTop: 0 }}>
              <div
                style={{
                  direction: "rtl",
                  width: "100%",
                  padding: 10,
                  fontSize: 15,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {this.state.generate_ans_time != -1 ? (
                  <p>
                    Prompt was generated in {this.state.generate_prompt_time} s.
                    Answer was generated in {this.state.generate_ans_time} s.
                    Speed is {this.state.speed} t/s
                  </p>
                ) : null}
              </div>
              <div
                style={{
                  width: "300px",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 10,
                }}
              >
                <div>
                  <h3>Select the essay</h3>

                  <Button
                    onClick={() => this.setSelected(0)}
                    style={{
                      width: "100%",
                      height: "50px",
                      textTransform: "none",
                      fontSize: "15px",
                      color: "black",
                      backgroundColor:
                        this.state.selected == 0 ? "grey" : "white",
                      borderColor: "black",
                      marginBottom: "10px",
                    }}
                    variant="outlined"
                  >
                    Pinocchio Ch 1-7
                  </Button>
                  <Button
                    onClick={() => this.setSelected(1)}
                    style={{
                      width: "100%",
                      height: "50px",
                      textTransform: "none",
                      fontSize: "15px",
                      color: "black",
                      backgroundColor:
                        this.state.selected == 1 ? "grey" : "white",
                      borderColor: "black",
                      marginBottom: "10px",
                    }}
                    variant="outlined"
                  >
                    Extra-good Sunday
                  </Button>
                </div>
              </div>
              <div
                style={{
                  border: "1px solid",
                  borderColor: "grey",
                  borderRadius: 10,
                  width: "100%",
                  // padding: 10,
                  marginTop: 20,
                  fontSize: 15,
                }}
              >
                <p style={{ margin: 15 }}>
                  <Button
                    onClick={() => {
                      this.setState({ userMessage: examples[0] });
                    }}
                    style={{
                      width: "100%",
                      textTransform: "none",
                      fontSize: "15px",
                      color: "black",
                      backgroundColor: "white",
                      borderColor: "black",
                      textAlign: "left",
                    }}
                  >
                    {examples[0]}
                  </Button>
                </p>
                <Divider></Divider>
                <p style={{ margin: 15 }}>
                  <Button
                    onClick={() => {
                      this.setState({ userMessage: examples[1] });
                    }}
                    style={{
                      width: "100%",
                      textTransform: "none",
                      fontSize: "15px",
                      color: "black",
                      backgroundColor: "white",
                      borderColor: "black",
                      textAlign: "left",
                    }}
                  >
                    {examples[1]}
                  </Button>
                </p>
                <Divider></Divider>
                <p style={{ margin: 15 }}>
                  <Button
                    onClick={() => {
                      this.setState({ userMessage: examples[2] });
                    }}
                    style={{
                      width: "100%",
                      textTransform: "none",
                      fontSize: "15px",
                      color: "black",
                      backgroundColor: "white",
                      borderColor: "black",
                      textAlign: "left",
                    }}
                  >
                    {examples[2]}
                  </Button>
                </p>
              </div>
              <div style={{ marginTop: "10px" }}>
                <h3>Selected story contents:</h3>
                <textarea
                  fullWidth
                  width={1000}
                  multiline={true}
                  readOnly={true}
                  rows={50}
                  style={{
                    marginTop: 0,
                    marginBottom: 50,
                    width: "100%",
                    borderRadius: 5,
                    padding: 5,
                  }}
                  size="small"
                  color="primary"
                  type="text"
                  value={getSubtext(
                    "STORY:",
                    "Your mission is to mimic",
                    this.state.prompt
                  )}
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div className="container" style={{ marginTop: 0 }}></div>
      </div>
    );
  }
}

export default PdfChatComponent;
