import React from "react";
import styles from "./SpeechAnalysisComp.module.scss";
import { Tooltip } from "react-tooltip";

interface IPronunciationAssessmentTextProps {
  words: TSpeechAnalysisWordItem[];
}
const PronunciationAssessmentTexts = ({
  words,
}: IPronunciationAssessmentTextProps) => {
  const [tooltipTarget, setTooltipTarget] = React.useState("");
  const [wordPronAccuracyScore, setWordPronAccuracyScore] = React.useState(0);
  const [tooltipContentItem, setTooltipContentItem] = React.useState<
    TSpeechAnalysisWordPhoneme[]
  >([]);
  const [tooltipErrorType, setTooltipErrorType] = React.useState<string[]>([]);
  return (
    <div className={styles.wordResultContainer}>
      {words.length > 0 &&
        words.map((word: TSpeechAnalysisWordItem, wordIndex: number) => {
          let errorTypesArray = [];
          const wordKey = `wordP-${word.Word}Ptag${wordIndex}`;
          const wordPronErrorType = word.PronunciationAssessment.ErrorType;

          const wordPronunciation =
            word.PronunciationAssessment.AccuracyScore || 0;
          const unexpectedBreakTarget =
            word.PronunciationAssessment.Feedback?.Prosody.Break.ErrorTypes;
          const missingBreakTarget =
            word.PronunciationAssessment.Feedback?.Prosody.Break.ErrorTypes;
          const MonotoneTarget =
            word.PronunciationAssessment.Feedback?.Prosody.Intonation
              .ErrorTypes;
          const isMonotone =
            MonotoneTarget && MonotoneTarget.includes("Monotone")
              ? true
              : false;
          if (wordPronErrorType !== "" && wordPronErrorType !== "None")
            errorTypesArray.push(wordPronErrorType);
          if (unexpectedBreakTarget)
            errorTypesArray.push(...unexpectedBreakTarget);
          if (missingBreakTarget) errorTypesArray.push(...missingBreakTarget);
          if (MonotoneTarget) errorTypesArray.push(...MonotoneTarget);
          const errorTypesSet = new Set(
            errorTypesArray.filter((p) => p !== "None")
          );
          errorTypesArray = [...errorTypesSet];
          const displayWord = `${word.Word}${word.Punctuation || ""}`;
          return (
            <p key={wordKey} className={isMonotone ? styles.wavyUnderline : ""}>
              <span>
                {unexpectedBreakTarget &&
                  unexpectedBreakTarget.includes("UnexpectedBreak") && (
                    <span>
                      <span className={styles["UnexpectedBreak"]}>{`[ ]`}</span>
                    </span>
                  )}
                {missingBreakTarget &&
                  missingBreakTarget.includes("MissingBreak") && (
                    <span>
                      <span className={styles["MissingBreak"]}>{`[ ]`}</span>
                    </span>
                  )}
                <span
                  id={wordKey}
                  onMouseEnter={() => {
                    setTooltipTarget(displayWord);
                    setWordPronAccuracyScore(wordPronunciation);
                    if (word.Phonemes) {
                      setTooltipContentItem(word.Phonemes);
                      setTooltipErrorType(errorTypesArray);
                    }
                  }}
                  onMouseLeave={() => {
                    setTooltipTarget("");
                    setWordPronAccuracyScore(0);
                    setTooltipContentItem([]);
                    setTooltipErrorType([]);
                  }}
                >
                  <span className={styles[wordPronErrorType]}>
                    {displayWord}
                  </span>
                </span>
              </span>
            </p>
          );
        })}
      <Tooltip
        anchorSelect={`[id^='wordP-']`}
        style={{
          borderRadius: "10px",
        }}
      >
        <div className={styles.tooltipContent}>
          <div className={styles.tooltipScore}>
            <span>
              {tooltipTarget} : {wordPronAccuracyScore}
            </span>
          </div>
          <div>
            <span className={styles.errorTypesBadge}>
              {"error types :"}
              {tooltipErrorType.map((types, i) => {
                const key = `tooltip-error-type-${types}-${i}`;
                return (
                  <span className={styles[types]} key={key}>
                    {types}
                  </span>
                );
              })}
            </span>
          </div>
          <div className={styles.tooltipContentItem}>
            {tooltipContentItem.map((phoneme, phonemeIndex) => {
              return (
                <div
                  key={`tooltipContentItem-${phoneme.Phoneme}-${phonemeIndex}`}
                >
                  <span>{phoneme.Phoneme}</span>
                  <span>{phoneme.PronunciationAssessment.AccuracyScore}</span>
                </div>
              );
            })}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};
export default PronunciationAssessmentTexts;
