import React from "react";
import NHRagChatComponent from "./NHRagComponent";

export default function NHRagChat() {
  return (
    <div className="App">
      <NHRagChatComponent />
    </div>
  );
}
