import React from "react";
import styles from "./SpeechAnalysisComp.module.scss";
import { sampleDatas } from "components/mantine-charts/dumyData";
import PronunciationAssessmentScores from "./PronunciationAssessmentScores";
import PronunciationAssessmentTexts from "./PronunciationAssessmentTexts";
import PronunciationAssessmentOriginalText from "./PronunciationAssessmentOriginalText";
import RecordButton from "./RecordButton";

interface ISpeechAnalysisCompProps {}
const initScore = {
  AccuracyScore: 0,
  CompletenessScore: 0,
  FluencyScore: 0,
  PronScore: 0,
  ProsodyScore: 0,
};

export default function SpeechAnalysisComp(props: ISpeechAnalysisCompProps) {
  const [targetText, setTargetText] = React.useState<string>("");
  const [data, setData] = React.useState<TSpeechAnalysisData>([]);
  const [scores, setScores] =
    React.useState<TPronunciationAssessment>(initScore);
  const [words, setWords] = React.useState<TSpeechAnalysisWordItem[]>([]);
  const [textValue, setTextValue] = React.useState("");

  const getAllWords = async (
    dataAll: TSpeechAnalysisData,
    originText: string
  ) => {
    console.log("=== getAllWords ===");
    // text split&indexing
    const originalTextSplit: {
      index: number;
      word: string;
    }[] = originText.split(" ").map((v, i) => ({ index: i, word: v }));

    // DisplayText의 단어들을 배열로 변환
    const displayWords = dataAll.flatMap((dataItem) =>
      dataItem.NBest.flatMap((nBestItem) => nBestItem.Words)
    );

    // Word에 없는 원본 텍스트
    const replaceRegex = /[.,\/#!$%\^&\*;:{}=\-_`~()"'?!]/g;
    let allDisplayWords: TSpeechAnalysisWordItem[] = [];
    // let findTextIndex = -1;
    let omissionCount = 0;
    for await (const wordData of displayWords) {
      if (wordData) {
        // console.log("\n\nwordData.Word :", wordData.Word);
        const findOriginalSplitIndex = originalTextSplit.findIndex(
          (p) =>
            p.word.replace(replaceRegex, "").toLowerCase() ===
            wordData.Word.toLowerCase()
        );
        if (wordData.PronunciationAssessment.ErrorType === "Insertion") {
          originalTextSplit.splice(0, 1);
          allDisplayWords.push(wordData);
          // findTextIndex += 1;
        } else {
          if (findOriginalSplitIndex !== -1) {
            // omission word array
            let addTextSplit = originalTextSplit.splice(
              0,
              findOriginalSplitIndex
            );
            const addDisplayWords: TSpeechAnalysisWordItem[] = addTextSplit.map(
              (addText) => {
                return {
                  Word: addText.word,
                  PronunciationAssessment: {
                    ErrorType: "Omission",
                  },
                  Phonemes: [],
                };
              }
            );
            omissionCount += addDisplayWords.length;
            originalTextSplit.splice(0, 1);
            allDisplayWords.push(...addDisplayWords, wordData);
            // findTextIndex += 1;
          }
        }
      }
    }

    return { allDisplayWords, omissionCount };
  };

  const getCalculateScores = (
    dataAll: TSpeechAnalysisData,
    totlaWordCount: number,
    omissionCount: number
  ) => {
    let scoresAll = {
      AccuracyScore: 0,
      CompletenessScore: 0,
      FluencyScore: 0,
      PronScore: 0,
      ProsodyScore: 0,
    };
    let hasAllScore = false;
    let sentenceCount = 0;
    dataAll.forEach((dataItem) => {
      dataItem.NBest.forEach((nBestItem) => {
        hasAllScore = true;
        sentenceCount += 1;
        scoresAll.AccuracyScore += nBestItem.PronunciationAssessment
          ? nBestItem.PronunciationAssessment.AccuracyScore
          : 0;
        scoresAll.CompletenessScore += nBestItem.PronunciationAssessment
          ? nBestItem.PronunciationAssessment.CompletenessScore
          : 0;
        scoresAll.FluencyScore += nBestItem.PronunciationAssessment
          ? nBestItem.PronunciationAssessment.FluencyScore
          : 0;
        scoresAll.PronScore += nBestItem.PronunciationAssessment
          ? nBestItem.PronunciationAssessment.PronScore
          : 0;
        scoresAll.ProsodyScore += nBestItem.PronunciationAssessment
          ? nBestItem.PronunciationAssessment.ProsodyScore
          : 0;
      });
    });
    if (hasAllScore) {
      const isNotOmissionWordCount = totlaWordCount - omissionCount;
      const accuracyScorePerSentenct = Math.round(
        scoresAll.AccuracyScore / sentenceCount
      );
      const accuracyScores = accuracyScorePerSentenct * isNotOmissionWordCount;
      const completenessScorePerSentence = Math.round(
        scoresAll.CompletenessScore / sentenceCount
      );
      const completenessScores =
        completenessScorePerSentence * isNotOmissionWordCount;

      scoresAll.AccuracyScore = Math.round(accuracyScores / totlaWordCount);
      scoresAll.CompletenessScore = Math.round(
        completenessScores / totlaWordCount
      );
      scoresAll.FluencyScore = Math.round(
        scoresAll.FluencyScore / sentenceCount
      );

      scoresAll.ProsodyScore = Math.round(
        scoresAll.ProsodyScore / sentenceCount
      );

      scoresAll.PronScore = Number(
        (
          scoresAll.AccuracyScore * 0.4 +
          scoresAll.CompletenessScore * 0.2 +
          scoresAll.FluencyScore * 0.2 +
          scoresAll.ProsodyScore * 0.2
        ).toFixed(0)
      );
    }
    return scoresAll;
  };
  const setStatesData = React.useCallback(
    async (
      value: TSpeechAnalysisData,
      targetText: string,
      isSubmit?: boolean
    ) => {
      setData(value);
      const allWords = await getAllWords(value, targetText);
      const calcScores = getCalculateScores(
        value,
        allWords.allDisplayWords.length,
        allWords.omissionCount
      );
      if (isSubmit) {
        setTargetText(targetText);
      }
      setScores(calcScores);
      setWords(allWords.allDisplayWords);
    },
    []
  );

  React.useEffect(() => {
    if (data.length === 0 && targetText === "" && words.length === 0) {
      setTargetText(sampleDatas[0].scriptText);
      setTextValue(sampleDatas[0].scriptText);
      setStatesData(sampleDatas[0].data, sampleDatas[0].scriptText);
    }
  }, []);
  return (
    <div className={styles.speechAnalysisContainer}>
      <div className={styles.speechAnalysisButtonContainer}>
        <div className={styles.speechAnalysisSmaples}>
          {sampleDatas.map((sample, sampleIndex) => {
            const sampleKey = `sample-button-${sampleIndex}-${sample.name}`;
            return (
              <button
                type="button"
                key={sampleKey}
                onClick={() => {
                  setTargetText(sample.scriptText);
                  setTextValue(sample.scriptText);
                  setStatesData(sample.data, sample.scriptText);
                }}
              >
                {sample.name}
              </button>
            );
          })}
        </div>
        <RecordButton
          referenceText={textValue}
          setStatesData={async (value, targetText) =>
            await setStatesData(value, targetText, true)
          }
        />
      </div>
      <PronunciationAssessmentOriginalText
        text={textValue}
        setText={setTextValue}
      />
      <PronunciationAssessmentTexts words={words} />
      <PronunciationAssessmentScores scores={scores} />
    </div>
  );
}
