import React from "react";
type TDropdownButtonProps = {
  label: string;
  select: string;
  onSelect: (value: string) => void;
  selectData: string[];
};
const DropdownButton = ({
  label,
  onSelect,
  select,
  selectData,
}: TDropdownButtonProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  // const [selected, setSelected] = React.useState(selectData[0]);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const handleSelect = (value: string) => {
    setIsOpen(false);
    onSelect(value);
  };
  return (
    <div className="select-area">
      <h6 className="text">{label}</h6>
      <div
        className="rbt-modern-select bg-transparent height-45"
        onClick={handleToggle}
        style={{ position: "relative", cursor: "pointer" }}
      >
        <div className="selected-option">{select}</div>
        {isOpen && (
          <ul
            className="options-list"
            style={{
              position: "absolute",
              top: "100%",
              left: 0,
              width: "100%",
              background: "white",
              border: "1px solid #ccc",
              zIndex: 10,
              maxHeight: "150px",
              overflowY: "auto",
              listStyle: "none",
              padding: 0,
              margin: 0,
            }}
          >
            {selectData.map((data, index) => (
              <li
                key={`custom-dropdown-data-${data}-${index}`}
                onClick={() => handleSelect(data)}
                style={{
                  padding: "10px",
                  cursor: "pointer",
                  borderBottom: "1px solid #f0f0f0",
                }}
              >
                <a>{data}</a>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
export default DropdownButton;
