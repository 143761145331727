import React from "react";
interface IHorizontalLineChartProps {
  score: number;
}
// 0이상 60 미만
// 60이상 80미만
// 80이상
const GREEN = {
  fill: "#66ff4b",
  stroke: "#d9ffd2",
};
//f9e354
const YELLOW = {
  fill: "#ffff00",
  stroke: "#ffffbb",
};
const RED = {
  fill: "#E50709",
  stroke: "#F58789",
};
export default function HorizontalLineChart({
  score,
}: IHorizontalLineChartProps) {
  const [fill, setFill] = React.useState("#8884d8");
  const [backFill, setBackFill] = React.useState("#CCC");

  React.useEffect(() => {
    const updateColors = (value: number) => {
      if (value >= 80) {
        setFill(GREEN.fill);
        setBackFill(GREEN.stroke);
      } else if (value >= 60 && value < 80) {
        setFill(YELLOW.fill);
        setBackFill(YELLOW.stroke);
      } else {
        setFill(RED.fill);
        setBackFill(RED.stroke);
      }
    };
    updateColors(score);
  }, [score]);
  return (
    <svg width={"100%"} height={15}>
      <rect width={"100%"} height={15} fill={backFill} rx={4} ry={4} />
      <rect width={`${score}%`} height={15} fill={fill} rx={4} ry={4} />
    </svg>
  );
}
