import { Outlet } from "react-router-dom";
import Sidebar from "../common/Sidebar";
import React from "react";
import BackToTop from "../common/BackToTop";
import BackToBottom from "../common/BackToBottom";

const MainLayout = () => {
  const [openSidebar, setOpenSidebar] = React.useState(false);
  const [isLightTheme, setLightTheme] = React.useState(false);

  const checkScreenSize = () => {
    if (window.innerWidth < 1600) {
      setOpenSidebar(true);
    } else {
      setOpenSidebar(false);
    }
  };
  // ===========> Switcher Function START
  React.useEffect(() => {
    const themeType = localStorage.getItem("aiwave-theme");
    if (themeType === "dark") {
      setLightTheme(false);
      document.body.classList.add("active-dark-mode");
    }
  }, []);

  React.useEffect(() => {
    if (isLightTheme) {
      document.body.classList.remove("active-dark-mode");
      localStorage.setItem("aiwave-theme", "light");
    } else {
      document.body.classList.add("active-dark-mode");
      localStorage.setItem("aiwave-theme", "dark");
    }
  }, [isLightTheme]);

  const toggleTheme = () => {
    setLightTheme((prevTheme) => !prevTheme);
  };
  // ===========> Switcher Function END
  React.useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  return (
    <main className="page-wrapper rbt-dashboard-page">
      <div className="rbt-panel-wrapper">
        {/* side */}
        <Sidebar
          openSidebar={openSidebar}
          toggleTheme={toggleTheme}
          isLightTheme={isLightTheme}
        />

        <div className="expand-btn-grp">
          <button
            className={`bg-solid-primary popup-dashboardleft-btn ${
              !openSidebar ? "" : "collapsed"
            }`}
            onClick={() => setOpenSidebar(!openSidebar)}
          >
            <i className="fa-sharp fa-regular fa-sidebar"></i>
          </button>
        </div>
        <div className="rbt-main-content">
          <div className="rbt-daynamic-page-content">
            <div className="rbt-dashboard-content">
              <div className="content-page">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
        <BackToTop />
        <BackToBottom />
      </div>
    </main>
  );
};

export default MainLayout;
