import React from "react";
import styles from "./SpeechAnalysisWithTopicComp.module.scss";
import PronunciationAssessmentScores from "./PronunciationAssessmentScores";
import PronunciationAssessmentContentScores from "./PronunciationAssessmentContentScores";
import RecordWithTopicButton from "./RecordWithTopicButton";
import { sampleTopicData } from "components/mantine-charts/dumyTopicData";
import PronunciationAssessmentTextsWithTopic from "./PronunciationAssessmentTextsWithTopic";

interface ISpeechAnalysisWithTopicCompProps {}
const defKey = "example-button-speech-analysis-withtopic";
const exampleTopics = [
  {
    key: `${defKey}-0`,
    topic: "What’s your favorite game to play?",
  },
  {
    key: `${defKey}-1`,
    topic: "Who do you love? Why?",
  },
  {
    key: `${defKey}-2`,
    topic: "When did you last say, “I do!”",
  },
  {
    key: `${defKey}-3`,
    topic: "What’s your favorite story?",
  },
];
const initTopic = "Talk about your day today";
const initScore = {
  AccuracyScore: 0,
  CompletenessScore: 0,
  FluencyScore: 0,
  PronScore: 0,
  ProsodyScore: 0,
};
const initContentScore = {
  GrammarScore: 0,
  VocabularyScore: 0,
  TopicScore: 0,
};
export default function SpeechAnalysisWithTopicComp(
  props: ISpeechAnalysisWithTopicCompProps
) {
  const [topic, setTopic] = React.useState("");
  const [data, setData] = React.useState<TSpeechAnalysisData>([]);
  const [words, setWords] = React.useState<TSpeechAnalysisWordItem[]>([]);
  const [pronScores, setPronScores] =
    React.useState<TPronunciationAssessment>(initScore);
  const [contentScores, setContentScores] =
    React.useState<TContentAssessment>(initContentScore);
  const [isFetching, setIsFetching] = React.useState(false);

  const regexpSC = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\('"]/;
  const regexpABC = /[a-z]/gmi;
  const getAllWords = async (dataAll: TSpeechAnalysisData) => {
    console.log("=== getAllWords : START ===");
    const displayWords = dataAll.flatMap((dataItem) =>
      dataItem.NBest.flatMap((nBestItem) => {
        if (nBestItem.Words) {
          let displayText: { index:number; word: string; target: string; punctuation:string }[] = []
          nBestItem.Display.split(" ").forEach((p,i) => {
            if (regexpSC.test(p)) {
              displayText.push({ index:i, word: p, target: p.replace(regexpSC, ""), punctuation: p.replace(regexpABC,'') })
            }
          });
          // console.log("found SC :displayText :", displayText);
          return nBestItem.Words.map((v,i) => {
            
            const foundIdx = displayText.findIndex(p => p.target.trim() === v.Word && p.index===i)
            if (foundIdx>0 && v.Punctuation==undefined) {
              // console.log('foundIdx :',foundIdx,', v :',v.Word)
              v.Punctuation = displayText[foundIdx].punctuation;
            }
            return v;
          });
        }
      })
    );
    let allDisplayWords: TSpeechAnalysisWordItem[] = [];
    // ommision 없음
    for await (const wordData of displayWords) {
      if (wordData) {
        allDisplayWords.push(wordData);
      }
    }
    return { allDisplayWords };
  };

  const getCalculateScores = (
    dataAll: TSpeechAnalysisData,
    totlaWordCount: number
    // omissionCount: number
  ) => {
    console.log("=== getCalculateScores : START ===");
    let scoresAll = {
      AccuracyScore: 0,
      CompletenessScore: 0,
      FluencyScore: 0,
      PronScore: 0,
      ProsodyScore: 0,
    };
    let contentScoreAll = {
      GrammarScore: 0,
      VocabularyScore: 0,
      TopicScore: 0,
      averageScore: 0,
    };
    let hasAllScore = false;
    let sentenceCount = 0;
    const sentenceLength = dataAll.length;
    // console.log("sentenceLength :", sentenceLength);
    dataAll.forEach((dataItem) => {
      dataItem.NBest.forEach((nBestItem) => {
        // hasAllScore = true;
        if (nBestItem.PronunciationAssessment) {
          scoresAll.AccuracyScore +=
            nBestItem.PronunciationAssessment.AccuracyScore;
          scoresAll.CompletenessScore +=
            nBestItem.PronunciationAssessment.CompletenessScore;
          scoresAll.FluencyScore +=
            nBestItem.PronunciationAssessment.FluencyScore;
          scoresAll.PronScore += nBestItem.PronunciationAssessment.PronScore;
          scoresAll.ProsodyScore +=
            nBestItem.PronunciationAssessment.ProsodyScore;
        }
        if (nBestItem.ContentAssessment) {
          contentScoreAll.GrammarScore +=
            nBestItem.ContentAssessment.GrammarScore;
          contentScoreAll.TopicScore += nBestItem.ContentAssessment.TopicScore;
          contentScoreAll.VocabularyScore +=
            nBestItem.ContentAssessment.VocabularyScore;
        }
        sentenceCount++;
      });
      if (sentenceCount === sentenceLength) hasAllScore = true;
    });
    if (hasAllScore) {
      // 발음 점수
      sentenceCount--; // last index is content Assessment
      const accuracyScorePerSentenct = Math.round(
        scoresAll.AccuracyScore / sentenceCount
      );
      const accuracyScores = accuracyScorePerSentenct * totlaWordCount;
      const completenessScorePerSentence = Math.round(
        scoresAll.CompletenessScore / sentenceCount
      );
      const completenessScores = completenessScorePerSentence * totlaWordCount;

      scoresAll.AccuracyScore = Math.round(accuracyScores / totlaWordCount);
      scoresAll.CompletenessScore = Math.round(
        completenessScores / totlaWordCount
      );
      scoresAll.FluencyScore = Math.round(
        scoresAll.FluencyScore / sentenceCount
      );

      scoresAll.ProsodyScore = Math.round(
        scoresAll.ProsodyScore / sentenceCount
      );

      scoresAll.PronScore = Number(
        (
          scoresAll.AccuracyScore * 0.4 +
          scoresAll.CompletenessScore * 0.2 +
          scoresAll.FluencyScore * 0.2 +
          scoresAll.ProsodyScore * 0.2
        ).toFixed(0)
      );
      // 콘텐츠 점수
      contentScoreAll.averageScore = Number(
        (
          (contentScoreAll.GrammarScore +
            contentScoreAll.VocabularyScore +
            contentScoreAll.TopicScore) /
          3
        ).toFixed(0)
      );
    }
    return { scoresAll, contentScoreAll };
  };

  const setStatesData = React.useCallback(
    async (value: TSpeechAnalysisData, topic: string, isSubmit?: boolean) => {
      setData(value);
      const allWords = await getAllWords(value);
      const calcScores = getCalculateScores(
        value,
        allWords.allDisplayWords.length
      );
      // if (isSubmit) {
      //   setTopic(topic);
      // }
      setPronScores(calcScores.scoresAll);
      setContentScores(calcScores.contentScoreAll);
      setWords(allWords.allDisplayWords);
    },
    []
  );

  React.useEffect(() => {
    if (data.length === 0 && topic === "" && words.length === 0) {
      setTopic(initTopic);
      setStatesData(sampleTopicData, initTopic);
    }
  }, []);
  return (
    <div className={styles.speechAnalysisContainer}>
      <div className={styles.speechAnalysisButtonContainer}>
        <div className={styles.speechAnalysisSmaplesL}>
          <p className={styles.speechAnalysisTopic}>
            Topic<span>{`${topic.length}/60`}</span>
          </p>
          <input
            type="text"
            value={topic}
            placeholder="What topic would you like to talk about?"
            maxLength={60}
            onChange={(e) => {
              const evt = e.currentTarget.value;
              const regex = /[^a-zA-Z0-9.,?!:;'"\-(){}[\]\/&@%#*+$\s]/gm;
              if (!regex.test(evt)) {
                setTopic(evt);
              } else console.log("not allow ch");
            }}
          ></input>
        </div>
        <div className={styles.speechAnalysisSmaples}>
          {exampleTopics.map((exampleItem, exampleItemIndex) => {
            return (
              <button
                key={exampleItem.key + exampleItemIndex}
                onClick={() => setTopic(exampleItem.topic)}
              >
                {exampleItem.topic}
              </button>
            );
          })}
        </div>
      </div>
      <div className={styles.guidTextContainer}>
        <p>
          <span className={styles.green}>15초</span> 이상,{" "}
          <span className={styles.green}>50단어</span> 이상,{" "}
          <span className={styles.green}>3문장</span> 이상 말하면 더 정확한
          점수를 측정할 수 있어요.
        </p>
        <p>
          You can get a more accurate score if you speak more than{" "}
          <span className={styles.green}>15 seconds</span>,{" "}
          <span className={styles.green}>50 words</span>, and{" "}
          <span className={styles.green}>3 sentences</span>.
        </p>
      </div>
      <div>
        <RecordWithTopicButton topic={topic} setStatesData={setStatesData} />
      </div>
      <PronunciationAssessmentTextsWithTopic words={words} isFetching={isFetching}/>
      <PronunciationAssessmentScores scores={pronScores} />
      <PronunciationAssessmentContentScores scores={contentScores} />
    </div>
  );
}
