import React from "react";
import SpeechAnalysisWithTopicComp from "./components/SpeechAnalysisWithTopicComp";

type Props = {};

const SpeechAnalysisWithTopic = (props: Props) => {
  return (
    <div>
      <SpeechAnalysisWithTopicComp />
    </div>
  );
};

export default SpeechAnalysisWithTopic;
