import React, { useState } from "react";
import axios from "axios";
import styles from "./SpeechAnalysisWithTopicComp.module.scss";

interface IRecordWithTopicButtonProps {
  topic: string;
  setStatesData: (
    value: TSpeechAnalysisData,
    topic: string,
    isSubmit?: boolean
  ) => Promise<void>;
}

const RecordWithTopicButton: React.FC<IRecordWithTopicButtonProps> = ({
  topic,
  setStatesData,
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState<string | null>(null);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );

  // 녹음 시작 & 중지 event
  const handleRecord = async () => {
    if (!isRecording) {
      // start recording
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      let chunks: BlobPart[] = [];

      recorder.ondataavailable = (event) => chunks.push(event.data);
      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: "audio/wave" });
        setAudioBlob(blob);
        setAudioURL(URL.createObjectURL(blob));
      };
      recorder.start();
      setMediaRecorder(recorder);
      setIsRecording(true);
    } else {
      // stop recording
      mediaRecorder?.stop();
      setIsRecording(false);
    }
  };

  const sendAudioToAPI = async () => {
    if (!audioBlob) return;
    const formData = new FormData();
    const mimeType = "audio/wav";
    formData.append("audio", audioBlob, `recording.${mimeType.split("/")[1]}`);
    formData.append("topic", topic);
    try {
      const url = process.env.REACT_APP_AZURE_PRONUNCIATION_ASSESSMENT_SPEECH
        ? process.env.REACT_APP_AZURE_PRONUNCIATION_ASSESSMENT_SPEECH
        : "";
      console.log("url :", url);
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      });
      if (response.data.statusCode === 200) {
        if (response.data.statusCode === 200) {
          console.log("파일 전송 성공!");
          console.log("response.data.data :", response.data.data.json_str);
          const jsonData: TSpeechAnalysisData = JSON.parse(
            JSON.stringify(response.data.data.json_str)
          );
          await setStatesData(jsonData, topic, true);
        } else {
          console.error("파일 전송 실패 :", response);
        }
      }
    } catch (error) {
      console.error("API 전송 오류:", error);
    }
  };

  return (
    <div className={styles.buttonContainer}>
      <button
        className={styles.recordToolButton}
        onClick={async () => await handleRecord()}
      >
        {isRecording ? "녹음 중지" : "녹음 시작"}
      </button>
      {audioURL && (
        <audio src={audioURL} controls style={{ height: 41, minWidth: 300 }} />
      )}
      {audioURL && (
        <button className={styles.recordToolButton} onClick={sendAudioToAPI}>
          Submit
        </button>
      )}
    </div>
  );
};
export default RecordWithTopicButton;
