import React from "react";
import StaticBarForm from "./StaticBarForm";
// import { TStaticBarFormProps } from "./staticbarFormType";
type TStaticBarFormProps = {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  textareaValue: string;
  onChangeTextarea: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onClearHistory: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onBackupCurrentChat?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  onClearMemory?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  buttonDisabled?: boolean;
};
const StaticBarDashboard = (props: TStaticBarFormProps) => {
  return (
    <>
      <div className="rbt-static-bar">
        <StaticBarForm {...props} />
        <p className="b3 small-text">
          
        </p>
      </div>
    </>
  );
};

export default StaticBarDashboard;
