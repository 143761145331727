import { ReactElement } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { COOKIE_NAME_VALUES, CookieTools } from "../shared/utils/Cookies";
import React from "react";

interface PrivateRouteProps {
  children?: ReactElement;
  authentication: boolean;
}
export default function PrivateRoute({
  authentication,
}: PrivateRouteProps): any {
  // login check
  const checkLogin = () => {
    // console.log("=== checkLogin ===");
    const valueList = Object.values(COOKIE_NAME_VALUES);
    // console.log("valueList :", valueList);
    for (const cookieName of valueList) {
      const cookieValue = CookieTools.getCookie(cookieName);
      // if (
      //   cookieValue === null ||
      //   cookieValue === undefined ||
      //   cookieValue === false
      // ) {
      //   return false;
      // }
    }
    return true;
  };
  if (authentication) {
    if (!checkLogin()) {
      return <Navigate to="/" />;
    } else {
      return <Outlet />;
    }
  } else {
    return <Outlet />;
  }
  // return <Outlet />;
}
