import React from "react";
import { Tooltip } from "react-tooltip";
type TFormProps = {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  buttonDisabled?: boolean;
  // textareaValue: string;
  // onChangeTextarea: (
  //   event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  // ) => void;
  children?: any;
};
const Form = (props: TFormProps) => {
  const {
    onSubmit,
    // onChangeTextarea,
    buttonDisabled,
    // textareaValue,
    children,
  } = props;
  return (
    <>
      <Tooltip id="form-tag-tooltip" className="custom-tooltip tooltip-inner" />
      <form onSubmit={onSubmit}>
        {children && children}
        <button
          disabled={buttonDisabled}
          type="submit"
          className="save-regenerate-btn btn-default btn-small"
          style={{
            width: "100%",
            marginTop: 24,
          }}
        >
          <span className="text">Send</span>
        </button>
      </form>
    </>
  );
};
export default Form;
