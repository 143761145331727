import React from "react";
type TTextInputFormsItem = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  title: string;
  placeholder?: string;
}
type TTextInputFormProps = {
  items:TTextInputFormsItem[]
};
const TextInputForm = ({
  items
}: TTextInputFormProps) => {
  return (
    <div
      className="container custom-range-with-input-container"
      style={{ width: "100%",marginTop: 24 }}
    >
      <div className="row">
        {items.map((item,index) => {
          return (
          <div className="col" key={`common-forms-textinputforms-${index}`} style={{ paddingBottom: 10 }}>
            <p className="form-label" style={{textAlign:'left'}}>{item.title}</p>
            <input
              type="text"
              className="form-number custom-number-input"
              value={item.value}
              placeholder={item.placeholder}
              onChange={(e) => item.onChange(e)}
            />
          </div>
          )
        })}
      </div>
    </div>
  );
};
export default TextInputForm;
