import React from "react";
type TPageTitleFormProps = {
  title: string;
  descriptionTitle?: string;
  descriptionText?: string;
};
const PageTitleForm = ({
  title,
  descriptionText,
  descriptionTitle,
}: TPageTitleFormProps) => {
  return (
    <div className="single-settings-box profile-details-box overflow-hidden">
      <h3
        className="title pt-4"
        style={{
          textAlign: "center",
          marginBottom: 50,
        }}
      >
        {title}
      </h3>
      <div className="section-title" style={{ marginBottom: 0 }}>
        <h4 className="title mb-0">
          {descriptionTitle ? descriptionTitle : "Prompt :"}
        </h4>
        <p className="description" style={{ marginBottom: 0 }}>
          <span className="icon" style={{ marginRight: 8 }}>
            <i className="feather-info"></i>
          </span>
          {descriptionText}
        </p>
      </div>
    </div>
  );
};

export default PageTitleForm;
