import { Avatar, Button, Drawer, List, Stack, Toolbar } from "@mui/material";
import assets from "../../assets";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import { appRoutes } from "../../routes/appRoutes";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";
import { Link } from "react-router-dom";
import React from "react";

import light from "../../tools/images/light/switch/sun-01.svg";
import dark from "../../tools/images/light/switch/vector.svg";

const Sidebar = (props: any) => {
  return (
    <div
      className={`rbt-left-panel popup-dashboardleft-section ${
        props.openSidebar ? "collapsed" : ""
      }`}
    >
      <div className="rbt-default-sidebar">
        <div className="inner">
          <div className="content-item-content">
            <div className="">
              <Link
                to="/"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 50,
                  marginBottom: 70,
                }}
              >
                <img
                  src={
                    props.isLightTheme
                      ? assets.images.logo.dark
                      : assets.images.logo.light
                  }
                  style={{
                    width: 301 * 0.8,
                    height: 167 * 0.8,
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="rbt-default-sidebar-wrapper">
              <nav className="mainmenu-nav">
                <ul className="dashboard-mainmenu rbt-default-sidebar-list">
                  {appRoutes.map((route, index) =>
                    route.sidebarProps ? (
                      route.child ? (
                        <SidebarItemCollapse item={route} key={index} />
                      ) : (
                        <SidebarItem item={route} key={index} />
                      )
                    ) : null
                  )}
                </ul>
              </nav>
            </div>
            <div className="switcher-btn-gr inner-switcher">
              <button
                className={`${props.isLightTheme ? "active" : ""}`}
                onClick={props.toggleTheme}
              >
                <img
                  src={dark}
                  alt="Switcher Image"
                  width={18}
                  height={18}
                  style={{ width: 18, height: 18 }}
                />
                <span className="text">Dark</span>
              </button>
              <button
                className={`${!props.isLightTheme ? "active" : ""}`}
                onClick={props.toggleTheme}
              >
                <img
                  src={light}
                  alt="Switcher Image"
                  style={{ width: 18, height: 18 }}
                />
                <span className="text">Light</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <Drawer
    //   variant="permanent"
    //   sx={{
    //     width: sizeConfigs.sidebar.width,
    //     flexShrink: 0,
    //     "& .MuiDrawer-paper": {
    //       width: sizeConfigs.sidebar.width,
    //       borderRight: "0px",
    //       height: "calc(100% - 14px - 14px)",
    //       marginTop: "14px",
    //       marginBottom: "14px",
    //       marginLeft: "14px",
    //       borderRadius: ".8em",
    //       backgroundColor: colorConfigs.sidebar.bg,
    //       color: colorConfigs.sidebar.color,
    //     },
    //   }}
    // >
    //   <List disablePadding>
    //     <Toolbar sx={{ marginBottom: "20px" }}>
    //       <Stack sx={{ width: "100%" }} direction="row" justifyContent="center">
    //         <Link to="/">
    // <Avatar
    //   src={assets.images.logo}
    //   style={{
    //     borderRadius: 0,
    //     width: 80 * 1.3,
    //     height: 44 * 1.3,
    //     marginTop: 50,
    //     marginBottom: 50,
    //   }}
    // />
    //         </Link>
    //       </Stack>
    //     </Toolbar>
    // {appRoutes.map((route, index) =>
    //   route.sidebarProps ? (
    //     route.child ? (
    //       <SidebarItemCollapse item={route} key={index} />
    //     ) : (
    //       <SidebarItem item={route} key={index} />
    //     )
    //   ) : null
    // )}
    //   </List>
    // </Drawer>
  );
};

export default Sidebar;
