import { Button, css, Divider, FormGroup, TextField } from "@mui/material";
import { useState } from "react";
import myUIConstants from "../../shared/constants/constants";
import React from "react";
import Form from "../../components/common/forms/Form";
import ExampleBoxList from "../../components/common/listBox/ExampleBoxList";
import TextareaForm from "../../components/common/forms/TextareaForm";
// require('dotenv').config()

type Props = {};

interface KeyObj {
  key: number;
}

interface KeyObj {
  key: number;
}

interface PhraseObj {
  str: string;
  //0 - default. 1 - color text. 2 - color strike through text, 3 - color backround
  type: string;
}

const green: string = "#13B617";
const red: string = "#DB1606";
const examples: string[] = [
  `I have read a beok, Lazy Jack. In the book “Lazy Jack” the poor boy Jack playing the hero. Howevers, Jack have an indolent disposition. So people are call him "Lazy Jack".`,
  `I have liken all the seasons, but winter is one of my favorite weathers. When it snow, I like to built snowmans with my friends. I had made a big snowballs and use a carrot and rocks for the face. Also, I like to sledding on snowy days. I been to the big hill by my house and race down the hills. Finally, my favorite thing in winter is having snowballs fighting. I like go to playground and have played with my neighbors friends. I think winter is best season for these fun activities.`,
  ` When planning a class trip, there are lots of places you can consider. However, I think a trip to Everland would be the best idea because there is so much to do. First, Everland offers lots of things to do. For example, we can look at animals there. Also, we can eat snacks like popcorn, candy and hotdogs. If you don't like these things, you can always ride attractions or watch parades and fireworks. These is something for everyone! In addition, Everland is big enough for everybody. You can bring lots of people without feeling crowded. There will be always somehing to do. Plus, there is plenty of room to hang out with friends. In conclusion, Everland is the best place to have a class trip. There is lots to do, and it is big engouh for everyone!`,
];

async function postData(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response; // parses JSON response into native JavaScript objects
}

function ColoredBackground(
  str: string,
  keyObj: KeyObj,
  color: string = green
): JSX.Element {
  return (
    <span style={{ backgroundColor: color }} key={keyObj.key++}>
      {str}
    </span>
  );
}

function Default(str: string, keyObj: KeyObj): JSX.Element {
  return (
    <span key={keyObj.key++} className="grammar-color-default">
      {str}
    </span>
  );
}

function ColoredStrikethrough(
  str: string,
  keyObj: KeyObj,
  color: string = red
): JSX.Element {
  if (str === " ") {
    return (
      <span
        style={{ textDecoration: "line-through", color }}
        key={keyObj.key++}
      >
        &nbsp;
      </span>
    );
  } else
    return (
      <span
        style={{ textDecoration: "line-through", color }}
        key={keyObj.key++}
      >
        {str}
      </span>
    );
}

function Color(
  str: string,
  keyObj: KeyObj,
  color: string = green
): JSX.Element {
  const returnObj: JSX.Element[] = [];
  for (let i = 0; i < str.length; i++) {
    returnObj.push(
      <span style={{ color }} key={keyObj.key++}>
        {str[i]}
      </span>
    );
  }
  return <span key={keyObj.key++}>{returnObj}</span>;
}

async function Middleware(
  text: string
): Promise<{ returnObj: JSX.Element[]; prev: string }> {
  const keyObj = { key: 0 };
  text = text.trim();
  return await postData(process.env.REACT_APP_GRAMMAR_CORRECTION_API_URL, {
    text: text,
  })
    .then((data) => {
      return data.json();
    })
    .then(async (data) => {
      const inputArr: PhraseObj[] = data.data.data;
      const prevResponse: string = data.data.corrected;
      const returnObj: JSX.Element[] = [];
      inputArr.forEach((x) => {
        if (x.type === "default") {
          returnObj.push(Default(x.str, keyObj));
        } else if (x.type === "add") {
          if (x.str === " ") {
            returnObj.push(ColoredBackground(x.str, keyObj));
          } else returnObj.push(Color(x.str, keyObj));
        } else if (x.type === "remove") {
          returnObj.push(ColoredStrikethrough(x.str, keyObj));
        } else {
          throw Error();
        }
      });
      return { returnObj, prev: prevResponse };
    })
    .catch((error: Error) => {
      throw new Error("Could not fetch data: " + text + " " + error.message);
    });
}

async function GetAIAnswer(
  text: string,
  callback: (a: JSX.Element) => void,
  setBusy: (a: boolean) => void
) {
  try {
    const displayArr: JSX.Element[] = [];
    setBusy(true);
    const { returnObj, prev } = await Middleware(text);
    returnObj.forEach((x) => displayArr.push(x));
    setBusy(false);
    callback(<div>{displayArr}</div>);
  } catch (e: any) {
    setBusy(false);
    callback(
      <div>
        {Color(
          "Cannot process inputs. Try other inputs, or try again later",
          { key: 1 },
          red
        )}
      </div>
    );
  }
}

function MyCustomGrammarly(props: Props) {
  const [text, setText] = useState("");
  const [output, setOutput] = useState(<div></div>);
  const [busy, setBusy] = useState(false);
  return (
    <div className="chat-box-section p-4 " style={{ minWidth: 300 }}>
      <h3
        className="title pt-4"
        style={{
          textAlign: "center",
          marginBottom: 50,
        }}
      >
        Grammar Correction API
      </h3>
      <div className="rbt-static-bar-top">
        <Form
          buttonDisabled={busy}
          onSubmit={(event) => {
            event.preventDefault();
            if (!busy) GetAIAnswer(text, setOutput, setBusy);
          }}
        >
          <TextareaForm
            textareaValue={text}
            onChangeTextarea={(event) => {
              setText(event.target.value);
            }}
          />
        </Form>
      </div>

      <div
        style={{
          border: "1px solid #13B617",
          borderRadius: 10,
          width: "100%",
          padding: 10,
          marginTop: 20,
          whiteSpace: "pre-line",
        }}
      >
        {output}
      </div>

      <ExampleBoxList
        title="Examples"
        examples={examples}
        onClickExample={setText}
      />
    </div>
  );
}

export default MyCustomGrammarly;
