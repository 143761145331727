import React from "react";
import SpeechAnalysisComp from "./components/SpeechAnalysisComp";

type Props = {};

const SpeechAnalysis = (props: Props) => {
  return (
    <div>
      <SpeechAnalysisComp />
    </div>
  );
};

export default SpeechAnalysis;
