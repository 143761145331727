import React from "react";
import PwcRagDocumentComponent from "./PwcRagDocumentComponent";

export default function PwcRagDocument() {
  return (
    <div className="App">
      <PwcRagDocumentComponent />
    </div>
  );
}
