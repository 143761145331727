import React from "react";
import { ChatFeed, Message } from "react-chat-ui";
import { Button, Divider } from "@mui/material";
import "../components/styles.css";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { CHATBOT_API_URL } from "../../../shared/constants/constants";
import myUIConstants from "../../../shared/constants/constants";
import { getStoppingStringsForAIRsp } from "../../../shared/utils";

const URL_CHAT = CHATBOT_API_URL;
const URL_ROLEPLAY_CHAT_READ = `${process.env.REACT_APP_JSON_BACKEND_HOST}/json/read/roleplay/chat`;
const URL_ROLEPLAY_CHAT_WRITE = `${process.env.REACT_APP_JSON_BACKEND_HOST}/json/write/roleplay/chat`;
const URL_ROLEPLAY_PROMPT = `${process.env.REACT_APP_JSON_BACKEND_HOST}/json/read/roleplay/prompt`;
const CHAT_TYPE = 3;

async function postData(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response; // parses JSON response into native JavaScript objects
}
async function getData(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
  return response; // parses JSON response into native JavaScript objects
}

async function GetAIAnswer(
  text,
  selected,
  clearPrevious,
  url_prompt,
  chat_type,
  clear_memory
) {
  return await getData(url_prompt)
    .then((rsp) => rsp.json())
    .then(async (data) => {
      return await postData(URL_CHAT, {
        text,
        selected,
        clear_previous: clearPrevious,
        prompt: data.prompt,
        temperature: data.temperature,
        top_p: data.top_p,
        max_new_tokens: data.max_new_tokens,
        chat_prompt_size: data.chat_prompt_size,
        repetition_penalty: data.repetition_penalty,
        top_k: data.top_k,
        ainame: data.ainame,
        username: data.username,
        chat_type,
        clear_memory,
        stopping_strings: getStoppingStringsForAIRsp(
          data.ainame,
          data.username
        ),
      })
        .then((data) => {
          return data.json();
        })
        .then(async (data) => {
          return [
            data.output,
            data.prompt,
            data.generate_ans_time,
            data.generate_prompt_time,
            data.speed,
          ];
        })
        .catch((e) => {
          return "Server is not running";
        });
    });
}

const users = {
  0: "You",
  1: "Ella",
};

const customBubble = (props) => (
  <div>
    <p>{`${props.message.senderName} ${props.message.id ? "says" : "said"}: ${
      props.message.message
    }`}</p>
  </div>
);

const readJson = async (url_chat) => {
  return await getData(url_chat)
    .then((rsp) => rsp.json())
    .then((data) => {
      const history = data.history;
      const prompt = data.prompt;
      const rsp = [];
      history.forEach((obj) => {
        const message = obj.message;
        const id = obj.id;
        rsp.push(new Message({ message, id, senderName: users[id] }));
      });
      return [rsp, history, prompt];
    });
};

const saveJson = (url_chat, json, prompt) => {
  const history = [];
  json.forEach((obj) => {
    const msg = obj.message;
    const id = obj.id;
    history.push({ message: msg, id });
  });
  postData(url_chat, { history, prompt });
};

class RoleplayChatComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      messages: [],
      history: [],
      useCustomBubble: false,
      curr_user: 0,
      selected: 4,
      prompt: "",
      chat_type: CHAT_TYPE,
      url_prompt: URL_ROLEPLAY_PROMPT,
      url_chat_read: URL_ROLEPLAY_CHAT_READ,
      url_chat_write: URL_ROLEPLAY_CHAT_WRITE,
      clear_memory: false,
      speed: -1,
      generate_ans_time: -1,
      generate_prompt_time: -1,
    };
  }
  async componentDidMount() {
    const [messages, history, prompt] = await readJson(
      this.state.url_chat_read
    );
    const messageElements = [];
    messages.forEach((message) => {
      const messageElement = {
        id: message.id,
        message:
          message.senderName === users[1] ? (
            <div style={{ color: "black", margin: 0 }}>
              <ReactMarkdown
                className="nomargin"
                style={{ margin: 0 }}
                remarkPlugins={[remarkGfm]}
              >
                {this.parseMarkdownTable(message.message)}
              </ReactMarkdown>
            </div>
          ) : (
            message.message
          ),
        senderName: message.senderName,
      };
      messageElements.push(messageElement);
    });

    this.setState({ messages: messageElements });
    this.setState({ history, prompt });
  }

  onPress(user) {
    this.setState({ curr_user: user });
  }

  async onMessageSubmit(e) {
    const input = this.message;
    e.preventDefault();
    if (!input.value) {
      return false;
    }
    const text = input.value;
    this.pushUserMessage(this.state.curr_user, text);
    input.value = "";
    const clearPrevious = this.state.history.length == 1;
    const [reply, prompt, generate_ans_time, generate_prompt_time, speed] =
      await GetAIAnswer(
        text,
        this.state.selected,
        clearPrevious,
        this.state.url_prompt,
        this.state.chat_type,
        this.state.clear_memory
      );
    this.pushEllaMessage(reply, prompt);
    this.setState({
      clear_memory: false,
      generate_ans_time,
      generate_prompt_time,
      speed,
    });
  }

  pushUserMessage(recipient, message) {
    const prevState = this.state;
    const newMessage = new Message({
      id: recipient,
      message,
      senderName: users[recipient],
    });
    prevState.history.push({ message, id: recipient });
    prevState.messages.push(newMessage);
    this.setState(this.state);
  }

  parseMarkdownTable(message) {
    const count = (message.match(/\|\s*\|/g) || []).length;
    if (count < 1) return message;
    message = message.replace(/\|\s*\|/g, "|\n|");
    message = message.replace(/\|/, "\n|");
    const lastPipeIndex = message.lastIndexOf("|");
    message =
      message.slice(0, lastPipeIndex + 1) +
      "\n\n" +
      message.slice(lastPipeIndex + 1);
    return message;
  }

  pushEllaMessage(message, prompt) {
    const prevState = this.state;
    const messageElement = (
      <div style={{ color: "black", margin: 0 }}>
        <ReactMarkdown
          className="nomargin"
          style={{ margin: 0 }}
          remarkPlugins={[remarkGfm]}
        >
          {this.parseMarkdownTable(message)}
        </ReactMarkdown>
      </div>
    );
    const newMessage = new Message({
      id: 1,
      message: messageElement,
      senderName: "Ella",
    });
    prevState.messages.push(newMessage);
    prevState.history.push({ message, id: 1 });
    this.setState({ ...this.state, prompt });
    saveJson(this.state.url_chat_write, this.state.history, prompt);
  }

  setSelected(x) {
    this.setState({ selected: x });
  }

  onClearHistory(e) {
    e.preventDefault();
    this.setState({ clearPrevious: true });
    this.setState({ messages: [] });
    this.setState({ history: [] });
    saveJson(this.state.url_chat_write, this.state.history, this.state.prompt);
  }

  onClearMemory(e) {
    e.preventDefault();
    this.setState({ clear_memory: !this.state.clear_memory });
  }

  render() {
    return (
      <div
        style={{
          width: "100%",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div className="container" style={{ marginTop: 0 }}>
          <h2
            style={{
              textAlign: "center",
              fontFamily: myUIConstants.fontFamily,
              marginBottom: 50,
            }}
          >
            Role Play Chat
          </h2>
          <div className="chatfeed-wrapper">
            <ChatFeed
              chatBubble={this.state.useCustomBubble && customBubble}
              maxHeight={500}
              messages={this.state.messages} // Boolean: list of message objects
              showSenderName
            />

            <form onSubmit={(e) => this.onMessageSubmit(e)}>
              <input
                style={{ height: 50 }}
                ref={(m) => {
                  this.message = m;
                }}
                placeholder="Type a message..."
              />
            </form>
          </div>
          <form
            style={{
              direction: "rtl",
              width: "100%",
              padding: 10,
              marginTop: 20,
              fontSize: 15,
            }}
            onSubmit={() => {}}
          >
            <Button
              onClick={(e) => this.onMessageSubmit(e)}
              style={{ marginLeft: 20 }}
              type="submit"
              variant="contained"
              color="success"
            >
              {" "}
              Submit{" "}
            </Button>
            <Button
              onClick={(e) => this.onClearHistory(e)}
              style={{ marginLeft: 20 }}
              type="submit"
              variant="outlined"
            >
              Clear History
            </Button>
          </form>
          <div
            style={{
              direction: "rtl",
              width: "100%",
              padding: 10,
              fontSize: 15,
              display: "flex",
              flexDirection: "row",
            }}
          >
            {this.state.generate_ans_time != -1 ? (
              <p>
                Prompt was generated in {this.state.generate_prompt_time} s.
                Answer was generated in {this.state.generate_ans_time} s. Speed
                is {this.state.speed} t/s
              </p>
            ) : null}
          </div>
          <div style={{ marginTop: "1500px" }}>
            <h3>Prompt looks like this:</h3>
            <textarea
              fullWidth
              width={1000}
              multiline={true}
              readOnly={true}
              rows={50}
              style={{
                marginTop: 0,
                marginBottom: 50,
                width: "100%",
                borderRadius: 5,
                padding: 5,
              }}
              size="small"
              color="primary"
              type="text"
              value={this.state.prompt}
            ></textarea>
          </div>
        </div>
      </div>
    );
  }
}

export default RoleplayChatComponent;
