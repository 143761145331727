import React from "react";
import { ChatFeed, Message } from "react-chat-ui";
import { Button } from "@mui/material";
import "../components/styles.css";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  CHATBOT_API_URL,
  CHATTING_AI_MESSAGE_ID,
  CHATTING_AI_NAME,
  CHATTING_USER_MESSAGE_ID,
  CHATTING_USER_NAME,
  JSON_FILE_NAMES,
} from "../../../shared/constants/constants";
import {
  readCompareChatJson,
  readPromptJson,
  saveCompareChatJson,
  constructFullPrompt,
  removeUnnecessaryResponseFromAIResponse,
  postData,
  getChattingNameById,
  parseMarkdownTable,
  getResponseFromLLMServer,
  getStoppingStringsForAIRsp,
  getChatGPTResponse,
} from "../../../shared/utils";
import myUIConstants from "../../../shared/constants/constants";
import {
  ChatComponentState,
  TAIRequestObj,
  TChatbotResponseData,
  TChatGetResponseDTO,
  TChatMessageElement,
  THistoryTurnOne,
  TPromptData,
} from "shared/types";

const URL_CHAT = CHATBOT_API_URL;
const CHAT_TYPE = 0;

async function GetAIAnswerGPT(
  dto: TChatGetResponseDTO,
  historyGPT: THistoryTurnOne[]
): Promise<TChatbotResponseData> {
  const { text, file_name_read_prompt } = dto;
  const promptData: TPromptData = await readPromptJson(file_name_read_prompt);
  const {
    temperature,
    top_p,
    max_new_tokens,
    repetition_penalty,
    ainame,
    username,
    prompt,
  } = promptData;
  const historyGPTWithoutLastUser = JSON.parse(JSON.stringify(historyGPT));
  historyGPTWithoutLastUser.pop();
  const fullPrompt = constructFullPrompt({
    prompt: prompt,
    aiName: ainame,
    childName: username,
    history: historyGPTWithoutLastUser,
    lastUserMsg: text,
  });
  const rsp = await getChatGPTResponse({
    prompt_text: fullPrompt,
    max_tokens: max_new_tokens,
    top_p: top_p,
    frequency_penalty: repetition_penalty,
    presence_penalty: repetition_penalty,
    engine: "gpt-4o-mini",
    temperature: temperature,
    ainame,
    username,
    stop_strings: getStoppingStringsForAIRsp(ainame, username),
  });
  const { output, generate_ans_time, speed } = rsp;
  const revisedAnswer = removeUnnecessaryResponseFromAIResponse(
    output,
    ainame,
    username
  );
  return {
    output: revisedAnswer,
    prompt,
    generate_ans_time,
    generate_prompt_time: 0,
    speed,
  };
}

async function GetAIAnswerLLM(
  dto: TChatGetResponseDTO
): Promise<TChatbotResponseData> {
  const { text, selected, clear_previous, chat_type, file_name_read_prompt } =
    dto;
  const promptData: TPromptData = await readPromptJson(file_name_read_prompt);
  const {
    temperature,
    top_p,
    max_new_tokens,
    chat_prompt_size,
    repetition_penalty,
    top_k,
    ainame,
    username,
  } = promptData;
  const aiRequestObj: TAIRequestObj = {
    text,
    selected,
    clear_previous,
    prompt: promptData.prompt,
    temperature,
    top_p,
    max_new_tokens,
    chat_prompt_size,
    repetition_penalty,
    top_k,
    ainame,
    username,
    chat_type,
    stopping_strings: getStoppingStringsForAIRsp(ainame, username),
  };

  const chatbotRspData: TChatbotResponseData = await postData(
    URL_CHAT,
    aiRequestObj
  );
  const { output, prompt, generate_ans_time, generate_prompt_time, speed } =
    chatbotRspData;
  const revisedAnswer = removeUnnecessaryResponseFromAIResponse(
    output,
    ainame,
    username
  );
  return {
    output: revisedAnswer,
    prompt,
    generate_ans_time,
    generate_prompt_time,
    speed,
  };
}

type CompareChatComponentState = ChatComponentState & {
  messagesLLM: TChatMessageElement[];
  historyLLM: THistoryTurnOne[];
  messagesGPT: TChatMessageElement[];
  historyGPT: THistoryTurnOne[];
  input_message_llm: string;
  input_message_gpt: string;
  speed_llm: number;
  generate_ans_time_llm: number;
  generate_prompt_time_llm: number;
  speed_gpt: number;
  generate_ans_time_gpt: number;
  generate_prompt_time_gpt: number;
};

class CompareChatComponent extends React.Component<
  {},
  CompareChatComponentState
> {
  constructor() {
    super({});
    this.state = {
      messagesLLM: [],
      historyLLM: [],
      messagesGPT: [],
      historyGPT: [],
      selected: 4,
      prompt: "",
      chat_type: CHAT_TYPE,
      file_name_chat: JSON_FILE_NAMES.compareChat,
      file_name_prompt: JSON_FILE_NAMES.comparePrompt,
      input_message_llm: "",
      input_message_gpt: "",
      speed_llm: -1,
      generate_ans_time_llm: -1,
      generate_prompt_time_llm: -1,
      speed_gpt: -1,
      generate_ans_time_gpt: -1,
      generate_prompt_time_gpt: -1,
      speed: -1, //unused
      generate_ans_time: -1, //unused
      generate_prompt_time: -1, //unused
      input_message: "", //unused
      messages: [], //unused
      history: [], //unused
    };
  }

  async componentDidMount() {
    const { history1, history2, prompt } = await readCompareChatJson(
      this.state.file_name_chat
    );
    const messageElementsLLM: TChatMessageElement[] = history1.map(
      (historyTurn: THistoryTurnOne) => {
        return {
          id: historyTurn.id,
          message: (
            <div style={{ color: "black", margin: 0 }}>
              <ReactMarkdown className="nomargin" remarkPlugins={[remarkGfm]}>
                {parseMarkdownText(parseMarkdownTable(historyTurn.message))}
              </ReactMarkdown>
            </div>
          ),
          senderName: getChattingNameById(historyTurn.id),
        };
      }
    );
    const messageElementsGPT: TChatMessageElement[] = history2.map(
      (historyTurn: THistoryTurnOne) => {
        return {
          id: historyTurn.id,
          message: (
            <div style={{ color: "black", margin: 0 }}>
              <ReactMarkdown className="nomargin" remarkPlugins={[remarkGfm]}>
                {parseMarkdownText(parseMarkdownTable(historyTurn.message))}
              </ReactMarkdown>
            </div>
          ),

          senderName: getChattingNameById(historyTurn.id),
        };
      }
    );

    this.setState({ messagesLLM: messageElementsLLM });
    this.setState({ messagesGPT: messageElementsGPT });
    this.setState({ historyLLM: history1 });
    this.setState({ historyGPT: history2 });
    this.setState({ prompt });
  }

  async onMessageSubmitLLM(e: any) {
    e.preventDefault();
    const text = this.state.input_message_llm;
    this.pushUserMessageLLM(text);
    this.setState({ input_message_llm: "" });
    const clearPrevious = this.state.historyLLM.length <= 1;
    const { output, prompt, generate_ans_time, generate_prompt_time, speed } =
      await GetAIAnswerLLM({
        text,
        selected: this.state.selected,
        clear_previous: clearPrevious,
        chat_type: this.state.chat_type,
        file_name_read_prompt: this.state.file_name_prompt,
      });
    this.pushEllaMessageLLM(output, prompt);
    this.setState({
      generate_ans_time_llm: generate_ans_time,
      generate_prompt_time_llm: generate_prompt_time,
      speed_llm: speed,
    });
  }

  async onMessageSubmitGPT(e: any) {
    e.preventDefault();
    const text = this.state.input_message_gpt;
    this.pushUserMessageGPT(text);
    this.setState({ input_message_gpt: "" });
    const clearPrevious = this.state.historyGPT.length <= 1;
    const { output, prompt, generate_ans_time, generate_prompt_time, speed } =
      await GetAIAnswerGPT(
        {
          text,
          selected: this.state.selected,
          clear_previous: clearPrevious,
          chat_type: this.state.chat_type,
          file_name_read_prompt: this.state.file_name_prompt,
        },
        this.state.historyGPT
      );
    this.pushEllaMessageGPT(output, prompt);
    this.setState({
      generate_ans_time_gpt: generate_ans_time,
      generate_prompt_time_gpt: generate_prompt_time,
      speed_gpt: speed,
    });
  }

  pushUserMessageLLM(message: string) {
    const prevState = this.state;
    const newMessage = new Message({
      id: CHATTING_USER_MESSAGE_ID,
      message,
      senderName: CHATTING_USER_NAME,
    });
    prevState.historyLLM.push({ message, id: CHATTING_USER_MESSAGE_ID });
    prevState.messagesLLM.push(newMessage);
    this.setState(this.state);
  }

  pushUserMessageGPT(message: string) {
    const prevState = this.state;
    const newMessage = new Message({
      id: CHATTING_USER_MESSAGE_ID,
      message,
      senderName: CHATTING_USER_NAME,
    });
    prevState.historyGPT.push({ message, id: CHATTING_USER_MESSAGE_ID });
    prevState.messagesGPT.push(newMessage);
    this.setState(this.state);
  }

  pushEllaMessageLLM(message: string, prompt: string) {
    const prevState = this.state;
    const messageElement = (
      <div style={{ color: "black", margin: 0 }}>
        <ReactMarkdown className="nomargin" remarkPlugins={[remarkGfm]}>
          {parseMarkdownTable(message)}
        </ReactMarkdown>
      </div>
    );

    const newMessage = new Message({
      id: CHATTING_AI_MESSAGE_ID,
      message: messageElement as any,
      senderName: CHATTING_AI_NAME,
    });

    prevState.messagesLLM.push(newMessage);
    prevState.historyLLM.push({ message, id: CHATTING_AI_MESSAGE_ID });
    this.setState({ ...this.state, prompt });
    saveCompareChatJson(
      this.state.historyLLM,
      this.state.historyGPT,
      prompt,
      this.state.file_name_chat
    );
  }

  pushEllaMessageGPT(message: string, prompt: string) {
    const prevState = this.state;
    const messageElement = (
      <div style={{ color: "black", margin: 0 }}>
        <ReactMarkdown className="nomargin" remarkPlugins={[remarkGfm]}>
          {parseMarkdownTable(message)}
        </ReactMarkdown>
      </div>
    );
    const newMessage = new Message({
      id: CHATTING_AI_MESSAGE_ID,
      message: messageElement as any,
      senderName: CHATTING_AI_NAME,
    });
    prevState.messagesGPT.push(newMessage);
    prevState.historyGPT.push({ message, id: CHATTING_AI_MESSAGE_ID });
    this.setState({ ...this.state, prompt });
    saveCompareChatJson(
      this.state.historyLLM,
      this.state.historyGPT,
      prompt,
      this.state.file_name_chat
    );
  }

  onClearHistoryLLM(e: any) {
    e.preventDefault();
    this.setState({ messagesLLM: [] });
    this.setState({ historyLLM: [] });
    saveCompareChatJson(
      this.state.historyLLM,
      this.state.historyGPT,
      this.state.prompt,
      this.state.file_name_chat
    );
  }

  onClearHistoryGPT(e: any) {
    e.preventDefault();
    this.setState({ messagesGPT: [] });
    this.setState({ historyGPT: [] });
    saveCompareChatJson(
      this.state.historyLLM,
      this.state.historyGPT,
      this.state.prompt,
      this.state.file_name_chat
    );
  }

  render() {
    return (
      <div
        style={{
          width: "100%",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div className="container" style={{ marginTop: 0 }}>
          <h2
            style={{
              textAlign: "center",
              fontFamily: myUIConstants.fontFamily,
            }}
          >
            Compare Chat
          </h2>
          <div
            style={{
              width: "100%",
              alignItems: "flex-start",
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div>
              <h3 className="center">Local LLM</h3>
              <div style={{ width: 430 }} className="chatfeed-wrapper-compare">
                <ChatFeed
                  maxHeight={500}
                  messages={this.state.messagesLLM} // Boolean: list of message objects
                  showSenderName
                />
              </div>
              <br></br>
              <div>
                <form onSubmit={(e) => this.onMessageSubmitLLM(e)}>
                  <input
                    style={{
                      border: "1px solid #ddd",
                      borderRadius: "20px",
                      width: "100%",
                      height: 50,
                    }}
                    onChange={(e) => {
                      this.setState({ input_message_llm: e.target.value });
                    }}
                    value={this.state.input_message_llm}
                    placeholder="Type a message..."
                  />
                </form>
              </div>
              <form
                style={{
                  direction: "rtl",
                  width: "100%",
                  padding: 10,
                  marginTop: 20,
                  fontSize: 15,
                }}
              >
                <Button
                  onClick={(e) => this.onMessageSubmitLLM(e)}
                  style={{ marginLeft: 20 }}
                  type="submit"
                  variant="contained"
                  color="success"
                >
                  {" "}
                  Submit{" "}
                </Button>
                <Button
                  onClick={(e) => this.onClearHistoryLLM(e)}
                  style={{ marginLeft: 20 }}
                  type="submit"
                  variant="outlined"
                >
                  Clear History
                </Button>
              </form>
              <div
                style={{
                  direction: "rtl",
                  width: "100%",
                  padding: 10,
                  fontSize: 15,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {this.state.generate_ans_time_llm !== -1 ? (
                  <p>
                    Answer was generated in {this.state.generate_ans_time_llm}{" "}
                    s. Speed is {this.state.speed_llm} t/s
                  </p>
                ) : null}
              </div>
            </div>
            <div>
              <h3 className="center">GPT-4o</h3>
              <div style={{ width: 430 }} className="chatfeed-wrapper-compare">
                <ChatFeed
                  maxHeight={500}
                  messages={this.state.messagesGPT} // Boolean: list of message objects
                  showSenderName
                />
              </div>
              <br></br>
              <div>
                <form onSubmit={(e) => this.onMessageSubmitGPT(e)}>
                  <input
                    style={{
                      border: "1px solid #ddd",
                      borderRadius: "20px",
                      width: "100%",
                      // borderTop: "none",
                      height: 50,
                    }}
                    onChange={(e) => {
                      this.setState({ input_message_gpt: e.target.value });
                    }}
                    value={this.state.input_message_gpt}
                    placeholder="Type a message..."
                  />
                </form>
              </div>
              <form
                style={{
                  direction: "rtl",
                  width: "100%",
                  padding: 10,
                  marginTop: 20,
                  fontSize: 15,
                }}
                onSubmit={() => {}}
              >
                <Button
                  onClick={(e) => this.onMessageSubmitGPT(e)}
                  style={{ marginLeft: 20 }}
                  type="submit"
                  variant="contained"
                  color="success"
                >
                  {" "}
                  Submit{" "}
                </Button>
                <Button
                  onClick={(e) => this.onClearHistoryGPT(e)}
                  style={{ marginLeft: 20 }}
                  type="submit"
                  variant="outlined"
                >
                  Clear History
                </Button>
              </form>
              <div
                style={{
                  direction: "rtl",
                  width: "100%",
                  padding: 10,
                  fontSize: 15,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {this.state.generate_ans_time_gpt !== -1 ? (
                  <p>
                    Answer was generated in {this.state.generate_ans_time_gpt}{" "}
                    s. Speed is {this.state.speed_gpt} t/s
                  </p>
                ) : null}
              </div>
            </div>
          </div>

          <div style={{ marginTop: "1500px" }}>
            <h3>Prompt looks like this:</h3>
            <textarea
              readOnly={true}
              rows={50}
              style={{
                marginTop: 0,
                marginBottom: 50,
                width: "100%",
                borderRadius: 5,
                padding: 5,
              }}
              color="primary"
              value={this.state.prompt}
            ></textarea>
          </div>
        </div>
      </div>
    );
  }
}

export default CompareChatComponent;
