import React from "react";
import styles from "./PronunciationAssessmentContentScores.module.scss";
import CustomShapePieChart from "components/mantine-charts/CustomShapePieChart";
import HorizontalLineChart from "components/mantine-charts/horizontalLineChart";

interface IPronunciationAssessmentContentScoresProps {
  scores: TContentAssessment;
}
const PronunciationAssessmentContentScores = ({
  scores,
}: IPronunciationAssessmentContentScoresProps) => {
  const [contentScore, setContentScore] = React.useState(0);
  React.useEffect(() => {
    const avr = Math.round(
      (scores.GrammarScore + scores.TopicScore + scores.VocabularyScore) / 3
    );
    if (avr !== contentScore) setContentScore(avr);
  }, [scores, contentScore]);
  return (
    <div className={styles.scores}>
      <div className={styles.pronScore}>
        <span>contents score</span>
        <CustomShapePieChart score={contentScore} />
      </div>

      <div className={styles.otherScores}>
        <div className={styles.scoresItem}>
          <div className={styles.scoresLabel}>
            <span>grammar score</span>
            <span>{`${scores.GrammarScore}/100`}</span>
          </div>
          <HorizontalLineChart score={scores.GrammarScore} />
        </div>
        <div className={styles.scoresItem}>
          <div className={styles.scoresLabel}>
            <span>topic score</span>
            <span>{`${scores.TopicScore}/100`}</span>
          </div>
          <HorizontalLineChart score={scores.TopicScore} />
        </div>
        <div className={styles.scoresItem}>
          <div className={styles.scoresLabel}>
            <span>vocabulary score</span>
            <span>{`${scores.VocabularyScore}/100`}</span>
          </div>
          <HorizontalLineChart score={scores.VocabularyScore} />
        </div>
      </div>
    </div>
  );
};
export default PronunciationAssessmentContentScores;
