import React from "react";
type TBoxListProps = {
  title: string;
  examples: string[];
  onClickExample: (value: string) => void;
  describText?: string;
  descText?: string;
};
const ExampleBoxList = ({
  title,
  describText,
  descText,
  examples,
  onClickExample,
}: TBoxListProps) => {
  return (
    <div className=" sessions-box overflow-hidden border-top mt-4">
      <div className="section-title">
        <h4 className="title mb-0 py-4">{title}</h4>
        {describText && <p className="description">{describText}</p>}
      </div>
      <div className="list-card-grp">
        {descText && (
          <div className="toolbar d-flex">
            <div className="icon">
              <i className="feather-info"></i>
            </div>
            <p className="desc">{descText}</p>
          </div>
        )}
        {examples &&
          examples.map((example, index) => (
            <div className="list-card" key={`example-box-list-item-${index}`}>
              <div className="inner py-4">
                <div
                  className="example-div-btn-text bg-color-white-off"
                  onClick={() => onClickExample(example)}
                >
                  <p className="m-0">{example}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
export default ExampleBoxList;
