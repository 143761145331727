import React from "react";
type TTextareaFormProps = {
  textareaValue: string;
  onChangeTextarea: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  rows?: number;
  placeholder?: string;
};
const TextareaForm = ({
  onChangeTextarea,
  textareaValue,
  rows,
  placeholder,
}: TTextareaFormProps) => {
  return (
    <div className="new-chat-form-tx border-gradient">
      <textarea
        rows={rows ? rows : 15}
        placeholder={placeholder ? placeholder : "Enter Text"}
        value={textareaValue}
        onChange={onChangeTextarea}
      />
    </div>
  );
};

export default TextareaForm;
